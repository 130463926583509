import PropTypes from 'prop-types';
// @mui
import {Dialog, InputAdornment, ListItemButton, Stack, TextField, Typography} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import {useLayoutEffect, useState} from "react";
// redux
import {dispatch, useSelector} from '../../../redux/store';


import {useFormContext} from "react-hook-form";
import {useSnackbar} from "notistack";
import {useLocales} from "../../../locales";
import {fetchAllCustomers} from "../../../redux/slices/customers";
import {LoadingButton} from "@mui/lab";
import {getClientInfo} from "../../../api/vpfr";
import CustomersNewEditForm from "../../Customers/CustomersNewEditForm";
// ----------------------------------------------------------------------

B2BDialog.propTypes = {
    addressOptions: PropTypes.array,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    open: PropTypes.bool,
    selected: PropTypes.func,
};

export default function B2BDialog({open, onClose}) {

    const {setValue} = useFormContext();
    const [insertingNew, setInsertingNew] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [foundedData, setFoundedData] = useState(null)

    const {allCustomers} = useSelector(state => state.customers);

    const {translate} = useLocales();

    const {enqueueSnackbar} = useSnackbar();
    const handleSelectCustom = (props) => {
        setValue('customer', props)
        onClose();
    }
    const [filterName, setFilterName] = useState('');

    const customDataFiltered = applyCustomerFilter({
        inputData: allCustomers,
        filterName,
    });

    useLayoutEffect(() => {
        if (allCustomers.length === 0) {
            dispatch(fetchAllCustomers()).unwrap().catch(reason => {
                console.error("fetchAllCustomers", reason);
                enqueueSnackbar(translate("errorWhileLoadingData"), {variant: "error"});
            });
        }
    }, [allCustomers.length]);

    const handleFilterName = (event) => {
        setFilterName(event.target.value);
    };

    const handleAddNew = () => {
        setLoadingData(true)
        getClientInfo(filterName).then((data) => {
            if (data) {
                setFoundedData(data)
            } else {
                setFoundedData({
                    TIN: filterName,
                })
            }
        }).catch(reason => {
            console.error("getClientInfo", reason)
            enqueueSnackbar("Pib nije pronađen!", {variant: "error"})
        }).finally(() => {
            setInsertingNew(true);
            setLoadingData(false)
        })
    }

    const handleSubmit = (props) => {
        setValue('customer', props)
        onClose();
    }

    return (
        <Dialog fullWidth maxWidth={insertingNew ? 'lg' : 'xs'} open={open} onClose={onClose}>
            <Stack direction="column" sx={{py: 2.5, px: 3, gap: 1}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{pt: 2.5}}>
                    <Typography variant="h6">Pronađi klijenta </Typography>
                </Stack>
                <TextField
                    fullWidth
                    value={filterName}
                    size='small'
                    type={'text'}
                    onChange={handleFilterName}
                    disabled={allCustomers.length < 1}
                    // helperText={'Unesite minimalno 3 karaktera...'}
                    placeholder={`${allCustomers.length < 1 ? 'Momenat...' : 'Pretražite...'}`}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                            </InputAdornment>
                        ),
                    }}/>
            </Stack>
            {(insertingNew && !loadingData) ?
                <Stack p={1}>
                    <CustomersNewEditForm
                        handleSaveAndSubmit={handleSubmit}
                        searched={foundedData}/>
                </Stack>
                :

                <Scrollbar sx={{p: 1.5, pt: 0, maxHeight: 80 * 8}}>
                    {customDataFiltered.length < 1 ?
                        <Typography textAlign="center">
                            Nema rezultata
                        </Typography> :
                        (customDataFiltered.slice(0, 10).map((item, index2) => (
                            <ListItemButton
                                key={`results-${index2}`}
                                onClick={() => handleSelectCustom(item)}
                                sx={{
                                    p: 1.5,
                                    borderRadius: 1,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Typography variant="subtitle2">{item.name}</Typography>

                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    PIB: {item.tin}
                                </Typography>

                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    Grad: {item.city}
                                </Typography>

                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    Addressa: {item.address}
                                </Typography>
                            </ListItemButton>
                        )))}

                </Scrollbar>
            }
            {customDataFiltered.length === 0 && !insertingNew &&
                <LoadingButton
                    loading={loadingData}
                    onClick={handleAddNew}>
                    Dodaj novog
                </LoadingButton>
            }
        </Dialog>
    );
}

function applyCustomerFilter({inputData, filterName}) {
    if (filterName) {
        inputData = inputData.filter(
            (company) =>
                (company?.name?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1) ||
                (company?.tin?.toString()?.indexOf(filterName) !== -1 && company?.tin?.toString()?.indexOf(filterName) !== undefined) ||
                (company?.jmbg?.toString()?.indexOf(filterName) !== -1 && company?.jmbg?.toString()?.indexOf(filterName) !== undefined)
        );
    }

    return inputData;
}
