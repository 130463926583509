import {Checkbox, TableCell, TableRow} from "@mui/material";
import PropTypes from "prop-types";
import {parseDateToDDMMYYYY} from "../../../utils/DateFormater";

PrivilegesDialogTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func
};

export default function PrivilegesDialogTableRow({row, selected, prevSelectedPassenger, onSelectRow}) {
    // prevSelectedPassenger prethodno izabrani passengeri, namerno cu gledati po clientIdentity jer ne moze po id zbog novih

    const alreadySelectedPassenger = prevSelectedPassenger?.some(psg => psg.clientIdentity === row?.clientIdentity)

    const {
        clientName,
        clientSurname,
        clientIdentity,
        cardNumber,
        ticketType,
        validFrom,
        validTo
    } = row;

    return (
        <TableRow hover>
            <TableCell padding="checkbox">
                <Checkbox disabled={alreadySelectedPassenger} checked={alreadySelectedPassenger ? alreadySelectedPassenger : selected} onClick={onSelectRow}/>
            </TableCell>
            <TableCell align="left">{clientName} </TableCell>
            <TableCell align="left">{clientSurname} </TableCell>
            <TableCell align="left">{clientIdentity}</TableCell>
            <TableCell align="left">{cardNumber}</TableCell>
            <TableCell align="left">{ticketType?.name || ""}</TableCell>
            <TableCell align="left">{parseDateToDDMMYYYY(validFrom)}</TableCell>
            <TableCell align="left">{parseDateToDDMMYYYY(validTo)}</TableCell>
        </TableRow>
    )
}
