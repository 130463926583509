import PropTypes from "prop-types";

import {IconButton, MenuItem, TableCell, TableRow} from "@mui/material";
import Iconify from "../../components/Iconify";
import MenuPopover from "../../components/menu-popover";
import {useState} from "react";
import Label from "../../components/label";
import {useLocales} from "../../locales";
import {ICONS_NAME} from "../../constants/icons";

BusTableRow.propTypes = {
    row: PropTypes.object,
    onEditRow: PropTypes.func,
};

export default function BusTableRow({row, onEditRow}) {
    const {translate} = useLocales();

    const {plateNumber, seats, description, isActive, operatorName} = row;

    const [openPopover, setOpenPopover] = useState(null);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    return (
        <>
            <TableRow hover>

                <TableCell align="left">{operatorName}</TableCell>

                <TableCell align="left">{plateNumber}</TableCell>

                <TableCell align="left">{seats}</TableCell>

                <TableCell align="left">{description}</TableCell>

                <TableCell align="left">
                    <Label
                        variant="soft"
                        color={(isActive && 'success') || 'error'}
                    >
                        {isActive ? `${translate("isActive")}` : `${translate("isNotActive")}`}
                    </Label>
                </TableCell>

                <TableCell align="right">
                    <IconButton color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{width: 140}}>
                <MenuItem
                    onClick={() => {
                        onEditRow();
                        handleClosePopover();
                    }}>
                    <Iconify icon={ICONS_NAME.edit}/>
                    {translate("edit")}
                </MenuItem>
            </MenuPopover>
        </>
    )
}
