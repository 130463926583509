import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const SpinnerWrapper = styled("div")(() => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "rgb(255,255,255,0.6)",
  top: 0,
  left: 0,
}));

export default function LoadingModal() {
  return (
    <SpinnerWrapper>
      <CircularProgress />
    </SpinnerWrapper>
  );
}