import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {child, get} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";

export const fetchEsirBalance = createAsyncThunk("fetchEsirBalance", async () => {
    return (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/balance`))).val()
})

export const fetchAllEsirInvoices = createAsyncThunk("fetchAllEsirInvoices", async () => {
    let arr = [];
    let objArr = (await get(child(dbRef, `userFinance/${AUTH.currentUser.uid}/invoices`))).val()
    if(objArr === null) return  arr
    for (const key of Object.keys(objArr)) {
        arr.push({
            id: key,
            ...objArr[key]
        })
    }
    return arr;
})

const initialState = {
    invoices: [],
    balance: 0,
    loading: false
}

export const esirInvoicesSlice = createSlice({
    name: 'esirInvoices',
    initialState,
    extraReducers: {
        [fetchAllEsirInvoices.pending]: (state) => {
            state.loading = true
        },
        [fetchAllEsirInvoices.fulfilled]: (state, {payload}) => {
            state.invoices = payload;
            state.loading = false
        },
        [fetchAllEsirInvoices.rejected]: (state) => {
            state.loading = false
        },
        //fetchEsirBalance
        [fetchEsirBalance.pending]: (state) => {
            state.loading = true
        },
        [fetchEsirBalance.fulfilled]: (state, {payload}) => {
            state.balance = payload;
            state.loading = false
        },
        [fetchEsirBalance.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const esirInvoicesReducer = esirInvoicesSlice.reducer;