import {customAxiosBE} from "../config";
import axios from "axios";

export async function getAllActionsAPI(payload) {
    return customAxiosBE.get(`/salesActions?page=${payload.page}&size=${payload.rowsPerPage}`)
}

export async function getActionsAPI(id) {
    return customAxiosBE.get(`/salesActions/${id}`)
}

export async function insertActionsAPI(data){
    return customAxiosBE.post(`/salesActions`, data)
}
export async function updateActionsAPI(data){
    return customAxiosBE.put(`/salesActions`, data)
}

export async function deleteActionsAPI(data){
    return customAxiosBE.delete(`/salesActions`, data)
}

export async function deactivateActionAPI(id){
    return customAxiosBE.patch(`/salesActions/${id}`);
}
export const searchActionsAPI = async (data, cancelToken) => {
    let response
    const {name, page, rowsPerPage} = data
    await customAxiosBE
        .post(`/salesActions/search?page=${page}&size=${rowsPerPage}`, {
            name: name
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function getCurrentActions(){
    return customAxiosBE.get(`/salesActions/current`)
}
