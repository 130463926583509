import {ToggleButton} from "@mui/lab";
import {alpha, Box} from "@mui/material";
import React from "react";
import Iconify from "../Iconify";
import useDoubleClick from "../../hooks/useDoubleClick";

export default function MethodButton({method, selectedPayments, onClick}) {
    const handlePaymentMethodClick = useDoubleClick({
        click: () => onClick(method, false),
        doubleClick: () => onClick(method, true)
    });

    return (
        <ToggleButton
            sx={{
                border: (theme) => isSelected(selectedPayments, method.value) && `1px solid ${alpha(theme.palette.primary.main, 0.74)} !important`,
                bgcolor: (theme) => isSelected(selectedPayments, method.value) && `${alpha(theme.palette.primary.main, 0.14)} !important`,
                height: 80,
                width: 110
            }}
            name={method.value}
            onClick={handlePaymentMethodClick}
            selected={isSelected(selectedPayments, method.value)}
            value={method.value}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Iconify sx={{width: 35, height: 35}} icon={method.icon}/>
                {method.displayName}
            </Box>
        </ToggleButton>
    );
}

const isSelected = (selectedPayments, method) => {
    return selectedPayments.findIndex(localMethod => localMethod.paymentType === method) !== -1;
};