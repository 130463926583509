import {createSlice} from "@reduxjs/toolkit";
import {dispatch} from '../store';
import {removeSessionOperator, setSessionOperator} from "../../helper/session";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    operatorInfo: null,
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, {payload}) {
            state.isLoading = false;
            state.error = payload;
        },

        // SET OPERATOR
        setOperatorSuccess(state, {payload}) {
            setSessionOperator(payload)
            state.operatorInfo = payload
            state.isLoading = false;
        },

        logOutOperator: (state) => {
            removeSessionOperator()
            state.operatorInfo = null;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function setOperatorInfo(operator) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setOperatorSuccess(operator));
        } catch (e) {
            dispatch(slice.actions.hasError(e))
        }
    }

}
