import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {AUTH, dbRef, storageRef} from "../../auth/FirebaseContext";
import {child, get, push, set, update} from "firebase/database";

import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {putItemsOffline} from "../../store/offlineDb";
import {addDefaultItemOnLocations, removeDefaultItemOnLocations} from "./userSettings";

export const addItem = createAsyncThunk("addItem", async (payload, {dispatch}) => {
    const { item, image } = payload;
    const itemKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
        ...item,
        inactive: false
    });
    dispatch(addDefaultItemOnLocations({
        uid: itemKey.key,
        locations: item.locations || []
    }))
    if (image) {
        let imageRef = ref(ref(storageRef, "public/itemphotos"), `${itemKey.key}.png`);
        await uploadBytes(imageRef, image);
    } else {
        delete payload?.avatarUrl;
    }
    return { ...item, uid: itemKey.key };
});

export const updateItem = createAsyncThunk("updateItem", async (payload, {dispatch}) => {
    const {item, locationsForRemove}= payload
    if (item?.avatarUrl) {
        let imageRef = ref(ref(storageRef, "public/itemphotos"), `${item.uid}.png`);
        await uploadBytes(imageRef, item?.avatarUrl);
    } else {
        delete item?.avatarUrl;
    }
    dispatch(addDefaultItemOnLocations({
        uid:item.uid,
        locations: item.locations || []
    }))
    dispatch(removeDefaultItemOnLocations({
        uid:item.uid,
        locations: locationsForRemove || []
    }))
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${item.uid}`), {
        ...item,
        inactive: false
    });
    return { ...item, inactive: false };
});

export const fetchItemLogoUrl = createAsyncThunk('fetchItemLogoUrl', async (payload) => {
    const imageRef = ref(ref(storageRef, "public/itemphotos"), `${payload.uid}.png`);
    return await getDownloadURL(imageRef);
})

export const deleteItem = createAsyncThunk('deleteItem', async (payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${payload.uid}`), {
        ...payload,
        inactive: true
    });
    return {...payload, inactive: !payload.inactive}
})

export const fetchAllMergedItems = createAsyncThunk("fetchAllMergedItems", async () => {

    let allPrivateProducts = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`))).forEach(item => {
        allPrivateProducts.push({
            ...item.val(),
            uid: item.key
        });
    });
    let publicProducts = [];
    (await get(child(dbRef, `public/items`))).forEach(item => {
        publicProducts.push({
            ...item.val(),
            uid: item.key
        });
    });
    const categories = {};
    const arr = [];
    allPrivateProducts.forEach(item => {
        let index = publicProducts.findIndex(v => v.uid === item.uid);

        const mergedItem = index === -1 ? item :
            {
                ...publicProducts[index],
                ...item,
                price: item?.price > 0 ? item.price : 0
            };
        if (mergedItem.name && mergedItem.code && mergedItem.price && mergedItem.inactive !== true) {
            arr.push(mergedItem);
            categories[mergedItem?.category?.toUpperCase()] = true;
        }
    });
    await putItemsOffline(arr);
    return { items: arr, categories: Object.keys(categories) };
});

const initialState = {
    allItemsList: [],
    allItemsCategories: [],
    loading: false
};

export const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllMergedItems.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllMergedItems.fulfilled]: (state, {payload}) => {
            state.allItemsList = payload.items
            state.allItemsCategories = payload.categories
            state.loading = false;
        },
        [fetchAllMergedItems.rejected]: (state) => {
            state.loading = false;
        },
        [addItem.pending]: (state) => {
            state.loading = true;
        },
        [addItem.fulfilled]: (state, {payload}) => {
            if(payload){
                const arr = [...current(state.allItemsList)]
                arr.push(payload)
                state.allItemsList = arr
                state.error = undefined
                state.loading = false
            }
        },
        [addItem.rejected]: (state) => {
            state.loading = false;
        },
        [updateItem.pending]: (state) => {
            state.loading = true;
        },
        [updateItem.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allItemsList)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.allItemsList = arr
            }
            state.loading = false
            state.error = undefined
        },
        [updateItem.rejected]: (state) => {
            state.loading = false;
        },
        [deleteItem.pending]: (state) => {
            state.loading = true;
        },
        [deleteItem.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allItemsList)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            arr.splice(index, 1)
            state.allItemsList = arr
            state.error = undefined
        },
        [deleteItem.rejected]: (state) => {
            state.loading = false;
        }
    }
});

export const itemsReducer = itemsSlice.reducer
