import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import moment from "moment/moment";
import {getAllTripTimesByOperatorApi, insertTripAPI, putTripAPI} from "../../api/trip";

export const getAllTripTimesByOperator = createAsyncThunk('getAllTripTimesByOperator', async (payload) => {
    return (await getAllTripTimesByOperatorApi(payload)).data
})


export const postDeparture = createAsyncThunk('postDeparture', async (payload) => {
    return insertTripAPI(payload);
})


export const updateDeparture = createAsyncThunk('updateDeparture', async (payload) => {
    return putTripAPI(payload);
})

const initialState = {
    departures: [],
    foundDepartures: [],
    tripTimes: [],
    departure: null,
    loadingDepartures: false
}

export const departureSlice = createSlice({
    name: 'departures',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllTripTimesByOperator.pending]: (state) => {
            state.loadingTrips = true;
        },
        [getAllTripTimesByOperator.fulfilled]: (state, {payload}) => {
            state.tripTimes = payload.sort((a, b) => moment(a, 'HH:mm:ss').diff(moment(b, 'HH:mm:ss')));
            state.loadingTrips = false;
        },
        [getAllTripTimesByOperator.rejected]: (state) => {
            state.loadingTrips = false;
        },
        [updateDeparture.pending]: (state) => {
            state.loadingTrips = true;
        },
        [updateDeparture.fulfilled]: (state) => {
            state.loadingTrips = false;
        },
        [updateDeparture.rejected]: (state) => {
            state.loadingTrips = false;
        },
        [postDeparture.pending]: (state) => {
            state.loadingTrips = true;
        },
        [postDeparture.fulfilled]: (state) => {
            state.loadingTrips = false;
        },
        [postDeparture.rejected]: (state) => {
            state.loadingTrips = false;
        }
    }
});

export const departureReducer = departureSlice.reducer
