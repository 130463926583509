import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deactivateActionAPI,
    deleteActionsAPI,
    getAllActionsAPI,
    insertActionsAPI,
    updateActionsAPI
} from "../../api/actions";

export const fetchAllActions = createAsyncThunk('fetchAllActions', async (data) => {
    return await getAllActionsAPI(data)
})

export const insertNewActions = createAsyncThunk('insertNewActions', async (data) => {
    return await insertActionsAPI(data)
})

export const deleteAction = createAsyncThunk('deleteAction', async (data) => {
    return await deleteActionsAPI(data)
})

export const deactiavteAction = createAsyncThunk('deactiavteAction', async (data) => {
    return await deactivateActionAPI(data)
})

export const updateActions = createAsyncThunk('updateActions', async (data) => {
    return await updateActionsAPI(data)
})


const initialState = {
    allActions: [],
    foundActions: [],
    loadingActions: false,
}

export const actionsSlice = createSlice({
    name: 'actions', initialState,

    reducers: {
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingRFIDCards = payload
            }
        },
        searchActions: (state, {payload}) => {
            if (payload?.content) {
                state.foundActions = payload;
                state.loadingActions = false
            }
        }
    },
    extraReducers: {
        [fetchAllActions.pending]: (state) => {
            state.loadingActions = true;
        },
        [fetchAllActions.fulfilled]: (state, {payload}) => {
            state.allActions = payload.data;
            state.foundActions = payload.data;
            state.loadingActions = false;
        },
        [fetchAllActions.rejected]: (state) => {
            state.loadingActions = false;
        },
        [insertNewActions.pending]: (state) => {
            state.loadingActions = true;
        },
        [insertNewActions.fulfilled]: (state, {payload}) => {
            state.loadingActions = false;
        },
        [insertNewActions.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [deleteAction.pending]: (state) => {
            state.loadingActions = true;
        },
        [deleteAction.fulfilled]: (state, action) => {
            let arr = [...current(state.allActions)]
            let index = arr.findIndex(i => i.cardNumber === action.meta.arg.cardNumber)
            if (index !== -1) {
                arr.splice(index, 1)
                state.allActions = arr
            }
            state.loadingActions = false;
        },
        [deleteAction.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [deactiavteAction.pending]: (state) => {
            state.loadingActions = true;
        },
        [deactiavteAction.fulfilled]: (state, {payload}) => {
            //TODO ispraviti
            const arr = [...current(state.foundActions.content)];
            const foundActions = { ...current(state.foundActions) };
            const index = arr.findIndex(f => f.id === payload.id);
            if (index !== -1) {
                arr[index] = payload;
            }
            foundActions.content = arr;
            state.foundActions = foundActions;
            state.loadingActions = false;
        },
        [deactiavteAction.rejected]: (state) => {
            state.loadingRFIDCards = false;
        }
    }
});
export const {searchActions, specialLoading} = actionsSlice.actions;

export default actionsSlice.reducer

