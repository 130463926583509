import {customAxiosBE} from "../config";
import moment from "moment/moment";
import {parsePaginationParams} from "../../utils/other";
import axios from "axios";

export async function getDepAndArrStationsApi(data) {
    return customAxiosBE.post(`/trip/depAndArrStations`, data)
}

export async function putTripAPI(data) {
    return customAxiosBE.put(`/trip`, data)
}

export async function updateTripByLine(data) {
    return customAxiosBE.put(`/trip/updateTripByLine`, data)
}

export async function insertTripAPI(data) {
    return customAxiosBE.post(`/trip`, data)
}

export async function getTripByIdApi(id) {
    return customAxiosBE.get(`/trip/${id}`)
}

export async function getTripHasVehiclesByIdApi(id) {
    return customAxiosBE.get(`trip/tripHasVehicles/${id}`)
}

export async function getLineByTripId(id) {
    return customAxiosBE.get(`/trip/${id}/line`)
}
export async function getPricePreviewByLineIdRateCardId(data) {
    const {lineId, rateCardId, operatorId, arrStationId} = data
    if(operatorId){
        return customAxiosBE.get(`/trip/price-preview?lineId=${lineId}&rateCardId=${rateCardId}&operatorId=${operatorId}`)
    }
    return customAxiosBE.get(`/trip/price-preview?lineId=${lineId}&rateCardId=${rateCardId}`)
}


export async function getByLineId(id) {
    return customAxiosBE.get(`/trip/byLine/${id}`)
}

export async function getByLineName(lineName) {
    try {
        return await customAxiosBE.post(`/trip/byLineName`, lineName, {
            headers: {
                'Content-Type': 'text/plain'
            }
        });
    } catch (error) {
        console.error("Error fetching trips by line name:", error);
    }
}

export async function searchTripApi(data) {
    return customAxiosBE.post(`/trip/search`, data)
}

// POVRATAK IZ MESTA
export async function searchTripReturnApi(data) {
    return customAxiosBE.post(`/trip/searchTripReturn`, data)
}

export async function copyTripApi(data) {
    return customAxiosBE.post(`/trip/copy`, data)
}

export async function getAllTripTimesByOperatorApi() {
    return await customAxiosBE.get(`/trip/times`)
}

export function deactivateTripApi(id) {
    return customAxiosBE.put(`/trip/deactivate/${id}`)
}

export function activateTripApi(obj) {
    return customAxiosBE.put(`/trip/activate`, obj)
}

export function cancelTripApi(id, payload) {
    return customAxiosBE.post(`/trip/${id}/cancelToPeriod`, payload);
}

export function activateTripAPI(id) {
    return customAxiosBE.post(`/trip/${id}/activate`)
}

export function getTripsByDepStationAndArrStation(depId, arrId, tripId) {
    return customAxiosBE.get(`/trip/depStation/${depId}/arrStation/${arrId}?tripId=${tripId}`)
}

export function getTripsByDepStationAndArrStationByOperatorId(depId, arrId, operatorId) {
    return customAxiosBE.get(`/trip/depStation/${depId}/arrStation/${arrId}/operatorId/${operatorId}`)
}

export function getAllInverseReturnTrips(data) {
    return customAxiosBE.post(`/trip/allInverseReturnTrips`, data)
}

export const getAllInverseReturnTripsAPI = async (data, cancelToken) => {
    let response
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/trip/allInverseReturnTrips?${parsedParams}`, {
                lineName: data.lineName || null,
                operatorName: data.operatorName || null,
                arrStationId: data.arrStationId || null,
                depStationId: data.depStationId || null,
                date: data.dateFrom || null,
            },
            {
                cancelToken
            })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
export async function insertCommentAPI(data) {
    return customAxiosBE.post(`/trip/addComment`, data)
}

export async function getCommentsAPI(id, date) {
    return customAxiosBE.get(`/trip/${id}/info?date=${date}`)
}

export async function getAllLinkedTrioOptions(stationId) {
    return customAxiosBE.get(`/trip/linkedTripOptions/${stationId}`)
}

export async function getitinerariesAPI(id, date) {
    return customAxiosBE.post(`/trip/${id}/itineraries`, {
        tripTime: moment(date).format("YYYY-MM-DD")
    })
}

export async function occupiedSeatsAPI({
                                           tripId,
                                           journeyDate,
                                           fromStationId,
                                           toStationId,
                                           allotmentId
                                       }) {
    return customAxiosBE.get(`/trip/${tripId}/seats/occupied?journeyDate=${journeyDate}&depId=${fromStationId}&arrId=${toStationId}`);
}


export async function allSeats(data) {
    const {id, date, depId, arrId} = data
    let url = `/trip/${id}/seats?date=${date}`;
    if (depId) {
        url += `&depId=${depId}`;
    }
    if (arrId) {
        url += `&arrId=${arrId}`;
    }
    return customAxiosBE.get(url);
}
