import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { AUTH, dbRef } from "../../auth/FirebaseContext";
import { child, get } from "firebase/database";
import moment from "moment";
import { ALL_LICENSE } from "../../constants";

export const fetchAllUserLicense = createAsyncThunk("fetchAllUserLicense", async () => {
  return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/license`))).val();
});

const initialState = {
  licenses: [],
  loading: false,
  isWebLicenseExpired: false
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    changedLicense: (state, { payload }) => {
      if (payload) {
        let arr = [...current(state).licenses];
        state.licenses = arr;
        let webLicense = arr.filter(license => license.productID === ALL_LICENSE.webESIR);
        state.isWebLicenseExpired = moment(webLicense[0].expiredDate).isBefore(moment.now());
      }
    },
    updateIsExpired: (state) => {
      state.isWebLicenseExpired = true;
    }
  },
  extraReducers: {
    [fetchAllUserLicense.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllUserLicense.fulfilled]: (state, { payload }) => {
      if(payload){
        state.licenses = payload;
        let webLicense = payload.filter(license => license?.productID === ALL_LICENSE.webESIR);
        state.isWebLicenseExpired = moment(webLicense[0]?.expiredDate).isBefore(moment.now());
      }
      state.loading = false;
    },
    [fetchAllUserLicense.rejected]: (state) => {
      state.loading = false;
    }
  }
});
export const licenseReducer = licenseSlice.reducer;
export const { changedLicense, updateIsExpired } = licenseSlice.actions;
