import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

//PERIOD
export async function getAllHolidaysAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.post(`/period/search?${parsedParams}`,{})
}

export async function insertHolidaysAPI(payload) {
    return customAxiosBE.post(`/period`, payload)
}

export async function deleteHolidaysByIdAPI(id) {
    return customAxiosBE.delete(`/period/${id}`)
}

export async function updateHolidaysAPI(payload) {
    return customAxiosBE.put(`/period`, payload)
}

export const searchHolidayAPI = async (data, cancelToken) => {
    let response
    const {name, dateFrom, dateTo, operatorId, operatorName} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/period/search?${parsedParams}`, {
            name: name,
            operatorName: operatorName,
            dateFrom: dateFrom,
            dateTo: dateTo,
            operatorId: operatorId
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
