// @mui
import {Skeleton, Stack, TableCell, TableRow} from '@mui/material';

// ----------------------------------------------------------------------

export default function TableSkeleton({ columns = 5, ...other }) {
    return (
        <TableRow {...other}>
            {Array.from({ length: columns }, (_, i) => (
                <TableCell key={i}>
                    <Skeleton variant="text" width={"100%"} height={40} />
                </TableCell>
            ))}
        </TableRow>
    );
}
