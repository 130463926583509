import {customAxiosBE} from "../config";
import axios from "axios";

export async function getAllCountriesAPI(payload) {
    return customAxiosBE.get(`/country?page=${payload.page}&size=${payload.rowsPerPage}`)
}

export async function getCountryAPI(id) {
    return customAxiosBE.get(`/country/${id}`)
}

export async function insertCountryAPI(data){
    return customAxiosBE.post(`/country`, data)
}
export async function updateCountryAPI(data){
    return customAxiosBE.put(`/country`, data)
}

export async function deleteCountryAPI(data){
    return customAxiosBE.delete(`/country`, data)
}
export const searchCountryAPI = async (data, cancelToken) => {
    let response
    const {name, page, rowsPerPage} = data
    await customAxiosBE
        .post(`/country/search?page=${page}&size=${rowsPerPage}`, {
            name: name
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
