import {fCurrency} from "../../utils/formatNumber";
import {translate} from "../../locales/i18n";
import {getDataForPrintingTicket} from "../../helper";

let fontSize = "0.7rem";

const style = `
  @media print{ 
    @page{
      size: portrait;  
      margin: 0px !important;
      padding: 0;
      width: 100%,
    }
    body {
      margin-bottom: 20mm; 
    }
  }
`;

export default function TicketSlip(ticket, services, preview = false, advertisementTextTicket,
                                   confirmation = false, logo,
                                   isSilentPrint = false,
                                   printerSize) {

    console.debug("TicketSlip: ", {
        ticket, services, preview, advertisementTextTicket, confirmation, logo, isSilentPrint, printerSize
    });

    const {
        ticketId,
        operator,
        departureStationName,
        arrivalStationName,
        ticketType,
        journeyDate,
        departureTime,
        price,
        cashier,
        salesTime,
        platformNumber,
        seat,
        // passengerDetails,
        passengerDocumentNumber,
        maxDaysOfReturnDate,
        outboundTicketId,
        issuedBy
    } = getDataForPrintingTicket(ticket)

    let width = 360;
    fontSize = isSilentPrint ? "19px" : fontSize;
    if (printerSize) {
        if (printerSize == "80") {
            width = 560;
            fontSize = "29px"
        }
    }

    let total = ticket.price
    if (services) {
        total = total + services.reduce((acc, {unitPrice}) => {
            return acc + unitPrice
        }, 0)
    }

    return (
        <html lang="sr">
        <head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <style type="text/css">{style}</style>
            <title>Ticket {ticketId}</title>
        </head>
        <body
            id="forPrint"
            style={{
                fontFamily: "Calibri",
                margin: 0,
                width: "100%",
                maxWidth: isSilentPrint ? "100%" : "80mm",
                justifyContent: "center",
                display: "inline-block",
                height: "auto",
                wordBreak: "break-word",
                fontSize: fontSize
            }}>
        <table style={{
            width: preview ? "300px" : (isSilentPrint ? width : "auto"),
            margin: 0
        }}>
            <tbody style={{marginBottom: "20px"}}>
            {logo ?
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <img alt="logo" src={logo} width={isSilentPrint ? width - 100 : "50%"}
                             height="auto"/>
                    </td>
                </tr> : <></>
            }
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    {issuedBy}
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    AUTOBUSKA STANICA
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    {confirmation ? "ID:" : "Broj karte:"}
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {ticketId}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Operater:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {cashier}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Izdato:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {salesTime}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{textAlign: "center", fontWeight: 700, fontSize: fontSize}} colSpan={4}>
                    {confirmation ? "POTVRDA CENE KARTE" :
                        advertisementTextTicket === translate("testTicketText") ? "TEST KARTA" :
                            `AUTOBUSKA KARTA - ${ticket.isReturnTicket ? "POVRATNA" : "JEDAN SMER"}`}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize, fontWeight: "bold"}} colSpan={2}>
                    {departureStationName}
                </td>
                <td style={{fontSize: fontSize, textAlign: "right", fontWeight: "bold"}} colSpan={2}>
                    {arrivalStationName}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Prevoznik
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {operator}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize, justifyContent: "space-between"}} colSpan={2}>
                    Vrsta karte:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {ticketType}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Datum putovanja:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {journeyDate}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Vreme:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {departureTime}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Peron/bis:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {platformNumber}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Sedište:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {seat}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Cena:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {price}
                </td>
            </tr>
            {!ticket.isReturnTicket && services?.length > 0 && services?.map((item, serviceIndex) => (
                <tr key={`service-${serviceIndex}`} style={{justifyContent: "space-between"}}>
                    <td style={{fontSize: fontSize}} colSpan={2}>
                        {item.name}
                    </td>
                    <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                        {fCurrency(`${item.unitPrice}`)}
                    </td>
                </tr>
            ))}
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Ukupno:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {ticket.isReturnTicket ? price : fCurrency(`${total}`)}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                {/*<td style={{fontSize: fontSize}} colSpan={2}>*/}
                {/*    {passengerDetails}*/}
                {/*</td>*/}
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {passengerDocumentNumber}
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize, fontStyle: "italic"}} colSpan={4}>
                    Srećan put želi Vam AS NS
                </td>
            </tr>
            {(confirmation) ?
                <></>
                :
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {ticket.parsedQR && <img
                                style={{
                                    minWidth: isSilentPrint ? 360 : "40mm",
                                    minHeight: isSilentPrint ? 360 : "40mm",
                                    maxWidth: isSilentPrint ? 360 : "50mm",
                                    maxHeight: isSilentPrint ? 360 : "50mm"
                                }}
                                width={isSilentPrint ? 360 : "100%"}
                                height={isSilentPrint ? 360 : "100%"}
                                alt="qr code"
                                src={ticket.parsedQR}/>
                            }
                        </div>
                    </td>
                </tr>
            }
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize, fontStyle: "italic"}} colSpan={4}>
                    {advertisementTextTicket}
                </td>
            </tr>
            {maxDaysOfReturnDate ?
                <tr style={{justifyContent: "space-between"}}>
                    <td style={{fontSize: fontSize}} colSpan={2}>
                        Povratna karta važi:
                    </td>
                    <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                        {maxDaysOfReturnDate} dana
                    </td>
                </tr> : <></>}
            {outboundTicketId ?
                <tr>
                    <td style={{fontSize: fontSize, textAlign: "center",}} colSpan={4}>
                        Važi uz kartu {outboundTicketId}
                    </td>
                </tr> : <></>
            }
            </tbody>
        </table>
        </body>
        </html>
    );
};
