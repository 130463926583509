import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";
import moment from "moment/moment";

export async function getAllUnUsedEventsAPI(filter, params) {
    const parsedParams = parsePaginationParams(params)
    const parsedDate = moment(filter.date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    return customAxiosBE.post(`/camera/unused-events?${parsedParams}`, {
        ...filter,
        date: parsedDate
    })
}

export async function findCameraEntryAPI(data){
    return customAxiosBE.post(`/camera/search-by-license-plate`, data)
}

export async function repeatCameraEventAPI(id){
    return customAxiosBE.get(`/camera/repeat-event/${id}`)
}

export async function setCameraEventToUsedAPI(data){
    return customAxiosBE.post(`/camera/used`, data)
}

export async function getCameraEventByTrafficLogIdAPI(id){
    return customAxiosBE.get(`/camera/traffic-log-id/${id}`)
}
