import React, {useRef, useState} from 'react';
import * as Yup from 'yup';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Alert, IconButton, InputAdornment, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// auth
import {useAuthContext} from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/Iconify';
import FormProvider, {RHFTextField} from '../../../components/hook-form';
import {getSessionTheme} from "../../../helper/session";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

// ----------------------------------------------------------------------

const KEYBOARDS = {
    default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = DEL",
        "{tab} q w e r t y u i o p [ ] \\",
        "{lock} a s d f g h j k l ; ' ",
        "{shift} z x c v b n m , . / {shift}",
        ".com @ {space}"
    ],
    shift: [
        "~ ! @ # $ % ^ & * ( ) _ + DEL",
        "{tab} Q W E R T Y U I O P { } |",
        "{lock} A S D F G H J K L : \" ",
        "{shift} Z X C V B N M < > ? {shift}",
        ".com @ {space}"
    ]
};

const keyboard_layout_name = {
    default: "default",
    shift: "shift"
};

export default function AuthLoginForm() {
    const {login} = useAuthContext();

    const [keyboardLayout, setKeyboardLayout] = useState(keyboard_layout_name.default);

    const [showPassword, setShowPassword] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Mejl adresa mora biti validnog formata!').required('Mejl je obavezan!'),
        password: Yup.string().required('Lozinka je obavezna!'),
    });

    const defaultValues = {
        email: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting, isSubmitSuccessful},
    } = methods;

    const emailRef = useRef();
    const passwordRef = useRef();
    const [activeInputRef, setActiveInputRef] = useState(null);

    const localOnKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") {
            setKeyboardLayout(prevState => prevState === keyboard_layout_name.default ?
                keyboard_layout_name.shift : keyboard_layout_name.default);
            return;
        }
        onKeyPress(button);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") {
            setKeyboardLayout(prevLayout => (prevLayout === "default" ? "shift" : "default"));
        } else {
            const value = activeInputRef.current.value;
            if (button === "DEL") {
                activeInputRef.current.value = value.slice(0, -1);
            } else if (button === "{space}") {
                activeInputRef.current.value = value + " ";
            } else {
                activeInputRef.current.value = value + button;
            }
            setValue(activeInputRef.current.name, activeInputRef.current.value);
        }
    };

    const onFocusInput = (ref) => {
        setActiveInputRef(ref);
    };

    const onSubmit = async (data) => {
        try {
            await login(data.email.trim(), data.password.trim());
        } catch (error) {
            console.error(error);

            reset();

            setError('afterSubmit', {
                ...error,
                message: 'Uneti kredencijali nisu validni!',
            });
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                <RHFTextField name="email"
                              label="Mejl"
                              inputRef={emailRef}
                              onClick={() => onFocusInput(emailRef)}/>

                <RHFTextField
                    name="password"
                    label="Lozinka"
                    type={showPassword ? 'text' : 'password'}
                    inputRef={passwordRef}
                    onClick={() => onFocusInput(passwordRef)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <Stack direction="row" spacing={1.2} sx={{mt:2}}>
                <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitSuccessful || isSubmitting}
                    sx={{
                        mt: 1,
                        bgcolor: 'text.primary',
                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                        '&:hover': {
                            bgcolor: 'text.primary',
                            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                        },
                    }}
                >
                    Prijavi se
                </LoadingButton>
                <IconButton onClick={() => setShowKeyboard(!showKeyboard)} edge="end">
                    <Iconify icon={!showKeyboard ? 'ic:baseline-keyboard' : 'material-symbols:keyboard-off'}/>
                </IconButton>
            </Stack>

            {showKeyboard &&
                <Stack sx={{display: 'flex', alignItems: 'center'}}>
                    <Keyboard
                        theme={getSessionTheme() === "dark" ? "hg-theme-default dark" : "hg-theme-default"}
                        onKeyPress={button => localOnKeyPress(button)}
                        layoutName={keyboardLayout}
                        layout={{
                            default: KEYBOARDS.default,
                            shift: KEYBOARDS.shift
                        }}
                    />
                </Stack>
            }
        </FormProvider>
    );
}
