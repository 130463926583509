import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCommissionsReportApi, getOperatorIncomeApi, getTicketsReportApi} from "../../api/reports";

export const fetchOperatorIncome =
    createAsyncThunk("fetchOperatorIncome", async (data)=>{
        return (await getOperatorIncomeApi(data)).data
})

export const fetchTicketsReport =
    createAsyncThunk("fetchTicketsReport", async (data)=>{
        return (await getTicketsReportApi(data)).data
    })

export const fetchCommissionsReport =
    createAsyncThunk("fetchCommissionsReport", async (data)=>{
        return (await getCommissionsReportApi(data)).data
    })

const initialState = {
    carrierIncome:{
        normalTicket: 0,
        discountedTicketTotal: 0,
        discountedTicketCanRefund: 0,
        discountedTicketCanNotRefund: 0,
        vzpTotal: 0,
        vzpEarnings: 0,
        vgpTotal: 0,
        vgpEarnings: 0,
        normalTickets: [],
        discountedTickets: [],
    },
    tickets: [],
    commissions: [],
    loading: false
}

export const slice = createSlice({
    name: 'springReports',
    initialState,
    reducers: {
        resetCarrierIncome: (state) => {
            state.carrierIncome = initialState.carrierIncome
        },
        resetCommissions: (state) => {
            state.commissions = initialState.commissions
        },
        resetTicketsReport: (state) => {
            state.tickets = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOperatorIncome.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchOperatorIncome.fulfilled, (state, {payload}) => {
                state.carrierIncome = payload;
                state.loading = false;
            })
            .addCase(fetchOperatorIncome.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchTicketsReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTicketsReport.fulfilled, (state, {payload}) => {
                state.tickets = payload;
                state.loading = false;
            })
            .addCase(fetchTicketsReport.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchCommissionsReport.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCommissionsReport.fulfilled, (state, {payload}) => {
                state.commissions = payload;
                state.loading = false;
            })
            .addCase(fetchCommissionsReport.rejected, (state) => {
                state.loading = false;
            })
    }
});

export const {resetCarrierIncome, resetTicketsReport, resetCommissions} = slice.actions;
export default slice.reducer;
