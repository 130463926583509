import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import NavItem from './NavItem';
import {getIniLoggedUser} from "../../../helper/session";
import {AUTH} from "../../../auth/FirebaseContext";

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChild: PropTypes.bool,
};

export default function NavList({ operator, data, depth, hasChild }) {
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList operator={operator} data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ operator, data, depth }) {
  const primaryUser = getIniLoggedUser();
  const isPrimary = primaryUser?.uid === AUTH.currentUser.uid
  data = data.filter(value => {
    if (value.needPrimaryUser === undefined || value.needPrimaryUser === false) {
      return true
    }
    return isPrimary;
  })
  return (
      <>
        {data.map((list) => {
          if (list.title === "Dodavanje mesečne karte" && operator !== 0) {
            return null;
          }

          return (
              <NavList key={list.title + list.path} data={list} depth={depth + 1} hasChild={!!list.children} />
          );
        })}
      </>
  );
}

