import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deleteRFIDCardAPI,
    extendRFIDCardAPI,
    getAllRFIDAPI,
    insertRFIDActionAPI,
    insertRFIDAPI,
    updateRFIDCardAPI
} from "../../api/rfid";
import moment from "moment/moment";
import {parsePaginationParams} from "../../utils/other";

export const fetchAllRFIDCard = createAsyncThunk('fetchAllRFIDCard', async (payload) => {
    const {filter, params} = payload;
    let validFrom = filter.validFrom ? filter.validFrom : null;
    let validTo = filter.validTo? filter.validTo : null;
    if (validFrom) {
        validFrom = moment(validFrom).format("DD.MM.YYYY")
    }
    if (validTo) {
        validTo = moment(validTo).format("DD.MM.YYYY")
    }
    const parsedParams = parsePaginationParams(params)
    return await getAllRFIDAPI({
        ...filter,
        validFrom,
        validTo
    }, parsedParams)
})

export const insertNewRFIDCard = createAsyncThunk('insertNewRFIDCard', async (data) => {
    return await insertRFIDAPI(data)
})

export const insertNewRFIDCardAction = createAsyncThunk('insertNewRFIDCardAction', async (data) => {
    return await insertRFIDActionAPI(data)
})

export const extendRFIDCard = createAsyncThunk('extendRFIDCard', async (data) => {
    return await extendRFIDCardAPI(data)
})

export const deleteRFIDCard = createAsyncThunk('deleteRFIDCard', async (data) => {
    return await deleteRFIDCardAPI(data)
})

export const updateRFIDCard = createAsyncThunk('updateRFIDCard', async (data) => {
    return await updateRFIDCardAPI(data)
})


const initialState = {
    allRFIDCards: {
        content: [],
        totalElements: 0
    },
    foundRFIDCards: [],
    loadingRFIDCards: false,
}

export const RFIDSlice = createSlice({
    name: 'rfid', initialState,

    reducers: {
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingRFIDCards = payload
            }
        },
        searchRFIDCard: (state, {payload}) => {
            if (payload?.content) {
                state.foundRFIDCards = payload;
                state.loadingRFIDCards = false
            }
        }
    },
    extraReducers: {
        [fetchAllRFIDCard.pending]: (state) => {
            state.loadingRFIDCards = true;
        },
        [fetchAllRFIDCard.fulfilled]: (state, {payload}) => {
            state.allRFIDCards = payload.data;
            state.foundRFIDCards = payload.data;
            state.loadingRFIDCards = false;
        },
        [fetchAllRFIDCard.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [insertNewRFIDCard.pending]: (state) => {
            state.loadingRFIDCards = true;
        },
        [insertNewRFIDCard.fulfilled]: (state) => {
            state.loadingRFIDCards = false;
        },
        [insertNewRFIDCard.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [insertNewRFIDCardAction.pending]: (state) => {
            state.loadingRFIDCards = true;
        },
        [insertNewRFIDCardAction.fulfilled]: (state) => {
            state.loadingRFIDCards = false;
        },
        [insertNewRFIDCardAction.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [deleteRFIDCard.pending]: (state) => {
            state.loadingRFIDCards = true;
        },
        [deleteRFIDCard.fulfilled]: (state, action) => {
            const currentState = current(state.allRFIDCards);
            let arr = [...currentState.content]
            let index = arr.findIndex(i => i.cardNumber === action.meta.arg)
            if (index !== -1) {
                arr.splice(index, 1)
                state.allRFIDCards = {
                    ...currentState,
                    content: arr
                }
            }
            state.loadingRFIDCards = false;
        },
        [deleteRFIDCard.rejected]: (state) => {
            state.loadingRFIDCards = false;
        },
        [extendRFIDCard.pending]: (state) => {
            state.loadingRFIDCards = true;
        },
        [extendRFIDCard.fulfilled]: (state, {payload}) => {
            const currentState = current(state.allRFIDCards);
            let arr = [...currentState.content]
            if (payload.data.cardNumber) {
                let index = arr.findIndex(i => i.cardNumber === payload.data.cardNumber)
                if (index !== -1) {
                    arr[index] = payload.data
                    state.allRFIDCards = {
                        ...currentState,
                        content: arr
                    }
                }
            }
            state.loadingRFIDCards = false;
        },
        [extendRFIDCard.rejected]: (state) => {
            state.loadingRFIDCards = false;
        }
    }
});
export const {specialLoading} = RFIDSlice.actions;

export const RFIDReducer = RFIDSlice.reducer

