import {customAxiosBE} from "../config";
let abortController = null;

export async function getStationScheduler(depId) {
    if (abortController) {
        abortController.abort();
    }

    // Kreiraj novi AbortController
    abortController = new AbortController();

    return await customAxiosBE.get(`station-schedule/${depId}`, {
        signal: abortController.signal
    });
}
