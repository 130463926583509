import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit';
import {child, get} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";

export const getIsTaxFree = createAsyncThunk('getIsTaxFree', async () => {
    return (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/transport/vatCategory`))).val();
})

const initialState = {
    localInvoice: undefined,
    isTaxFree: undefined,
    loading: false,
    error: undefined
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setLocalInvoice: (state, action) => {
            state.localInvoice = action.payload;
        },
        setDefaultLocalInvoice: (state) => {
            state.localInvoice = undefined
        },
    },
    extraReducers: {
        [getIsTaxFree.pending]: (state) => {
            state.loading = true;
        },
        [getIsTaxFree.fulfilled]: (state, {payload}) => {
            state.isTaxFree = payload
            state.loading = false;
        },
        [getIsTaxFree.rejected]: (state, {payload}) => {
            state.error = payload;
            state.isTaxFree = undefined;
            state.loading = false;
        }
    }
});

export const servicesReducer = servicesSlice.reducer

