import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteLineStationAPI,
    findStationsBySearchDataAPI,
    getAllByLineId, getAllLineStationsByLineIdsAPI,
    insertLineStationAPI,
    updateLineStationAPI
} from "../../api/lineStation";

export const findStationsBySearchData = createAsyncThunk('findStationsBySearchData', async (payload) => {
    return await findStationsBySearchDataAPI(payload)
})

export const deleteLineStation = createAsyncThunk('findStationsBySearchData', async (id) => {
    return await deleteLineStationAPI(id)
})

export const getAllLineStationsByLineIds = createAsyncThunk('findStationsBySearchData', async (payload) => {
    return await getAllLineStationsByLineIdsAPI(payload)
})

export const findArrStationsBySearchData = createAsyncThunk('findArrStationsBySearchData', async (payload) => {
    return (await findStationsBySearchDataAPI(payload)).data
})

export const fetchLineStationsByLineId = createAsyncThunk('fetchLineStationsByLineId', async (lineId) => {
    return (await getAllByLineId(lineId)).data
})

export const insertLineStation = createAsyncThunk('insertLineStation', async (payload) => {
    return insertLineStationAPI(payload)
})

export const updateLineStation = createAsyncThunk('updateLineStation', async (payload) => {
    return updateLineStationAPI(payload)
})

const initialState = {
    lineStations: [],
    foundStations: [],
    arrStations: [],
    loadingLineStations: false,
    loadingArrStations: false,
}

export const lineStationsSlice = createSlice({
    name: 'lineStations',
    initialState,
    reducers: {
        resetStationSearch: (state) => {
            state.foundStations = [];
            state.arrStations = [];
        },
        searchAllStationsByLineIdSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundStations = payload;
            } else {
                state.foundStations = [];
            }
        }
    },
    extraReducers: {
        [findArrStationsBySearchData.pending]: (state) => {
            state.loadingArrStations = true;
        },
        [findArrStationsBySearchData.fulfilled]: (state, {payload}) => {
            state.arrStations = payload;
            state.loadingArrStations = false;
        },
        [findArrStationsBySearchData.rejected]: (state) => {
            state.loadingArrStations = false;
        },
        [findStationsBySearchData.pending]: (state) => {
            state.loadingLineStations = true;
        },
        [findStationsBySearchData.fulfilled]: (state, {payload}) => {
            if (payload?.data) {
                state.foundStations = payload.data;
            }
            state.loadingLineStations = false;
        },
        [findStationsBySearchData.rejected]: (state) => {
            state.loadingLineStations = false;
        },
        [fetchLineStationsByLineId.pending]: (state) => {
            state.loadingLineStations = true;
        },
        [fetchLineStationsByLineId.fulfilled]: (state, {payload}) => {
            state.loadingLineStations = false;
            state.lineStations = payload.content;
        },
        [fetchLineStationsByLineId.rejected]: (state) => {
            state.loadingLineStations = false;
        }
    }
});
export const {searchAllStationsByLineIdSlice, resetStationSearch} = lineStationsSlice.actions;

export const lineStationReducer = lineStationsSlice.reducer
