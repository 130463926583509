import {customAxiosBE, resetTokenCache} from "../config";

export async function changeUser(data) {
    resetTokenCache(); // Resetujte token cache kada se menja korisnik
    return customAxiosBE.post(`/other/changeUser`, data)
}

export async function me() {
    resetTokenCache();
    return customAxiosBE.get(`/operator/me`)
}
