import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllTripViewAPI} from "../../api/tripView";
import {insertCommentAPI} from "../../api/trip";

export const fetchAllTrip = createAsyncThunk('fetchAllTrip', async (data) => {
    return await getAllTripViewAPI(data)
})

export const insertComment = createAsyncThunk('insertComment', async (payload) => {
    return await insertCommentAPI(payload)
})

const initialState = {
    allTripsView: [],
    foundTripsView: [],
    loadingTripsView: false,
}

export const tripViewSlice = createSlice({
    name: 'tripsView', initialState,

    reducers: {
        setLoadingTripsView: (state, {payload}) => {
            if (payload) {
                state.loadingTripsView = payload
            }
        },
        searchTripView: (state, {payload}) => {
            if (payload?.content) {
                state.foundTripsView = payload;
                state.loadingTripsView = false
            }
        }
    },
    extraReducers: {
        [fetchAllTrip.pending]: (state) => {
            state.loadingTripsView = true;
        },
        [fetchAllTrip.fulfilled]: (state, {payload}) => {
            state.allTripsView = payload.data;
            state.foundTripsView = payload.data;
            state.loadingTripsView = false;
        },
        [fetchAllTrip.rejected]: (state) => {
            state.loadingTripsView = false;
        },
        [insertComment.pending]: (state) => {
            state.loadingTripsView = true;
        },
        [insertComment.fulfilled]: (state) => {
            state.loadingTripsView = false;
        },
        [insertComment.rejected]: (state) => {
            state.loadingTripsView = false;
        }
    }
});
export const {searchTripView, setLoadingTripsView} = tripViewSlice.actions;

export default tripViewSlice.reducer

