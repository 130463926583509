import {createSvgIcon} from "@mui/material";
import Iconify from "../components/Iconify";

export const  ICONS_NAME = {
    edit: "lets-icons:edit-duotone",
    more: "eva:more-vertical-fill",
    dropDown: "solar:point-on-map-bold-duotone",
    trash: "solar:trash-bin-minimalistic-bold-duotone",
    calendar: "solar:calendar-bold-duotone",
    clock: "uim:clock",
    bus: "solar:bus-bold-duotone",
    document: "solar:document-bold-duotone",
    add: "fluent-mdl2:add-to",
    remove: "mdi:remove",
    preview: "solar:eye-bold-duotone",
    seat: "mingcute:seat-fill",
    checked: "ph:check-duotone",
    notChecked: "lets-icons:cancel-duotone-line",
    location: "fluent:location-16-filled",
}

export function CalendarIconSvg(size) {
    return <Iconify icon={ICONS_NAME.calendar} width={size ? size : 10} />;
}
