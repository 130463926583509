import moment from "moment-timezone";
import {fCurrency} from "../../utils/formatNumber";

let fontSize = "0.7rem";

const style = `
  @media print{ 
    @page{
      size: portrait;  
      margin: 0px !important;
      padding: 0;
    }
    body {
      margin-bottom: 20mm; 
    }
  }
`;


export default function PeronSlip(peronId, service, logo, qrCode, isSilentPrint = false, printerSize, additional) {
    console.debug("Peron slip:", {peronId, service, logo, qrCode, isSilentPrint, printerSize, additional});

    let width = 360;
    fontSize = isSilentPrint ? "19px" : fontSize;
    if (printerSize) {
        if (printerSize == "80") {
            width = 560;
            fontSize = "29px";
        }
    }

    return (
        <html lang="sr">
        <head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <style type="text/css">{style}</style>
            <title>platform</title>
        </head>
        <body
            id="forPrint"
            style={{
                fontFamily: "Calibri",
                margin: 0,
                width: "100%",
                maxWidth: isSilentPrint ? "100%" : "80mm",
                justifyContent: "center",
                display: "inline-block",
                height: "auto",
                wordBreak: "break-word",
                fontSize: fontSize
            }}>
        <table style={{
            width: isSilentPrint ? width : "auto",
            margin: 0
        }}>
            <tbody>
            {logo ?
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <img alt="logo" src={logo} width={isSilentPrint ? width - 100 : "50%"}
                             height="auto"/>
                    </td>
                </tr> : <></>
            }
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    JGSP "NOVI SAD"
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    AUTOBUSKA STANICA
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    ID:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {peronId}
                </td>
            </tr>
            {additional?.lineName &&
                <tr style={{justifyContent: "space-between"}}>
                    <td style={{fontSize: fontSize}} colSpan={2}>
                        Linija:
                    </td>
                    <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                        {additional?.lineName}
                    </td>
                </tr>
            }
            {additional?.carrier && <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Prevoznik:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {additional?.carrier}
                </td>
            </tr>}
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Operater:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {service.cashier}
                </td>
            </tr>
            {additional ? <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Datum i vreme putovanja:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {moment(additional?.journeyDate).format("DD.MM.YYYY HH:mm")}
                </td>
            </tr> : <></>}
            {additional ? <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Peron:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {additional.platformNumber}
                </td>
            </tr> : <></>}
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Izdato:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {moment(service.salesTime).format("DD.MM.YYYY HH:mm:ss")}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Naziv:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {service.name}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Cena:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {fCurrency(`${service.unitPrice}`)}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Količina:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {service.quantity}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Iznos bez PDV-a:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {fCurrency(`${service.baseAmount}`)}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Iznos PDV-a:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {fCurrency(`${service.pdv}`)}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Ukupno:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {fCurrency(`${service.totalAmount}`)}
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "center"}} colSpan={4}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {qrCode &&
                            <img
                                style={{
                                    minWidth: isSilentPrint ? 360 : "40mm",
                                    minHeight: isSilentPrint ? 360 : "40mm",
                                    maxWidth: isSilentPrint ? 360 : "50mm",
                                    maxHeight: isSilentPrint ? 360 : "50mm"
                                }}
                                width={isSilentPrint ? 360 : "100%"}
                                height={isSilentPrint ? 360 : "100%"}
                                alt="qr code"
                                src={qrCode}/>
                        }
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        </body>
        </html>
    );
};

