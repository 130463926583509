import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {child, get, push, remove, update} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {getClientInfo} from "../../api/vpfr";

export const updateCustomer = createAsyncThunk("updateCustomer", async (data) => {
    return await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${data.uid}`),
        {
            name: data.name,
            tin: data.tin,
            jmbg: data.jmbg,
            address: data.address,
            city: data.city,
            telephone: data.phoneNumber,
            email: data.email,
            contactPerson: data.contactPerson,
            taxCorePrefix: data.taxCorePrefix
        });
})

export const addCustomer = createAsyncThunk("addCustomer", async (data) => {
    return push(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`),
        {
            name: data.name,
            tin: data.tin,
            jmbg: data.jmbg,
            address: data.address,
            city: data.city,
            telephone: data.phoneNumber,
            email: data.email,
            contactPerson: data.contactPerson,
            taxCorePrefix: data.taxCorePrefix,
        });
})

export const fetchAllCustomers = createAsyncThunk("fetchAllCustomers", async () => {
    const arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers`))).forEach(child1 => {
        arr.push({
            ...child1.val(),
            uid: child1.key
        });
    });
    return arr;
});

export const getClientDetails = createAsyncThunk('getClientDetails', async (tin) => {
    const response = await getClientInfo(tin);
    return response.data;
})

export const removeCustomer = createAsyncThunk("removeCustomer", async (data) => {
    await remove(child(dbRef, `users/${AUTH.currentUser.uid}/private/customers/${data.uid}`));
    return data;
})

const initialState = {
    allCustomers: [],
    loading: false,
    error: undefined,
    isSuccessAdded: false,
    loadingInfo: false,
    clientDetails: undefined,
    clientDetailsError: undefined,
}

const customersSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        resetIsSuccessAdded: (state) => {
            state.isSuccessAdded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCustomers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllCustomers.fulfilled, (state, action) => {
                state.allCustomers = action.payload;
                state.loading = false;
            })
            .addCase(fetchAllCustomers.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCustomer.fulfilled, (state) => {
                state.loading = false;
                state.error = undefined;
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.error = action.payload;
                state.allCustomers = [];
                state.loading = false;
            })
            .addCase(addCustomer.pending, (state) => {
                state.loading = true;
                state.isSuccessAdded = false;
            })
            .addCase(addCustomer.fulfilled, (state, action) => {
                const arr = [...current(state.allCustomers)]
                arr.push(action.meta.arg)
                state.allCustomers = arr
                state.loading = false;
                state.isSuccessAdded = true;
            })
            .addCase(addCustomer.rejected, (state, action) => {
                state.error = action.payload;
                state.allCustomers = [];
                state.loading = false;
                state.isSuccessAdded = false;
            })
            .addCase(removeCustomer.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeCustomer.fulfilled, (state, {payload}) => {
                const arr = [...current(state.allCustomers)]
                const index = arr.findIndex(i => i.uid === payload.uid)
                if (index !== -1) {
                    arr.splice(index, 1)
                    state.allCustomers = arr
                }
                state.loading = false;
            })
            .addCase(removeCustomer.rejected, (state, action) => {
                state.error = action.payload;
                state.allCustomers = [];
                state.loading = false;
            })
            .addCase(getClientDetails.pending, (state) => {
                state.loadingInfo = true;
            })
            .addCase(getClientDetails.fulfilled, (state, {payload}) => {
                state.loadingInfo = false;
                state.clientDetailsError = undefined;
                state.clientDetails = payload;
            })
            .addCase(getClientDetails.rejected, (state, action) => {
                state.clientDetailsError = action.error;
                state.loadingInfo = false;
            });
    },
});

export default customersSlice.reducer;
