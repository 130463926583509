import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
//
import {child, get} from "firebase/database";
import {dbRef} from "../../auth/FirebaseContext";
import {OPERATORS_GROUP} from "../../constants";

// ----------------------------------------------------------------------

export const fetchAllPublicGroups = createAsyncThunk("groups/fetchAll", async () => {
    let arr = [];
    (await get(child(dbRef, "public/groups"))).forEach(child1 => {
        if (Object.values(OPERATORS_GROUP).includes(parseInt(child1.key))) {
            arr.push({
                ...child1.val(),
                uid: parseInt(child1.key)
            });
        }
    });
    return arr;
});

const initialState = {
    isLoading: false,
    error: null,
    groups: [],
};

const groupsPublicSlice = createSlice({
    name: 'groupsPublic',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllPublicGroups.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllPublicGroups.fulfilled]: (state, action) => {
            state.loading = false;
            state.groups = action.payload
        },
        [fetchAllPublicGroups.rejected]: (state) => {
            state.loading = false;
        },

    }
});

// Reducer
export const groupsPublicReducer = groupsPublicSlice.reducer




