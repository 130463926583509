import {useSettingsContext} from "../../../components/settings";
import {Button, Card, Container, Table, TableBody, TableContainer} from "@mui/material";
import {PATH_DASHBOARD} from "../../../routes/path";
import Iconify from "../../../components/Iconify";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {useEffect, useState} from "react";
import {TableHeadCustom, TablePaginationCustom, TableSkeleton, useTable} from "../../../components/table";
import Scrollbar from "../../../components/Scrollbar";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet-async";
import {useLocales} from "../../../locales";
import {dispatch, useSelector} from "../../../redux/store";
import {fetchAllActions, searchActions, specialLoading} from "../../../redux/slices/actions";
import {debounceSearch} from "../../../api/searchDebounce";
import {debounce} from "lodash";
import {searchActionsAPI} from "../../../api/actions";
import ActionsValueTableRow from "./ActionsValueTableRow";
import ActionsValueTableToolbar from "./ActionsValueTableToolbar";

// ----------------------------------------------------------------------

const TABLE_HEAD = translate => [
    {id: "salesActionTypeId", label: translate('name'), align: "left"},
    {id: "isAbsolute", label: translate("type"), align: "left", width: 160},
    {id: "amount", label: translate("amount"), align: "left", width: 160},
    {id: "vat", label: translate("vat"), align: "left", width: 80},
    {id: "trafficType", label: translate("trafficType"), align: "left", width: 160},
    {id: "dateFrom", label: translate("dateFrom"), align: "left", width: 160},
    {id: "dateTo", label: translate("dateTo"), align: "left", width: 160},
    {id: "status", label: translate("status"), align: "right", width: 50},
    {id: "", align: "right",}
];
export default function ActionsValue() {
    const {enqueueSnackbar} = useSnackbar();

    const {translate} = useLocales();

    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage
    } = useTable({
        defaultOrderBy: "createdAt"
    });

    const {themeStretch} = useSettingsContext();

    const navigate = useNavigate();

    const {
        loadingActions,
        foundActions
    } = useSelector(state => state.actions)

    const [filterName, setFilterName] = useState("");

    useEffect(() => {
        if (filterName) {
            dispatch(specialLoading(true))
            debounceSearchAction({
                name: filterName,
                page: page,
                rowsPerPage: rowsPerPage
            });
        } else {
            reload();
        }
    }, [page, rowsPerPage, filterName]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardPress);

        return () => {
            window.removeEventListener('keydown', handleKeyboardPress);
        };
    }, []);

    const handleKeyboardPress = (e) => {
        if (e.key === 'Insert') {
            e.preventDefault();
            navigate(PATH_DASHBOARD.actions.actionValueNew)
        }
    }

    const searchAction = async (value) => {
        try {
            const response = await debounceSearch(searchActionsAPI, value);
            dispatch(searchActions(response))
        } catch (error) {
            enqueueSnackbar(translate("errorOnServer"), {variant: "error"})
        }
    };

    const debounceSearchAction = debounce(searchAction, 300);

    const isFiltered = filterName !== "";

    const handleEditRow = (data) => {
        navigate(PATH_DASHBOARD.actions.actionValueListEdit(data.id));
    };

    const handleFilterName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };


    const handleResetFilter = () => {
        setFilterName("");
    };

    const reload = () => {
        dispatch(fetchAllActions({
            page: page,
            rowsPerPage: rowsPerPage,
        }))
            .unwrap().catch(reason => {
            //logError("CarriersList", "getAllOperators", errorFetch)
            enqueueSnackbar(translate("errorFetching"), {variant: "error"});
        });
    }
    return (
        <>
            <Helmet>
                <title>BUS Soft - {translate('actionsValue')}</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : "xl"}>
                <CustomBreadcrumbs
                    heading={translate("actionsValueList")}
                    links={[
                        {name: translate('home'), href: PATH_DASHBOARD.general.app},
                        {name: translate('actionsValue'), href: PATH_DASHBOARD.actions.actionValueList},
                        {name: translate('list')}
                    ]}
                    action={
                        <Button
                            to={PATH_DASHBOARD.actions.actionValueNew}
                            component={RouterLink}
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill"/>}
                        >
                            {translate("add")} [Insert]
                        </Button>
                    }
                />

                <Card>
                    <ActionsValueTableToolbar
                        isFiltered={isFiltered}
                        filterName={filterName}
                        onFilterName={handleFilterName}
                        onResetFilter={handleResetFilter}
                    />

                    <TableContainer sx={{position: "relative", overflow: "unset"}}>
                        <Scrollbar>
                            <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD(translate)}
                                    rowCount={foundActions?.content?.length}
                                    onSort={onSort}
                                />

                                <TableBody>
                                    {loadingActions ?
                                        Array.from({length: rowsPerPage}, (_, i) => (
                                            <TableSkeleton key={i} columns={8}/>
                                        ))
                                        :
                                        foundActions?.content?.map((row, index) =>
                                            <ActionsValueTableRow
                                                key={index}
                                                reload={reload}
                                                row={row}
                                                onEditRow={() => handleEditRow(row)}
                                            />)
                                    }
                                </TableBody>

                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        loading={loadingActions}
                        count={foundActions?.totalElements || 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>
        </>
    );
}

