import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllRateAPI, getRateByIdAPI, insertRateAPI, updateRateAPI} from "../../api/rate";

export const fetchAllRates = createAsyncThunk('fetchAllRates', async (payload) => {
    return await getAllRateAPI(payload)
})

export const findAllRatesByRateCardId = createAsyncThunk('fetchAllRatesByRateCard', async (id) => {
    return await getRateByIdAPI(id)
})

export const postRate = createAsyncThunk('postRate', async (payload) => {
    return insertRateAPI(payload);
})

export const updateRate = createAsyncThunk('updateRate', async (payload) => {
    return updateRateAPI(payload);
})

const initialState = {
    rates: [],
    foundRates: [],
    loadingRates: false,
    searchRate: null
}

export const ratesSlice = createSlice({
    name: 'rates',
    initialState,
    reducers: {
        emptyAllRates: (state) => {
            state.rates = []
        },
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingRates = payload
            }
        },
        searchRateSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundRates = payload;
                state.loadingRates = false
            }
        }
    },
    extraReducers: {
        [fetchAllRates.pending]: (state) => {
            state.loadingRates = true;
        },
        [fetchAllRates.fulfilled]: (state, {payload}) => {
            if (payload?.data) {
                state.foundRates = payload.data;
                state.rates = payload.data;
            }
            state.loadingRates = false;
        },
        [fetchAllRates.rejected]: (state) => {
            state.loadingRates = false;
        },
        [findAllRatesByRateCardId.pending]: (state) => {
            state.loadingRates = true;
        },
        [findAllRatesByRateCardId.fulfilled]: (state, {payload}) => {
            state.loadingRates = false;
            state.rates = payload.data.content;
        },
        [findAllRatesByRateCardId.rejected]: (state, {payload}) => {
            state.loadingRates = false;
            state.rates = payload.data.content;
        },
        [postRate.pending]: (state) => {
            state.loadingRates = true;
        },
        [postRate.fulfilled]: (state) => {
            state.loadingRates = false;
        },
        [postRate.rejected]: (state) => {
            state.loadingRates = false;
        },
        [updateRate.pending]: (state) => {
            state.loadingRates = true;
        },
        [updateRate.fulfilled]: (state) => {
            state.loadingRates = false;
        },
        [updateRate.rejected]: (state) => {
            state.loadingRates = false;
        }
    }
});

export const ratesReducer = ratesSlice.reducer
export const {emptyAllRates, searchRateSlice, specialLoading} = ratesSlice.actions
