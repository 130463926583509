import PropTypes from "prop-types";
// @mui
import {useTheme} from "@mui/material/styles";
import {AppBar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
// utils
import {bgBlur} from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import {HEADER, NAV} from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/Iconify";
import AccountPopover from "./AccountPopover";
import useActiveLink from "../../../hooks/useActiveLink";
import {PATH_DASHBOARD} from "../../../routes/path";
//
import NotificationsPopover from "./NotificationsPopover";
import {keyframes} from "@mui/system";
import {useSelector} from "../../../redux/store";
import UserInfo from "./UserInfo";
import {useLocation} from "react-router-dom";

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func
};

const blink = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
`;

export default function Header({onOpenNav}) {
    const theme = useTheme();
    const {pathname} = useLocation();
    const {isTrainingModActive} = useSelector(state => state.settings);

    let isDesktop = useResponsive("up", "lg");
    let isHeader = useResponsive("up", "md");

    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP);
    const {active} = useActiveLink(PATH_DASHBOARD.general.app);
    const showMenuIcon = pathname === PATH_DASHBOARD.otpravnik.list || active

    if (active || showMenuIcon) {
        isDesktop = false;
    }

    const renderContent = (<>
        {isDesktop && <Logo sx={{mr: 2.5}}/>}

        {!isDesktop && (
            <IconButton onClick={onOpenNav} sx={{mr: 1, color: "text.primary"}}>
                <Iconify icon="eva:menu-2-fill"/>
            </IconButton>)}
        {isTrainingModActive && <Typography sx={{
            position: "fixed",
            top: 70,
            display: "flex",
            width: "100%",
            justifyContent: "center",

            zIndex: 9999,
            background: "rgba(255, 255, 255, 0.1)",
            fontSize: "1.5rem",
            color: "#ff5d52",
            textAlign: "center",
            animation: `${blink} 1.5s linear infinite;`
        }}>OBUKA MOD JE AKTIVAN</Typography>}
        <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end"
               spacing={{xs: 0.5, sm: 1.5}}>

            {isHeader && <UserInfo/>}

            <NotificationsPopover/>

            <AccountPopover/>
        </Stack>
    </>);

    return (<AppBar
        sx={{
            boxShadow: "none", height: HEADER.H_MOBILE, zIndex: theme.zIndex.appBar + 1, ...bgBlur({
                color: theme.palette.background.default
            }), transition: theme.transitions.create(["height"], {
                duration: theme.transitions.duration.shorter
            }), ...(isDesktop && {
                width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                height: HEADER.H_DASHBOARD_DESKTOP, ...(isOffset && {
                    height: HEADER.H_DASHBOARD_DESKTOP_OFFSET
                }),
            })
        }}
    >
        <Toolbar
            sx={{
                height: 1, px: {lg: 5}
            }}
        >
            {renderContent}
        </Toolbar>
    </AppBar>);
}
