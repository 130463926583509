import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    addByLocationTaxItemsFromInvoiceToArray,
    addCarriersAmountFromInvoiceToArray,
    addCashiersAmountFromInvoiceToArray,
    addItemFromInvoiceToArray,
    addItemFromInvoiceToArrayByTax,
    addPaymentMethodFromInvoiceToArray, addRelations,
    addTaxItemsFromInvoiceToArray,
    concatItemsForTax
} from "../../helper/reports";
import {REPORTS_ROLES, TRANSACTION_TYPE} from "../../constants";
import {addRefundTaxItemsFromInvoiceToArray} from "../../helper/firestore";
import {getSessionOperator} from "../../helper/session";
import {convertItemName} from "../../helper/other";

export const fetchReportOld = createAsyncThunk('fetchReportOld', async (data) => {

    let sessionGroup = getSessionOperator().group
    let sessionName = convertItemName(getSessionOperator().username);
    let allCashiers = [];
    let allCarriers = [];
    let allItems = [];
    let refundItems = [];
    let amountByTax = [];
    let paymentMethods = [];
    let taxItems = [];
    let taxRefundItems = [];
    let locationsSummary = [];
    let refundedSum = 0;
    let normalSum = 0;
    let numberOfSaleInvoices = 0;
    let numberOfRefoundInvoices = 0;
    let relations = [];

    for (let invoice of data.allInvoice) {
        if (REPORTS_ROLES.includes(sessionGroup) || invoice.cashier === sessionName) {
            if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                normalSum = normalSum + parseFloat(invoice.totalAmount);
                numberOfSaleInvoices = numberOfSaleInvoices + 1;
                await addCarriersAmountFromInvoiceToArray(allCarriers, invoice, false);
                await addRelations(relations, invoice, false);
                addItemFromInvoiceToArray(allItems, invoice, false, data.currentTaxRates);
                addTaxItemsFromInvoiceToArray(taxItems, invoice, false);
                addByLocationTaxItemsFromInvoiceToArray(locationsSummary, invoice, false, data.locations);
                addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, false);
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, false)

            } else if (invoice.transactionType === TRANSACTION_TYPE.refund) {
                refundedSum = refundedSum + parseFloat(invoice.totalAmount);
                numberOfRefoundInvoices = numberOfRefoundInvoices + 1;
                await addCarriersAmountFromInvoiceToArray(allCarriers, invoice, true)
                // await addRelations(relations, invoice, true);
                addItemFromInvoiceToArray(refundItems, invoice, true, data.currentTaxRates)
                addTaxItemsFromInvoiceToArray(taxItems, invoice, true)
                addByLocationTaxItemsFromInvoiceToArray(locationsSummary, invoice, true, data.locations);
                addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, true);
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, true)
                addRefundTaxItemsFromInvoiceToArray(taxRefundItems, invoice, true)
            }
        }
    }


    addItemFromInvoiceToArrayByTax(amountByTax, allItems, data.currentTaxRates)
    taxItems = concatItemsForTax(taxItems, amountByTax);
    taxRefundItems = concatItemsForTax(taxRefundItems, amountByTax);
    return {
        items: allItems,
        refundItems: refundItems,
        taxRefund: taxRefundItems,
        tax: taxItems,
        methods: paymentMethods,
        cashiers: allCashiers,
        locationsSummary: locationsSummary,
        carriers: allCarriers || [],
        totalSum: normalSum - refundedSum,
        totalRefund: refundedSum,
        numberOfInvoicesSale: numberOfSaleInvoices,
        numberOfInvoicesRefund: numberOfRefoundInvoices,
        relations: relations
    }
})

const initialState = {
    filterReport: [],
    overviewReportOld: {
        items: [],
        carriers: [],
        refundItems: [],
        tax: [],
        methods: [],
        cashiers: [],
        taxRefund: [],
        totalSum: 0,
        totalRefund: 0,
        numberOfInvoicesSale: 0,
        numberOfInvoicesRefund: 0,
        relations: [],
    },
    loadingOld: false
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        clearReport: (state) => {
            state.loadingOld = false
            state.overviewReportOld = {
                items: [],
                carriers: [],
                refundItems: [],
                tax: [],
                methods: [],
                cashiers: [],
                taxRefund: [],
                totalSum: 0,
                totalRefund: 0,
                numberOfInvoicesSale: 0,
                numberOfInvoicesRefund: 0,
            }
        }
    },
    extraReducers: {
        [fetchReportOld.pending]: (state) => {
            state.loadingOld = true;
        },
        [fetchReportOld.fulfilled]: (state, {payload}) => {
            state.overviewReportOld = payload;
            state.loadingOld = false;
        },
        [fetchReportOld.rejected]: (state) => {
            state.loadingOld = false;
        }
    }
});

export const {clearReport} = reportsSlice.actions
export const reportsReducer = reportsSlice.reducer
