import PropTypes from "prop-types";
// form
import {Controller, useFormContext} from "react-hook-form";
// @mui
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from "@mui/material";

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node
};

export function RHFCheckbox({ name, helperText, onChange, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => {
                                        field.onChange(e);  // Pozivaj onChange iz react-hook-form
                                        if (onChange) {
                                            onChange(e);  // Pozivaj i tvoj onChange handler
                                        }
                                    }}
                                />
                            }
                            {...other}
                        />
                        {(!!error || helperText) && (
                            <FormHelperText error={!!error}>
                                {error ? error?.message : helperText}
                            </FormHelperText>
                        )}
                    </div>
                );
            }}
        />
    );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
    row: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    spacing: PropTypes.number,
    helperText: PropTypes.node
};

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, ...other }) {
    const { control } = useFormContext();

    const getSelected = (selectedItems, item) =>
        selectedItems.includes(item)
            ? selectedItems.filter((value) => value !== item)
            : [...selectedItems, item];

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (

                <FormControl component="fieldset">
                    {label && (
                        <FormLabel component="legend" sx={{ typography: "body2" }}>
                            {label}
                        </FormLabel>
                    )}

                    <FormGroup
                        sx={{
                            ...(row && {
                                flexDirection: "row"
                            }),
                            "& .MuiFormControlLabel-root": {
                                "&:not(:last-of-type)": {
                                    mb: spacing || 0
                                },
                                ...(row && {
                                    mr: 0,
                                    "&:not(:last-of-type)": {
                                        mr: spacing || 2
                                    }
                                })
                            }
                        }}
                    >
                        <Box gap={2}
                             display="grid"
                             gridTemplateColumns={{
                                 xs: "repeat(2, 1fr)",
                                 sm: "repeat(3, 1fr)",
                                 md: "repeat(4, 1fr)"
                             }}>
                            {options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={field.value?.includes(option.value)}
                                            onChange={() => field.onChange(getSelected(field.value, option.value))}
                                        />
                                    }
                                    label={option.label}
                                    {...other}
                                />
                            ))}
                        </Box>
                    </FormGroup>

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error} sx={{ mx: 0 }}>
                            {error ? error?.message : helperText}
                        </FormHelperText>
                    )}
                </FormControl>

            )}
        />
    );
}
