import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {collection, getDocs, query, where} from "firebase/firestore";
import moment from "moment/moment";
import {AUTH, DB} from "../../auth/FirebaseContext";
import {parseInvoice} from "../../helper";
import {storeErrorToOffline} from "../../store/offlineDb";

export const fetchAllInvoicesByInternalData = createAsyncThunk('fetchAllInvoicesByInternalData', async (prop, {rejectWithValue}) => {

    const startDate = moment(prop.startDate).format("YYYY-MM-DD")
    const endDate = moment(prop.endDate).format("YYYY-MM-DD")
    try {
        const arr = [];

        const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
            where("sdcDateTime", ">=", startDate),
            where("sdcDateTime", "<=", endDate))
        const querySnapshot = await getDocs(q);
        await querySnapshot.forEach(doc => {
            let parsedInvoice = {
                sdcDateTime: doc.data().sdcDateTime
            };

            if (doc.data().paymentMethod !== undefined && (doc.data().invoiceType === "0" || doc.data().invoiceType === "1")) {
                parsedInvoice = parseInvoice(doc.data(), false, doc.id);
                arr.push(parsedInvoice)
            }
        });

        return arr;

    } catch (e) {
        // Use rejectWithValue to include the error in the payload
        storeErrorToOffline("fetchAllFactures", "factureredux.js", [], e?.toString())
        return rejectWithValue(e);
    }
});


const initialState = {
    invoicesList: [],
    loading: false,

}

export const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllInvoicesByInternalData.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllInvoicesByInternalData.fulfilled]: (state, action) => {
            state.loading = false;
            state.operatorsList = action.payload
        },
        [fetchAllInvoicesByInternalData.rejected]: (state) => {
            state.loading = false;
        },

    }
});

export const invoicesReducer = invoicesSlice.reducer

