import PropTypes from "prop-types";
import {createContext, useCallback, useEffect, useReducer} from "react";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getDatabase, ref} from "firebase/database";
import {getStorage, ref as sRef} from "firebase/storage";

import {FIREBASE_API} from "../config";
import {clearSession, setIniLoggedUser} from "../helper/session";
import {changeUser, me} from "../api/auth";
import {resetIsFetchedUserSettings} from "../redux/slices/userSettings";
import {dispatch as appDispatch} from "../redux/store"

// ----------------------------------------------------------------------

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
    isPrimaryUser: true,
};

const ACTION_TYPES = {
    INITIAL: "INITIAL",
};

const reducer = (state, action) => {
    if (action.type === ACTION_TYPES.INITIAL) {
        return {
            isInitialized: true,
            operator: undefined,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }

    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);
export const DB = getFirestore(firebaseApp);
export const RDB = getDatabase(firebaseApp);
export const dbRef = ref(RDB);
export const storageRef = getStorage(firebaseApp);
export const usersImageStorage = sRef(storageRef, "public/userphotos");
export const cityStorage = sRef(storageRef, "");

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(() => {
        try {
            onAuthStateChanged(AUTH, async (user) => {
                if (user) {
                    const userData = (await me()).data;

                    dispatch({
                        type: ACTION_TYPES.INITIAL,
                        payload: {
                            isAuthenticated: true,
                            user: {
                                ...user,
                                ...userData,
                                // ...profile,
                                // role: "admin",
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: ACTION_TYPES.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        initialize();
        return () => initialize();
        // eslint-disable-next-line
    }, []);

    // LOGIN
    const login = async (email, password) => {
        const userCredential = await signInWithEmailAndPassword(AUTH, email, password)
        const userData = (await me()).data;
        setIniLoggedUser({
            ...userCredential.user,
            ...userData
        })
    };

    // LOGOUT
    const logout = async () => {
        await signOut(AUTH);
        clearSession(true);
        dispatch({
            type: ACTION_TYPES.INITIAL,
            payload: {
                isAuthenticated: false,
                user: null,
            },
        });
    };

    const changeOperator = async (firebaseUid) => {
        const token = (await changeUser({
            userUid: firebaseUid
        })).data
        await signInWithCustomToken(AUTH, token)
        appDispatch(resetIsFetchedUserSettings());
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "firebase",
                login,
                logout,
                changeOperator
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
