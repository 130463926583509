import {customAxiosBE} from "../config";
import {getSessionSelectedLocation} from "../../helper/session";
import {AUTH} from "../../auth/FirebaseContext";

export async function issueInvoiceApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    const headers = {
        'client_os': 'web',
            'client_app': 'busSoft-new',
            'client_version': '1',
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "PAC": pac
    }
    return customAxiosBE.post(`/invoice`, data, {
        headers: headers
    })
}

export async function issueInvoiceVerificationApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    const headers = {
        'client_os': 'web',
        'client_app': 'busSoft-new',
        'client_version': '1',
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "PAC": pac
    }
    return customAxiosBE.post(`/invoice/issueVerify`, data, {
        headers: headers
    })
}

export async function issueFlixInvoiceApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    const headers = {
        'client_os': 'web',
        'client_app': 'busSoft-new',
        'client_version': '1',
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "PAC": pac
    }
    return customAxiosBE.post(`/invoice/flix`, data, {
        headers: headers
    })
}

export async function issueReservationApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    const headers = {
        'client_os': 'web',
            'client_app': 'busSoft-new',
            'client_version': '1',
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "PAC": pac
    }
    return customAxiosBE.post(`/invoice/issueReservation`, data, {
        headers: headers
    })
}

export async function refundInvoiceApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    const headers = {
        'client_os': 'web',
        'client_app': 'busSoft-new',
        'client_version': '1',
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "PAC": pac
    }
    return customAxiosBE.post(`/invoice/refund`, data, {
        headers: headers
    })
}
