import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider
} from "@mui/material";
import moment from 'moment';

export default function ItinererDialog({data, open, onClose}) {

    const calculateTime = (baseTime, addTime, divTime) => {
        const baseMoment = moment(baseTime, 'HH:mm:ss');
        const addMoment = moment(addTime, 'HH:mm:ss');

        let calculatedTime = baseMoment
            .clone()
            .add(addMoment.hours(), 'hours')
            .add(addMoment.minutes(), 'minutes')
            .add(addMoment.seconds(), 'seconds');

        if (divTime) {
            const divMoment = moment(divTime, 'HH:mm:ss');
            calculatedTime = calculatedTime
                .subtract(divMoment.hours(), 'hours')
                .subtract(divMoment.minutes(), 'minutes')
                .subtract(divMoment.seconds(), 'seconds');
        }

        return calculatedTime.format('HH:mm:ss');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <List>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Typography variant="h6" sx={{width: 300}}>
                                            {item.station.name}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" color="textSecondary">
                                            Dolazak: {calculateTime(item.tripDepartureTime, item.arrivalTime, null)}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" color="textSecondary">
                                            Polazak: {calculateTime(item.tripDepartureTime, item.departureTime, null)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            {index < data.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    );
}
