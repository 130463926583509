import moment from "moment";

export function isWithinTwoHours(providedTime) {
    const now = moment();

    const time = moment(providedTime);

    const differenceInHours = now.diff(time, 'hours', true);

    return Math.abs(differenceInHours) < 2;
}
