import {Skeleton, Stack, Tooltip, Typography} from "@mui/material";
import Iconify from "../../components/Iconify";
import {nameSlice} from "../../helper/other";

const canPreview = [0, 2];

export default function Comments({routeComment, comments, allowedViewers = [], loading}) {

    if (loading) {
        return <Skeleton variant="text"/>
    }

    return (
        <>
            {comments.filter(value => canPreview.includes(value.allowedViewers)).map(value =>
                <Tooltip title={value.comment}>
                    <Stack key={value.id} direction="row" spacing={1} justifyContent="center" alignItems={"center"}
                           width={1}>
                        <Typography variant="subtitle2" color="orangered">
                            {nameSlice(value.comment, 20)}
                        </Typography>
                        <Iconify icon={'ph:info-duotone'} width={16}/>
                    </Stack>
                </Tooltip>
            )}
            {routeComment ?
                <Tooltip title={routeComment}>
                    <Stack key={routeComment} direction="row" spacing={1} justifyContent="center" alignItems={"center"}
                           width={1}>
                        <Typography variant="subtitle2" color="orangered">
                            {nameSlice(routeComment, 20)}
                        </Typography>
                        <Iconify icon={'ph:info-duotone'} width={16}/>
                    </Stack>
                </Tooltip>
                :
                <></>
            }
        </>
    )
}
