import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {
    deleteCountryAPI,
    getAllCountriesAPI,
    getCountryAPI,
    insertCountryAPI,
    updateCountryAPI
} from "../../api/country";

export const fetchAllCountries = createAsyncThunk('fetchAllCountries', async (data) => {
    return await getAllCountriesAPI(data)
})

export const deleteCountryById = createAsyncThunk('deleteCountryById', async (payload) => {
    return deleteCountryAPI(payload);
})

export const postCountry = createAsyncThunk('postCountry', async (payload) => {
    return insertCountryAPI(payload);
})

export const updateCountry = createAsyncThunk('updateCountry', async (payload) => {
    return updateCountryAPI(payload);
})

export const getCountryById = createAsyncThunk('getCountryById', async (payload) => {
    return getCountryAPI(payload);
})

const initialState = {
    allCountries: [],
    country: null,
    loadingCountries: false,
}

const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCountries.pending, (state) => {
                state.loadingCountries = true;
            })
            .addCase(fetchAllCountries.fulfilled, (state, {payload}) => {
                state.allCountries = payload.data.content;
                state.loadingCountries = false;
            })
            .addCase(fetchAllCountries.rejected, (state) => {
                state.loadingCountries = false;
            })
            .addCase(postCountry.pending, (state) => {
                state.loadingCountries = true;
            })
            .addCase(postCountry.fulfilled, (state, {payload}) => {
                const arr = [...current(state.allCountries)];
                arr.push(payload.data);
                state.allCountries = arr;
                state.loadingCountries = false;
            })
            .addCase(postCountry.rejected, (state) => {
                state.loadingCountries = false;
            })
            .addCase(deleteCountryById.pending, (state) => {
                state.loadingCountries = true;
            })
            .addCase(deleteCountryById.fulfilled, (state, action) => {
                const arr = [...current(state.allCountries)];
                const index = arr.findIndex((i) => i.id === action.meta.arg);
                if (index !== -1) {
                    arr.splice(index, 1);
                    state.allCountries = arr;
                }
                state.loadingCountries = false;
            })
            .addCase(deleteCountryById.rejected, (state) => {
                state.loadingCountries = false;
            })
            .addCase(getCountryById.pending, (state) => {
                state.loadingCountries = true;
            })
            .addCase(getCountryById.fulfilled, (state, {payload}) => {
                state.loadingCountries = false;
                state.country = payload.data.content;
            })
            .addCase(getCountryById.rejected, (state) => {
                state.loadingCountries = false;
            });
    },
});

export default countriesSlice.reducer;
