import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
// @mui
import {styled, alpha} from '@mui/material/styles';
import Iconify from "../Iconify";
//


// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({theme}) => ({
    width: 64,
    height: 64,
    fontSize: 24,
    display: 'flex',
    flexShrink: 0,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    borderRadius: theme.shape.borderRadius,
    border: `dashed 1px ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.grey[500], 0.08),
    '&:hover': {
        opacity: 0.72,
    },
}));

// ----------------------------------------------------------------------

UploadBox.propTypes = {
    sx: PropTypes.object,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.node,
};

function fileSizeValidator(file) {
    if (file.size > 3145728) {
        return {
            code: "file-too-large",
            message: `Name is larger than 25mb`
        };
    }

    return null
}

export default function UploadBox({placeholder, error, disabled, sx, ...other}) {
    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        validator: fileSizeValidator,
        accept: {
            'application/pdf': []
        },
        maxFiles: 3,
        disabled,
        ...other,
    });

    const isError = isDragReject || !!error;

    return (
        <StyledDropZone
            {...getRootProps()}
            sx={{
                ...(isDragActive && {
                    opacity: 0.72,
                }),
                ...(isError && {
                    color: 'error.main',
                    bgcolor: 'error.lighter',
                    borderColor: 'error.light',
                }),
                ...(disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                }),
                ...sx,
            }}
        >
            <input {...getInputProps()} />

            {placeholder || <Iconify icon="eva:cloud-upload-fill" width={28}/>}
        </StyledDropZone>
    );
}
