import PropTypes from "prop-types";
import LinesListTableToolbar from "../Lines/LinesListTableToolbar";
import {Button, InputAdornment, Stack, TextField} from "@mui/material";
import Iconify from "../../components/Iconify";
import {useLocales} from "../../locales";

LinesListTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
    onResetFilter: PropTypes.func,
};

export default function CarriersToolbar(
    {
        filterName,
        onFilterName,
        onResetFilter,
        isFiltered
    }
) {

    const {translate} = useLocales();

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}>

            <TextField
                size={"small"}
                fullWidth
                autoFocus
                value={filterName}
                onChange={onFilterName}
                placeholder={translate("name")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    {translate("clear")}
                </Button>
            )}
        </Stack>
    )
}
