import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {
    deleteStationAPI,
    getAllArrivalStationsByDepStationId, getAllDefaultDepStationsApi,
    getAllStationsApi,
    insertStationAPI,
    searchStationAPI,
    updateStationAPI
} from "../../api/station";

export const fetchHomeStations = createAsyncThunk('fetchHomeStations', async (payload) => {
    return await getAllDefaultDepStationsApi(payload)
})
export const fetchHomeArrStations = createAsyncThunk('fetchHomeArrStations', async (payload) => {
    return await getAllArrivalStationsByDepStationId(payload)
})

export const searchStation = createAsyncThunk('searchStation', async (payload) => {
    return await searchStationAPI(payload)
})

export const fetchStations =
    createAsyncThunk('fetchStations', async (data) => {
        return getAllStationsApi(data);
    })

export const insertStation = createAsyncThunk('insertStation', async (payload) => {
    return insertStationAPI(payload);
})

export const updateStation = createAsyncThunk('updateStation', async (payload) => {
    return updateStationAPI(payload);
})

export const deleteStation = createAsyncThunk('deleteStation', async (id) => {
    return deleteStationAPI(id);
})

const initialState = {
    query: '',
    homeStations: [],
    homeArrStations: [],
    stationDepartures: [],
    idOfStartStation: null,
    stations: [],
    loadingStations: false,
    loadingArrStations: false,
    searchParam: ""
}

export const stationSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload;
        },
    },
    extraReducers: {
        [fetchHomeStations.pending]: (state) => {
            state.loadingArrStations = true;
        },
        [fetchHomeStations.fulfilled]: (state, {payload}) => {
            state.homeStations = payload.data;
            state.loadingArrStations = false;
        },
        [fetchHomeStations.rejected]: (state) => {
            state.loadingArrStations = false;
        },
        [fetchHomeArrStations.pending]: (state) => {
            state.loadingStations = true;
        },
        [fetchHomeArrStations.fulfilled]: (state, {payload}) => {
            state.loadingStations = false;
            state.homeArrStations = payload.data;
        },
        [fetchHomeArrStations.rejected]: (state) => {
            state.loadingStations = false;
        },
        [searchStation.pending]: (state) => {
            state.loadingStations = true;
        },
        [searchStation.fulfilled]: (state, {payload}) => {
            state.loadingStations = false;
            state.stations = payload.data.content;
        },
        [searchStation.rejected]: (state) => {
            state.loadingStations = false;
        },
        [fetchStations.pending]: (state) => {
            state.loadingStations = true;
        },
        [fetchStations.fulfilled]: (state, {payload}) => {
            state.stations = payload.data;
            state.loadingStations = false;
        },
        [fetchStations.rejected]: (state) => {
            state.loadingStations = false;
        },
        [deleteStation.pending]: (state) => {
            state.loadingStations = true;
        },
        [deleteStation.fulfilled]: (state, action) => {
            let arr = [...current(state.stations)]
            let index = arr.findIndex(i => i.id === action.meta.arg)
            if (index !== -1) {
                arr.splice(index, 1)
                state.stations = arr
            }
            state.loadingStations = false;
        },
        [deleteStation.rejected]: (state) => {
            state.loadingStations = false;
        },
        [updateStation.pending]: (state) => {
            state.loadingStations = false;
        },
        [updateStation.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.stations)]
            const index = arr.findIndex(o => o.id === payload.data.id)
            if (index !== -1) {
                arr[index] = payload.data
                state.stations = arr
            }
            state.loadingStations = false;
        },
        [updateStation.rejected]: (state) => {
            state.loadingStations = false;
        },
        [insertStation.pending]: (state) => {
            state.loadingStations = false;
        },
        [insertStation.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.stations)];
            arr.push(payload.data)
            state.stations = arr
            state.loadingStations = false;
        },
        [insertStation.rejected]: (state) => {
            state.loadingStations = false;
        },
    }
});

export const stationsReducer = stationSlice.reducer
