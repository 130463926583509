import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deleteSalesActionTypeAPI,
    getAllSalesActionTypeAPI,
    insertSalesActionTypeAPI,
    updateSalesActionTypeAPI
} from "../../api/salesActionType";

export const fetchAllSalesActionType = createAsyncThunk('fetchAllSalesActionType', async (data) => {
    return await getAllSalesActionTypeAPI(data)
})

export const insertNewSalesActionType = createAsyncThunk('insertNewSalesActionType', async (data) => {
    return await insertSalesActionTypeAPI(data)
})

export const deleteSalesActionType = createAsyncThunk('deleteSalesActionTypeNomAPI', async (data) => {
    return await deleteSalesActionTypeAPI(data)
})

export const updateSalesActionType = createAsyncThunk('updateSalesActionType', async (data) => {
    return await updateSalesActionTypeAPI(data)
})

const initialState = {
    allSalesActionType: [],
    foundSalesActionType: [],
    loadingSalesActionType: false,
}

export const actionsSaleType = createSlice({
    name: 'salesActionType', initialState,

    reducers: {
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingSalesActionType = payload
            }
        },
        searchActionsSaleType: (state, {payload}) => {
            if (payload?.content) {
                state.foundSalesActionType = payload;
                state.loadingSalesActionType = false
            }
        }
    },
    extraReducers: {
        [fetchAllSalesActionType.pending]: (state) => {
            state.loadingSalesActionType = true;
        },
        [fetchAllSalesActionType.fulfilled]: (state, {payload}) => {
            state.allSalesActionType = payload.data;
            state.foundSalesActionType = payload.data;
            state.loadingSalesActionType = false;
        },
        [fetchAllSalesActionType.rejected]: (state) => {
            state.loadingSalesActionType = false;
        },
        [insertNewSalesActionType.pending]: (state) => {
            state.loadingSalesActionType = true;
        },
        [insertNewSalesActionType.fulfilled]: (state, {payload}) => {
            state.loadingSalesActionType = false;
        },
        [insertNewSalesActionType.rejected]: (state, action) => {
            state.loadingSalesActionType = false;
        },
        [deleteSalesActionType.pending]: (state) => {
            state.loadingSalesActionType = true;
        },
        [deleteSalesActionType.fulfilled]: (state, action) => {
            let arr = [...current(state.allSalesActionType)]
            let index = arr.findIndex(i => i.cardNumber === action.meta.arg.cardNumber)
            if (index !== -1) {
                arr.splice(index, 1)
                state.allSalesActionType = arr
            }
            state.loadingSalesActionType = false;
        },
        [deleteSalesActionType.rejected]: (state) => {
            state.loadingSalesActionType = false;
        }
    }
});
export const {searchActionsSaleType, specialLoading} = actionsSaleType.actions;

export default actionsSaleType.reducer

