import {customAxiosBE} from "../config";

export async function searchTicketsApi(payload, params) {
    return customAxiosBE.post(`/ticket/search?${params}`, payload)
}

export async function createReservationApi(payload) {
    return customAxiosBE.post(`/ticket/reservation`, payload)
}

export async function checkIfHaveOutbound(ticketId) {
    return customAxiosBE.get(`/ticket/checkIfHaveOutbound/${ticketId}`)
}

export async function issueCopyTicketAPI(ticketId) {
    return customAxiosBE.post(`/ticket/${ticketId}/copy`)
}

export async function getTicketsByInvoiceNumber(invoiceNumber) {
    return customAxiosBE.get(`/ticket/invoiceNumber/${invoiceNumber}`)
}

export async function updateReservationAPI(data) {
    return customAxiosBE.post(`/ticket/reservation/update`, data)
}

export async function cancelReservationAPI(ticketId) {
    return customAxiosBE.post(`/ticket/${ticketId}/reservation/cancel`)
}

export async function verifyTicket(data) {
    return customAxiosBE.post(`/ticket/verify`, data)
}
