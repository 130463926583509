import {getAllCurrentTaxRates} from "../../utils/other";
import {getClientDetails} from "../../redux/slices/customers";
import {dispatch} from "../../redux/store";

const taxValueForLabel = (item, currentTaxRates) => {
    let tax = 0;
    const allRates = getAllCurrentTaxRates(currentTaxRates);

    // Check if the item has a 'labels' array or 'vat' property
    const labelToCheck = item?.labels?.[0] || item?.vat;

    for (let i = 0; i < allRates.length; i++) {
        // Compare the 'label' property of the tax rate with the item's label or vat value
        if (allRates[i].label === labelToCheck) {
            tax = allRates[i].rate;
            break; // Exit the loop once the matching tax rate is found
        }
    }

    return tax;
};

export function calculateBasicOfItem(item, currentTaxRates) {
    let tax = taxValueForLabel(item, currentTaxRates)
    return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) * parseFloat(item.quantity)
}

export function calculateBasic(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        let tax = taxValueForLabel(items[i], currentTaxRates)
        sum += (parseFloat(items[i].unitPrice) / (tax * 0.01 + 1)) * parseFloat(items[i].quantity)
    }
    return sum;
}

export function calculatePdv(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        sum += parseFloat(items[i].unitPrice) * parseFloat(items[i].quantity) - calculateBasicOfItem(items[i], currentTaxRates)
    }
    return sum;
}

export const addPaymentMethodFromInvoiceToArray = (array, invoice, isRefound = false) => {
    try {
        invoice.paymentMethod.forEach(paymentMethod => {
            let indexOfMethod = array.findIndex((method) => method.paymentType === paymentMethod.paymentType)
            if (indexOfMethod === -1) {
                if (isRefound) {
                    array.push({paymentType: paymentMethod.paymentType, amount: parseFloat(-paymentMethod.amount)})
                } else {
                    array.push({paymentType: paymentMethod.paymentType, amount: parseFloat(paymentMethod.amount)})
                }
            } else {
                if (isRefound) {
                    array[indexOfMethod].amount = parseFloat(array[indexOfMethod].amount) - parseFloat(paymentMethod.amount)
                } else {
                    array[indexOfMethod].amount = parseFloat(array[indexOfMethod].amount) + parseFloat(paymentMethod.amount)
                }

            }
        })
    } catch (e) {
        console.error(e);
    }
}

export const addItemFromInvoiceToArrayByTax = (array, items, currentTaxRates) => {
    try {
        items.forEach(item => {
            let index = array.findIndex(taxItem => {
                return taxItem.label === item.labels[0]
            });
            let basic = calculateBasic([{
                unitPrice: item.totalAmount,
                quantity: 1,
                vat: item.labels[0]
            }], currentTaxRates)
            if (index === -1) {
                array.push({
                    label: item.labels[0],
                    totalAmount: parseFloat(item.totalAmount),
                    basic: basic
                })
            } else {
                array[index].totalAmount = parseFloat(array[index].totalAmount) + parseFloat(item.totalAmount)
                array[index].basic = parseFloat(array[index].basic) + parseFloat(basic)
            }
        })
    } catch (e) {
        console.error(e);
    }
}

export const addItemFromInvoiceToArray = (array, invoice, isRefound = false, currentTaxRates) => {
    try {
        invoice.items.forEach(item => {
            // let cashierFound = allCarriers.find((cashier) => cashier.pib === invoice?.internalData?.issuedFor)
            let indexOfItem = array.findIndex(localItem => localItem.name === item.name)
            let basic = calculateBasic([{
                unitPrice: item.totalAmount,
                quantity: 1,
                vat: item.labels[0]
            }], currentTaxRates)
            let tax = calculatePdv([{
                unitPrice: item.totalAmount,
                quantity: 1,
                vat: item.labels[0]
            }], currentTaxRates)
            if (indexOfItem === -1) {
                array.push({
                    pib: "",
                    name: item.name,
                    basic: isRefound ? -basic : basic,
                    tax: tax,
                    unitPrice: item.unitPrice,
                    totalAmount: parseFloat(item.totalAmount),
                    quantity: parseFloat(item.quantity),
                    labels: item.labels,
                    multiplePrices: [{
                        unitPrice: item.unitPrice,
                        quantity: parseFloat(item.quantity),
                        totalAmount: parseFloat(item.totalAmount),
                    }]
                })
            } else {
                array[indexOfItem].totalAmount = parseFloat(array[indexOfItem].totalAmount) + parseFloat(item.totalAmount)
                array[indexOfItem].quantity = parseFloat(array[indexOfItem].quantity) + parseFloat(item.quantity)
                if (isRefound) {
                    array[indexOfItem].basic = parseFloat(array[indexOfItem].basic) - parseFloat(basic)
                } else {
                    array[indexOfItem].basic = parseFloat(array[indexOfItem].basic) + parseFloat(basic)
                }
                array[indexOfItem].tax = parseFloat(array[indexOfItem].tax) + parseFloat(tax)
                let index = array[indexOfItem].multiplePrices.findIndex((multipleItem) => multipleItem.unitPrice === item.unitPrice)
                if (index === -1) {
                    let arr = [...array[indexOfItem].multiplePrices]
                    arr.push({
                        unitPrice: item.unitPrice,
                        quantity: parseFloat(item.quantity),
                        totalAmount: parseFloat(item.totalAmount),
                    });
                    array[indexOfItem].multiplePrices = arr;
                } else {
                    array[indexOfItem].multiplePrices[index].totalAmount =
                        parseFloat(array[indexOfItem].multiplePrices[index].totalAmount) +
                        parseFloat(item.totalAmount)
                    array[indexOfItem].multiplePrices[index].quantity =
                        parseFloat(array[indexOfItem].multiplePrices[index].quantity) +
                        parseFloat(item.quantity)
                }
            }
        })
    } catch
        (e) {
        console.error(e, 'greska za item')
    }
}

export const addCashiersAmountFromInvoiceToArray = (array, invoice, isRefund = false) => {
    try {
        let cashierIndex = array.findIndex((item) => item.name === invoice.cashier)
        if (cashierIndex === -1) {
            array.push({
                name: invoice.cashier,
                amount: isRefund ? 0 : parseFloat(invoice.totalAmount),
                refund: isRefund ? parseFloat(invoice.totalAmount) : 0
            })
        } else {
            if (isRefund) {
                array[cashierIndex].refund = parseFloat(array[cashierIndex].refund) + parseFloat(invoice.totalAmount)
            } else {
                array[cashierIndex].amount = parseFloat(array[cashierIndex].amount) + parseFloat(invoice.totalAmount)
            }
        }
    } catch (e) {
        console.error(e)
    }
}

export const  addRelations = async (array, invoice, isRefund = false) => {
        try {
            const relationIndex = array.findIndex((r) => r.selectedLine === invoice?.internalData?.selectedLine)
            if(relationIndex === -1){
                array.push({
                    selectedLine: invoice?.internalData?.selectedLine,
                    departureTripTime: invoice?.internalData?.departureTripTime,
                    multipleDepartureTripTime: [
                        {
                            departureTripTime: invoice?.internalData?.departureTripTime,
                            count: 1,
                            amount: Number(invoice.totalAmount)
                        }
                    ],
                    count: 1,
                    amount: Number(invoice.totalAmount)
                })
            } else {
                const index = array[relationIndex].multipleDepartureTripTime.findIndex(multipleDepartures =>
                    multipleDepartures.departureTripTime === invoice?.internalData?.departureTripTime);
                if(index === -1){
                    const arr = [...array[relationIndex].multipleDepartureTripTime]
                    arr.push({
                        departureTripTime: invoice?.internalData?.departureTripTime,
                        count: 1,
                        amount: Number(invoice.totalAmount)
                    })
                    array[relationIndex].multipleDepartureTripTime = arr;
                } else {
                    array[relationIndex].multipleDepartureTripTime[index].amount =
                        parseFloat(array[relationIndex].multipleDepartureTripTime[index].amount) +
                        parseFloat(invoice.totalAmount);
                    array[relationIndex].multipleDepartureTripTime[index].count =
                        parseFloat(array[relationIndex].multipleDepartureTripTime[index].count) + 1
                }
                array[relationIndex].count = array[relationIndex].count + 1;
                array[relationIndex].amount = array[relationIndex].amount + Number(invoice.totalAmount);
            }
        } catch (error){
            console.error(error)
        }
}

export const addCarriersAmountFromInvoiceToArray = async (array, invoice, isRefund = false) => {
    try {
        let count = 1
        let cashierIndex = array.findIndex((carriers) => carriers.pib === invoice?.internalData?.issuedFor)
        if (cashierIndex === -1) {
            if (invoice?.internalData?.issuedFor?.tin !== null && invoice?.internalData?.issuedFor?.tin !== undefined) {
                let name = ""
                await dispatch(getClientDetails(invoice.internalData.issuedFor.tin)).unwrap().then(response => {
                    name = response.name
                })

                array.push({
                    count: count,
                    name: name,
                    pib: invoice.internalData.issuedFor,
                    amount: isRefund ? 0 : parseFloat(invoice.totalAmount),
                    refund: isRefund ? parseFloat(invoice.totalAmount) : 0
                })
            }

        } else {
            if (isRefund) {
                array[cashierIndex].refund = parseFloat(array[cashierIndex].refund) + parseFloat(invoice.totalAmount)
                array[cashierIndex].count = count + 1
            } else {
                array[cashierIndex].amount = parseFloat(array[cashierIndex].amount) + parseFloat(invoice.totalAmount)
                array[cashierIndex].count = count + 1
            }
        }
    } catch (e) {
        console.error(e)
    }
}

export const concatItemsForTax = (taxItems, amountByTax) => {
    let arr = [];
    taxItems.forEach(taxItem => {
        let index = amountByTax.findIndex(aTax => aTax.label === taxItem.label)
        if (index !== -1) {
            arr.push({
                ...taxItem,
                basic: amountByTax[index].basic,
                totalAmount: amountByTax[index].totalAmount,
            })
        } else {
            arr.push({
                ...taxItem,
                basic: "/",
                totalAmount: "/",
            })
        }
    })
    return arr;
}

export const addTaxItemsFromInvoiceToArray = (array, invoice, isRefound = false) => {
    let taxes = invoice.taxItems
    let length = invoice.taxItems ? taxes.length : 0;
    for (let i = 0; i < length; i++) {
        let indexOfItem = array.findIndex(item => item.label === taxes[i].label)
        if (indexOfItem === -1) {
            array.push({
                amount: isRefound ? -taxes[i].amount : taxes[i].amount,
                categoryName: taxes[i].categoryName,
                categoryType: taxes[i].categoryType,
                label: taxes[i].label,
                rate: taxes[i].rate
            })
        } else {
            if (isRefound) {
                array[indexOfItem].amount = parseFloat(array[indexOfItem].amount - parseFloat(taxes[i].amount))
            } else {
                array[indexOfItem].amount = parseFloat(array[indexOfItem].amount + parseFloat(taxes[i].amount))
            }
        }
    }
}

export const addByLocationTaxItemsFromInvoiceToArray = (array, invoice, isRefund = false, locations) => {
    try {
        let locationIndex = array.findIndex((item) => invoice.invoiceNumber.startsWith(item.name))
        if (locationIndex === -1) {
            array.push({
                name: invoice.invoiceNumber.split('-')[0],
                location: locations.find(i => i.JID === invoice.invoiceNumber.split('-')[0])?.name,
                amount: isRefund ? 0 : parseFloat(invoice.totalAmount),
                refund: isRefund ? parseFloat(invoice.totalAmount) : 0
            })
        } else {
            if (isRefund) {
                array[locationIndex].refund = parseFloat(array[locationIndex].refund) + parseFloat(invoice.totalAmount)
            } else {
                array[locationIndex].amount = parseFloat(array[locationIndex].amount) + parseFloat(invoice.totalAmount)
            }
        }
    } catch (e) {
        console.error(e)
    }
}

