import {useEffect, useState} from "react";
import {IconButtonAnimate} from "../../../components/animate";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import Iconify from "../../../components/Iconify";
import MenuPopover from "../../../components/MenuPopover";
import Scrollbar from "../../../components/Scrollbar";
import {fToNow} from "../../../utils/formatTime";
import PropTypes from "prop-types";
import {
    fetchAllUserMessages,
    setAllNotificationAsRead,
    setNotificationAsRead
} from "../../../redux/slices/notifications";
import {dispatch} from "../../../redux/store";
import {useSelector} from "react-redux";
import TextMaxLine from "../../../components/text-max-line";
import {noCase} from "change-case";
import AllNotificationsDialog from "../../../components/allNotificationsDialog";
import {getSessionOperator} from "../../../helper/session";
import {MSG_VIEWERS_GROUP} from "../../../constants/other";

export default function NotificationsPopover() {
    const {messages} = useSelector((state => state.notifications))

    const [openDialog, setOpenDialog] = useState(false);
    const [openPopover, setOpenPopover] = useState(null);
    const [openMessage, setOpenMessage] = useState({open: false, data: null})

    const totalUnRead = messages.filter((item) => item.isRead !== true).length;
    const operator = getSessionOperator();

    useEffect(() => {
        dispatch(fetchAllUserMessages());
    }, []);

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleMarkAllAsRead = () => {
        const arr = []
        messages.forEach(msg => {
            arr.push({...msg, isRead: true})
        })
        dispatch(setAllNotificationAsRead(arr))
    };
    const open = Boolean(openPopover);


    const handleCloseMessage = () => {
        setOpenMessage({open: false, data: null})
    }

    const handleSeeAll = () => {
        handleClosePopover()
        setOpenDialog(true)
    }

    const handleCloseSeeAll = () => {
        setOpenDialog(false)
    }
    return (
        <>
            {operator.group === 13  &&

                <>
                    <IconButtonAnimate
                        color={openPopover ? 'primary' : 'default'}
                        onClick={handleOpenPopover}
                        sx={{width: 40, height: 40}}
                    >
                        <Badge badgeContent={totalUnRead} color="error">
                            <Iconify icon="eva:bell-fill"/>
                        </Badge>
                    </IconButtonAnimate>

                    <MenuPopover openPopover={openPopover} open={open} onClose={handleClosePopover} sx={{width: 360, p: 0}}>
                        <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
                            <Box sx={{flexGrow: 1}}>
                                <Typography variant="subtitle1">Notifikacije</Typography>

                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    Broj ne pročitanih prouka je {totalUnRead}
                                </Typography>
                            </Box>

                            {totalUnRead > 0 && (
                                <Tooltip title="Označi sve kao pročitano">
                                    <IconButton color="primary" onClick={handleMarkAllAsRead}>
                                        <Iconify icon="eva:done-all-fill"/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>

                        <Divider sx={{borderStyle: 'dashed'}}/>

                        <Scrollbar sx={{height: {xs: 340, sm: 'auto'}}}>
                            <List
                                disablePadding
                                subheader={
                                    <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                        Nove
                                    </ListSubheader>
                                }
                            >
                                {messages.slice(0, 2).map((notification) => notification.isRead !== true &&
                                    <NotificationItem dispatch={dispatch} closePopover={handleClosePopover}
                                                      openMessage={openMessage}
                                                      setOpenMessage={setOpenMessage} key={notification.uid}
                                                      notification={notification}/>)}
                            </List>

                            <List
                                disablePadding
                                subheader={
                                    <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                        Ranije
                                    </ListSubheader>
                                }
                            >
                                {messages.slice(0, 5).map((notification) => notification.isRead === true &&
                                    <NotificationItem dispatch={dispatch} closePopover={handleClosePopover}
                                                      openMessage={openMessage}
                                                      setOpenMessage={setOpenMessage} key={notification.uid}
                                                      notification={notification}/>)}
                            </List>
                        </Scrollbar>

                        <Divider sx={{borderStyle: 'dashed'}}/>

                        <Box sx={{p: 1}}>
                            <Button fullWidth disableRipple onClick={handleSeeAll}>
                                Vidi sve
                            </Button>
                        </Box>
                    </MenuPopover>
                </>
            }

            {openDialog && <AllNotificationsDialog open={openDialog} messages={messages} onClose={handleCloseSeeAll}/>}
            {openMessage.open && <NotificationDialog message={openMessage} onClose={handleCloseMessage}/>}
        </>
    )
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string,
        avatar: PropTypes.node,
        type: PropTypes.string,
        title: PropTypes.string,
        isUnRead: PropTypes.bool,
        description: PropTypes.string,
        createdAt: PropTypes.instanceOf(Date),
    }),
};

function NotificationItem({notification, setOpenMessage, closePopover, dispatch}) {
    const {avatar, title} = renderContent(notification);

    const handleOpen = () => {
        setOpenMessage({open: true, data: notification})
        dispatch(setNotificationAsRead(notification))
        closePopover()
    }

    return (
        <ListItemButton
            onClick={handleOpen}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(notification.isUnRead && {
                    bgcolor: 'action.selected',
                }),
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{bgcolor: 'background.neutral'}}>{avatar}</Avatar>
            </ListItemAvatar>

            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    <Stack direction="row" sx={{mt: 0.5, typography: 'caption', color: 'text.disabled'}}>
                        <Iconify icon="eva:clock-fill" width={16} sx={{mr: 0.5}}/>
                        <Typography variant="caption">{fToNow(notification.dateFrom)}</Typography>
                    </Stack>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {

    const title = (
        <Typography variant="subtitle2">
            {notification?.title.toString()}
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                &nbsp; {noCase(notification?.lineName.toString())}
            </Typography>
            <TextMaxLine variant={'body2'} line={2} persistent>
                {notification?.body.toString()}
            </TextMaxLine>
        </Typography>
    );

    if (notification?.type === undefined) {
        return {
            avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg"/>,
            title,
        };
    }
}

function NotificationDialog({ message, onClose, ...other }) {
    const { open, data: { title, body, lineName } } = message;

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ typography: 'body2' }}>
                {lineName && <div>{lineName}</div>}
                {body && <div>{body}</div>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    );
}
