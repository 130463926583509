const sr = {
    youCanOnlyEnterNumbers: "Samo brojevi",
    operatorModification: "Izmena operatera",
    appVersion:"Verzija aplikacije: {{number}}",
    departures: "Polasci",
    forbidPage: "Zabrani stranice",
    forbiddenPages: "Zabranjene stranice",
    connectingPlate: "Povezivanje: {{plate}}; {{time}}",
    passengerTypeIsRequired: "Tip putnika je obavezan!",
    emailForTicket: "Email za kartu/e",
    emailForTicketIsRequired: "Email za kartu/e je obavezan!",
    return: "Povratak",
    chooseReturnTrip: "Izaberite povratak",
    passengerNameIsRequired: "Ime putnika je obavezno",
    passengerLastNameIsRequired: "Prezime putnika je obavezno",
    realOperator: "Stvarni prevoznik",
    refundType: "Tip refundacije",
    refund: "Refundacija",
    entry: "Ulaz:",
    exit: "Izlaz:",
    recognizedVehicle: "<strong>{{type}}</strong> <strong>{{licensePlate}}</strong>.",
    autoConnectedText: "Za vozilo <strong>{{licensePlate}}</strong> je automatski zabeležen izlaz.",
    close: "Zatvori",
    testTicketText: "Ovo je testna karta",
    platformBusyness: "Zauzetost perona",
    systemCheck: "Provera sistema",
    vpfrCheck: "Provera VPFR-a",
    printerCheck: "Provera stampača",
    lastTicketCheck: "Štampanje poslednje karte",
    chooseOperator: "Izaberite prevoznika",
    fromRateCard: "Sa cenovnika",
    toRateCard: "Na cenovnik",
    printLogoOnInvoice: "Štampati logo na računu",
    printLogoOnTicket: "Štampati logo na karti",
    comments: "Komentar",
    documentsObj: {
        success: "Potpuna dokumentcija",
        warning: "Dokumentacija nepotpuna",
        error: "Nema dokumenta"
    },
    documentationStatus: "Status dokumentacije",
    invoiceSettings: "Podešavanje računa",
    adjustAppearanceInvoice: "Prilagodi izgled svog računa",
    advertisingText: "Reklamni tekst",
    printTestInvoice: "Štampaj uzorak računa",
    errorActionsFetching: "Greška prilikom učitavanja akcija",
    privilegesList: "Lista tipova povlastica",
    addingNewPlatform: "Dodavanje novog perona",
    newPlatform: "Novi peron",
    platformNumber: "Broj perona",
    number: "Broj",
    id: "ID",
    free: "Slobodno",
    reserved: "Rezervisano",
    writeMessage: "Napiši poruku",
    writeContentOfMessage: "Unesite sadržaj poruke...",
    forEmployers: "Zaposleni",
    atLeastOneTypeRequired: "Označit jedno ili više",
    forPassengers: "Putnici",
    returnDate: "Datum povratka",
    forOtpravnik: "Optravnici/objavljivači",
    forEveryone: "Za sve",
    platforms: "Peroni",
    platform: "Peron",
    noTripsOnDate: "Nema polaska za ovaj dan",
    forDate: "Za dan",
    moreDetails: "Više detalja",
    disabilityId: "Povlastica",
    choose: "Izaberite",
    enterNumber: "Unesite broj",
    operationalInterval: "Interval",
    editAllotment: "Izmena alotmana",
    carrierName: "Naziv prevoznika",
    carriers: "Prevoznici",
    home: "Početna",
    list: "Lista",
    createNew: "Napravi novog",
    type: "Tip",
    description: "Opis",
    insurance: "Osiguranje",
    carriersList: "Lista prevoznika",
    country: "Država",
    edit: "Izmeni",
    delete: "Obriši",
    success: "Uspešno",
    successFetching: "Uspešno očitani podaci",
    errorFetching: "Greška prilikom očitavanja podataka",
    errorChangingStatus: "Došlo je do greške prilikom promene statusa",
    lineList: "Lista linija",
    lines: "Linije",
    add: "Dodaj",
    preview: "Pregled",
    giveUp: "Odustani",
    cancel: "Poništi",
    youNeedToSelectBeforeEdit: "Niste onzačili red koji želite izmeniti",
    youNeedToSelectBeforePreview: "Niste onzačili red koji želite pregledati",
    rowsPerPage: "Redova po strani",
    isActiveDiscount: "Popust akitvan",
    theDateUntilWhichTheDepartureIsCancelled: "Datum do kad se otkazuje polazak",
    total: "Ukupno",
    held: "Održano",
    left: "Preostalo",
    shownRows: "Prikaz redova",
    ofTotal: "od ukupno",
    goBack: "Nazad",
    successAdded: "Uspešno dodato",
    maxDaysOfReturnDate: "Maksimalno dana za povratak",
    reducedSpacing: "Smanjen prored",
    errorFindingCarrier: "Greška, prevoznik nije pronađen",
    newCarrier: "Nov prevoznik",
    departuresArrivalsStatistic: "Statistika održanih polazaka/dolazaka",
    requiresReturnDate: "Ima datum povratka",
    errorOnServer: "Greška na serveru",
    duplicateVehicle: "Izabrali ste ista vozila",
    duplicatePlatform: "Izabrali ste iste perone",
    drivingDays: "Dani održavnja",
    optional: "Opciono",
    onlyNumbers: "Samo brojevi",
    isNotActive: "Neaktivno",
    departuresArrivals: "Polasci/Dolasci",
    fillOutTheTrafficLog: "Popuni saobraćajni denvnik",
    carrier: "Prevoznik",
    allotmentList: "Lista alotmana",
    allotments: "Alotmani",
    discountsList: "Lista popusta",
    occupied: "Zauzeto",
    oneWay: "Jedan smer",
    returnTicket: "Povratna",
    selected: "Izabrano",
    carrierDocumentation: "Dokumenti prevoznika",
    itinerary: "Itinerer",
    documents: "Dokumenti",
    submit: "Potvrdi",
    departure: "Polasci",
    permission: "Dozvola",
    contract: "Ugovor",
    discounts: "Popusti",
    discount: "Popust",
    name: "Naziv",
    status: "Status",
    addDiscount: "Dodavanje popusta",
    numberOfSeats: "Ukupno sedišta",
    numberOfSeat: "Broj sedišta",
    newDiscount: "Nov popust",
    onlyDigitNumbers: "Možete uneti samo brojeve",
    fieldIsRequired: "Obavezno polje",
    relations: "Relacije",
    enterName: "Unesite naziv",
    scheduleAndDep: "Red vožnje i polasci",
    departuresFor: "Polasci za",
    loadingText: "Učitavanje...",
    enterAtLeastOneCharacter: "Unesite bar jedan karakter...",
    vehicles: "Vozila",
    plateNumber: "Reg. oznaka",
    capacity: "Kapacitet",
    driveParkList: "Lista voznog parka",
    minNumberOfSeats: "Minimalni broj sedišta",
    clear: "Očisti",
    line: "Linija",
    dateFrom: "Datum od",
    dateTo: "Datum do",
    priceListName: "Naziv cenovnika",
    depFrom: "Polazak",
    departureEditTitle: "Izmena polaska",
    departureEdit: "Izmeni polazak",
    cooperator: "Kooperator",
    forLine: "Za liniju",
    startStation: "Početna stanica",
    endStation: "Krajnja stanica",
    numerated: "Numerisana",
    lineName: "Naziv linije",
    newAllotment: "Dodavanje alotmana",
    allotment: "Alotman",
    refresh: "Osveži",
    fromSeat: "Od broja sedišta",
    toSeat: "do sedišta",
    numberedSeats: "Numerisana sedišta",
    noNumberedSeats: "Nenumerisana sedišta",
    numberOfFreeSeats: "Broj slobodnih mesta",
    vehicleAlreadyExists: "Vozilo već postoji pod ovom registracionom oznakom",
    willBeSaved: "Upisuje se u saobraćajni dnevnik",
    reservations: "Rezervacije",
    reservationsPreview: "Pregled rezervacija",
    errorOnEdit: "Došlo je do greške prilikom izmene",
    maximumDays: "Maksimalan broj dana",
    save: "Sačuvaj",
    disableOnlineSale: "Isključi online prodaju",
    enterAllField: "Popunite sva polja sa *",
    editVehicle: "Izmena vozila",
    vehicle: "Vozilo",
    carrierNotFound: "Prevoznik nije pronađen",
    editDiscount: "Izmena popusta",
    addingVehicle: "Dodavanje vozila",
    privileges: "Povlastice",
    addingNewVehicle: "Dodavanje novog vozila",
    editPrivileges: "Izmena povlasitce",
    privilege: "Povlastica",
    expired: "Istekao rok važenja",
    issuePlace: "Mesto izdavanja",
    documentType: "Tip dokumenta",
    invalid: "Invalidnost",
    privilegePass: "Broj povlastice",
    issueDate: "Datum izdavanja",
    instantEditAllotment: "Instant alotmani",
    archive: "Arhivirani",
    date: "Datum",
    maxSeats: "Ukupno sedišta",
    instant: "Instant",
    durationTime: "Rok važenja",
    find: "Pronađi",
    depStation: "Polazna stanica",
    arrStation: "Krajnja stanica",
    priceTitle: "Cenovnik",
    priceList: "Lista cenovnika",
    licenceNumber: "Broj licence",
    holidays: "Praznici",
    addNewHolidaysTitle: "Dodavanje praznika",
    newHolidaysList: "Nov praznik",
    holidayName: "Naziv praznika",
    addNewPriceTitle: "Dodavanje novog cenovnika",
    newPriceList: "Nov cenovnik",
    addingStationsRate: "Dodavanje stanice po cenovnika",
    newStation: "Nova stanica",
    isAvailableOnline: "Dostupno za online prodaju",
    isActive: "Aktivan",
    bisVehicle: "Bis vozilo",
    level: "Stepen",
    disabilitiesList: "Lista",
    disability: "Invalidnost",
    newDisability: "Nova invalidnost",
    absoluteRelative: "Absolutna - relativna",
    amount: "Iznos",
    actionsList: "Lista akcija",
    newAction: "Nova akcija",
    addingNewAction: "Dodavanje nove akcije",
    absolute: "Apsolutna",
    relative: "Relativna",
    action: "Akcija",
    actions: "Akcije",
    editAction: "Izmena akcije",
    actionsValueList: "Lista vrednosti akcija",
    actionsValue: "Vrednosti akcija",
    vat: "PDV",
    trafficType: "Vrsta saobraćaja",
    addingNewValueAction: "Dodavanje vrednosti akcije",
    actionValueList: "Lista vrednosti akcija",
    newValueAction: "Nova vrednost akcije",
    pib: "PIB",
    jmbg: "Matični broj",
    email: "Mejl",
    webAddress: "Web adresa",
    phone: "Telefon",
    city: "Grad",
    address: "Adresa",
    bankAccount: "Žiro račun",
    salesContact: "Kontakt prodaje",
    printText: "Tekst za štampu",
    subPrivilege: "Pod privilegija",
    fillOutAllFields: "Popunite sva polja",
    createNewCarrier: "Dodavanje novog prevoznika",
    priceListEdit: "Lista cenovnika",
    stationByRateCard: "Stanice po cenovniku",
    rateCard: "Cenovnik",
    priceOneDirection: "Cena (jedan smer)",
    priceReturn: "Cena (povratak)",
    priceMonthly: "Cena (ceo mesec)",
    departureTime: "Polazak",
    addingNewLine: "Dodavanje nove linije",
    peronizationRate: "Cenovnik peronizacije",
    peronization: "Peronizacija",
    publicationText: "Tekst za objavljivanje",
    markIfActive: "Vozilo je aktivno",
    editActionValue: "Izmena vrednosti akcije",
    choosePrivilegeDiscount: "Izaberite tip privilegije",
    chooseDiscounts: "Izaberi popuste",
    newLine: "Nova linija",
    borderName: "Naziv graničnog prelaza",
    carrierPIB: "PIB prevoznika",
    findStation: "Pronađi stanicu",
    insuranceOffer: "Ponuda osiguranja na prodaji",
    active: "Aktivna",
    scheduleAndDepartures: "Red vožnje i polasci",
    departuresList: "Lista polazaka",
    exportCSV: "Izvezi CSV",
    chooseRateCard: "Izaberi cenovnik",
    addingNewDeparture: "Dodavanje novog polaska",
    newDeparture: "Nov polazak",
    surname: "Prezime",
    newVehicle: "Novo vozilo",
    onlyOneDiscountAllowed: "Samo jedan vid popusta je dozvoljen",
    copy: "Kopiraj",
    trip: "Polazak",
    all: "Svi",
    search: "Pretraži",
    user: "Korisnik",
    role: "Uloga",
    listOfOperators: "Lista operatera",
    operators: "operateri",
    inactive: "Pasivni",
    newOperator: "Dodaj operatera",
    creatingOperator: "Dodavanje operatera",
    username: "Korisničko ime",
    password: "Lozinka",
    deactivate: "Obriši",
    comment: "Komentar",
    newRateCardName: "Novi naziv cenovnika",
    newRateCard: "Novi cenovnik",
    newLineName: "Novi naziv linije",
    cloneInverseLine: "Kloniraj inverznu liniju",
    cloneLine: "Kloniraj liniju",
    lineCloning: "Kloniranje linije",
    doYouWantToCloneLine: "Želite da klonirate liniju",
    //calemName: "Naziv kalem linije",
    acceptsETicket: "Prihvata e-kartu",
    periodName: "Naziv praznika",
    dateOverlap: "Datum se preklapa sa nekom od prethodno unetih",
    uniqueNameError: "Ime praznika je zauzeto",
    choosePeriod: "Praznici/Izuzeci",
    addPeronization: "Dodaj peronizaciju",
    creatingPeronization: "Dodavanje peronizacije",
    dashboard: "Početna",
    existingRateCard: "Postojeći cenovnik",
    addParking: "Dodaj parking",
    isOneWayOnly: "Samo jedan smer",
    isReturnTicketOnly: "Samo porvatna",
    isForAllLines: "Za sve linije",
    isRoundPrice: "Zaokruživanje",
    addArrivalsDepartures: "Dodaj polazak/dolazak",
    onlineSale: "Online prodaja",
    applyDiscountOneWay: "Za jedan smer",
    applyDiscountReturnTicket: "Za povratak",
    applyRoundPrice: "Zaokružena cena",
    applyToAllTrips: "Na svim polascima",
    documentValidation: "Verifikacija dokumenta",
    errors: {
        name: "Naziv",
        discountName: "Unesite naziv popusta",
        discountCarrier: "Unesite naziv prevoznika",
        discountAmount: "Unesite bar jednu visinu popusta",
        delete: "Brisanje nije moguće",
        dateOverlap: "Datum se preklapa sa nekom od prethodno unetih akcija!",
    },
    validation: {
        maxNumOfCharacters: "Maksimalni broj karaktera je ",
        minNumOfCharacters: "Minimalni broj karaktera je ",
        phone: "Telefon mora imati od 8 do 15 brojeva"
    }
};

export default sr;
