import {customAxiosBE} from "../config";

export async function getAllByLineId(id) {
    return customAxiosBE.get(`/lineStation/line/${id}?size=2000&sort=departureTime,asc`)
}

export async function getByLineAndStationIdAPI(lineId, stationId){
    return customAxiosBE.get(`/lineStation/line/${lineId}/stationId/${stationId}`)
}

export async function getReturnDepartureByStationIdAPI(stationId) {
    return customAxiosBE.get(`/lineStation/return-departure?stationId=${stationId}`);
}
export async function insertLineStationAPI(data) {
    return customAxiosBE.post(`/lineStation`,data)
}

export async function resetLineStationAPI(lineId) {
    return customAxiosBE.post(`/lineStation/reset`,lineId)
}

export async function getAllLineStationsByLineIdsAPI(data) {
    return customAxiosBE.post(`/lineStation/getAllLineStationsByLineIds`,data)
}

export async function updateLineStationAPI(data) {
    return customAxiosBE.put(`/lineStation`,data)
}

export async function deleteLineStationAPI(id) {
    return customAxiosBE.delete(`/lineStation/${id}`)
}

export async function findStationsBySearchDataAPI(payload) {
    const {name, lineId, depStationId} = payload?.data
    return customAxiosBE.post(`/lineStation/search?page=${payload.page}&size=${payload.rowsPerPage}`, {
        name: name,
        lineId: lineId,
        depStationId: depStationId
    })
}
