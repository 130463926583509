import {Helmet} from 'react-helmet-async';
// sections
import {Alert, Stack, Typography} from "@mui/material";
import AuthLoginForm from "./AuthLoginForm";
import LoginLayout from "../../../layouts/login";

// ----------------------------------------------------------------------

export default function LoginPage() {
    return (
        <>
            <Helmet>
                <title>BUS Soft - Logovanje | ESIR BUS</title>
            </Helmet>

            <Typography sx={{textAlign: "center", paddingTop: 5}} variant="h4"> Dobrodosli na BUS Soft - business and
                ticketing management system</Typography>

            <LoginLayout>

                <Stack spacing={2} sx={{mb: 5, position: 'relative'}}>
                    <Typography variant="h5">Prijavi se na BUS Soft portal</Typography>
                </Stack>

                <Alert sx={{mb: 3}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={14} variant="caption">
                            Tehnicka podrska dostupna :<strong>{` 24/7/365`}</strong>
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={14} variant="caption">
                            Broj telefona: <strong>011/4444-588</strong>
                        </Typography>
                    </Stack>
                </Alert>

                <AuthLoginForm/>

            </LoginLayout>
        </>
    );
}
