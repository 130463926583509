import {
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack, TextField,
    Typography
} from "@mui/material";
import {useSelector} from "../../redux/store";
import {useEffect, useState} from "react";
import {INVOICE_TYPE, PAYMENT_TYPE, SETTINGS_PAYMENTS, TRANSACTION_TYPE} from "../../constants";
import moment from "moment";
import {calculateBasicOfItem} from "../../helper/other";
import {Invoice} from "../../helper/invoice/Invoice";
import {useSnackbar} from "notistack";
import {LoadingButton, ToggleButtonGroup} from "@mui/lab";
import MethodButton from "../../components/MethodButton";
import {alpha} from "@mui/material/styles";
import {fCurrency} from "../../utils/formatNumber";
import {getCashierName} from "../../utils/other";

export default function PlatformDialog({additional, open, onClose, onSuccess}){

    const {enqueueSnackbar} = useSnackbar();

    const {allItemsList} = useSelector(state => state.items);
    const {roundedPrice,} = useSelector(state => state.userSettings);
    const {currentTaxRates} = useSelector(((state) => state.taxCore));
    const {settings, userImage, silentPrint, printerSize, ticketLogo} = useSelector(state => state.settings);

    const [selectedPayments, setSelectedPayments] = useState([]);
    const [exchange, setExchange] = useState(0);
    const [loading, setLoading] = useState(false);

    const item = allItemsList.find(item => item.includeOnPlatform === true);
    const total = item?.price || 0

    const roundTotal = roundedPrice ? Math.ceil(total/10)*10 : total

    let showExchange = selectedPayments.length === 1 && selectedPayments[0].paymentType === PAYMENT_TYPE.cash && settings.change;
    const filteredPayments = SETTINGS_PAYMENTS.filter(value => !!settings[value.name]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSelectedPayments([{
                paymentType: PAYMENT_TYPE.cash,
                amount: roundTotal
            }]);
            setExchange(0);
        }
        return () => isMounted = false;
    }, [roundTotal]);

    const handleMethodClick = (method, isDouble) => {
        if (isDouble) {
            setSelectedPayments([{
                paymentType: method.value,
                amount: roundTotal
            }]);
        } else {
            let arr = [...selectedPayments];
            let index = arr.findIndex(localMethod => localMethod.paymentType === method.value);
            if (index === -1) {
                arr.push({
                    paymentType: method.value,
                    amount: 0
                });
            } else {
                arr.splice(index, 1);
            }
            if (arr.length > 1) {
                arr = arr.map(value => ({
                    ...value,
                    amount: 0
                }));
            } else {
                arr = arr.map(value => ({
                    ...value,
                    amount: roundTotal
                }));
            }
            setSelectedPayments(arr);
        }
        setExchange(0);
    };

    const handleChangeAmount = (event, index) => {
        const {name, value} = event.target;
        let arr = [...selectedPayments];
        let sumAll = arr.filter(localMethod => localMethod.paymentType !== name).reduce((acc, {amount}) => acc + amount, 0);
        let amount = Number(Number(value).toFixed(2));
        let newAmount = Number(Number(sumAll + amount <= roundTotal ? amount : roundTotal - sumAll).toFixed(2));
        arr[index] = {
            ...arr[index],
            amount: showExchange ? amount : newAmount
        };
        if (showExchange) {
            setExchange(Number(Number(amount - roundTotal).toFixed(2)));
        }
        setSelectedPayments(arr);
    };

    const handleIssuePlatformCard = async (e) => {
        try {
            setLoading(true)
            e.preventDefault();
            if (!item) {
                return enqueueSnackbar("Ne postoji proizvod/usluga za izdavanje!", {variant: "warning"});
            }
            let sumAll = selectedPayments.reduce((acc, {amount}) => acc + amount, 0);
            if (showExchange) {
                sumAll = sumAll - exchange;
            }
            if (sumAll !== roundTotal) {
                return enqueueSnackbar("Zbir plaćanja mora biti isti kao i ukupan iznos", {variant: "warning"});
            }
            let localExchange = exchange < 0 ? 0 : exchange;
            let payment = selectedPayments;
            if (selectedPayments.length === 1) {
                payment = selectedPayments.map(value => ({
                    ...value,
                    amount: roundTotal
                }));
            }
            const base = calculateBasicOfItem({
                ...item,
                unitPrice: item.price,
                quantity: 1
            }, currentTaxRates);
            const invoice = new Invoice();
            invoice.setItems([{
                ...item,
                totalAmount: item.price,
                name: item.name,
                quantity: 1,
                labels: [item.vat],
                unitPrice: item.price,
                baseAmount: base,
                pdv: item.price - base,
                cashier: getCashierName(),
                salesTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            }])
            invoice.setRoundedPrice = roundedPrice;
            invoice.setPayments(payment);
            invoice.setInvoiceType(INVOICE_TYPE.normal);

            invoice.setTransactionType(TRANSACTION_TYPE.sale)
            // invoice.setBuyerIdFromCustomer(customer)
            // OTHER
            invoice.setInternalData({
                peronTicket: true,
                barrierId: -1
            })
            invoice.setSilentPrint(silentPrint)
            invoice.setPrinterSize(printerSize)
            invoice.setLogo(userImage);
            invoice.setTicketLogo(ticketLogo);
            invoice.setExchange(localExchange || 0)
            invoice.setPrintLogoInvoice(settings?.printLogoInvoice || "");
            invoice.setPrintLogoTicket(settings?.printLogoTicket || "");
            await invoice.sendAndPrintInvoice();
            await invoice.printPeron(invoice.totalCounter, additional);
            if (onSuccess) {
                onSuccess();
            }
            onClose();
        } catch (error) {
            console.error("handleSubmitPaymentDialog", error);
            if (typeof error === "string") {
                return enqueueSnackbar(error, {variant: "error"});
            }
            return enqueueSnackbar(error?.message, {variant: "error"});
        } finally {
            setLoading(false)
        }
    }

    return(
        <Dialog open={open} onClose={loading ? null : onClose}>
            <form>
                <DialogTitle>Fiskalizacija perona</DialogTitle>
                <DialogContent>
                    <Divider sx={{borderStyle: "dashed", mb: 1}}>
                        <Chip label={"Načini plaćanja"} variant="outlined"/>
                    </Divider>
                    <ToggleButtonGroup
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}>
                        {filteredPayments.map((method, index) =>
                            <MethodButton
                                key={`payment-method-${method.name}-${index}`}
                                onClick={handleMethodClick}
                                method={method}
                                selectedPayments={selectedPayments}/>
                        )}
                    </ToggleButtonGroup>
                    <Card sx={{
                        //textTransform: "capitalize",
                        color: (theme) => (theme.palette.mode === "light" ? "primary.darker" : "primary.lighter"),
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
                        paddingX: 3,
                        paddingY: 2
                    }}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="button" color="dimgrey">
                                Ukupan iznos za uplatu: {fCurrency(roundTotal.toString())} RSD
                            </Typography>
                            <Stack direction="row" spacing={1}>
                                {selectedPayments.map((value, index) =>
                                    <TextField
                                        size="small"
                                        autoFocus={index === 0}
                                        key={`selected-payment-${index}-${value.paymentType}`}
                                        sx={{
                                            maxWidth: 150
                                        }}
                                        label={getLabel(value.paymentType)}
                                        value={value.amount}
                                        name={value.paymentType}
                                        onChange={(event) => handleChangeAmount(event, index)}
                                    />
                                )}
                            </Stack>
                            {exchange > 0 &&
                                <Typography variant="button" color="dimgrey">
                                    Kusur: {fCurrency(exchange.toString())}
                                </Typography>}
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="outlined" color="inherit" onClick={onClose}>
                        Otkaži [ESC]
                    </Button>
                    <LoadingButton loading={loading} onClick={handleIssuePlatformCard} variant="contained">
                        Prihvati
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    );
}

const getLabel = (method) => {
    return SETTINGS_PAYMENTS.find(localMethod => localMethod.value === method).displayName;
};
