// @mui
import {alpha, styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
// auth
// routes
// components
import {CustomAvatar} from "../../../components/custom-avatar";
import {getSessionOperator} from "../../../helper/session";
import {findRoleName} from "../../../helper/other";
import {useState} from "react";
import ChangeBusOperatorDialog from "../ChangeBusOperatorDialog";
import {useAuthContext} from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12)
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
    const operator = getSessionOperator();
    const {user} = useAuthContext();

    const [openBusLoginDialog, setOpenLoginDialog] = useState(false);

    const handleOpenBusModal = () => {
        setOpenLoginDialog(true)
    }

    return (
        <StyledRoot>
            <CustomAvatar src={operator?.photoURL} alt={operator?.username} name={operator?.username}/>

            <Box sx={{ml: 2, minWidth: 0}}>
                <Typography variant="subtitle2" noWrap>
                    {operator?.username}
                </Typography>

                <Typography variant="body2" noWrap sx={{color: "text.secondary"}}>
                    {findRoleName(operator?.group)}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{color: "text.secondary"}}>
                    Prijavljeni ste na:
                </Typography>
                <Typography
                    sx={{
                        cursor: "pointer",
                        '&:hover': {
                            color: "primary.main"
                        }
                    }}
                    variant="body2"
                    onClick={handleOpenBusModal}>
                    {user?.name}
                </Typography>
            </Box>
            {openBusLoginDialog &&
                <ChangeBusOperatorDialog
                    open={openBusLoginDialog}
                    onClose={() => setOpenLoginDialog(false)}
                />}
        </StyledRoot>
    );
}
