import PropTypes from "prop-types";
import * as Yup from "yup";
import {useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
// form
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @mui
import {LoadingButton} from "@mui/lab";
import {Box, Card, Grid, Stack, Typography} from "@mui/material";
// utils
import {fData} from "../../utils/formatNumber";
// routes
// assets
import {countries} from "../../assets/data";
// components
import FormProvider, {RHFSelect, RHFTextField, RHFUploadAvatar} from "../../components/hook-form";
import {PATH_DASHBOARD} from "../../routes/path";
import {dispatch} from "../../redux/store";
import {addCustomer, getClientDetails, updateCustomer} from "../../redux/slices/customers";
import {CUSTOMER_TYPES} from "../../constants";


// ----------------------------------------------------------------------

CustomersNewEditForm.propTypes = {
    isEdit: PropTypes.bool,
    currentCustomer: PropTypes.object
};

export default function CustomersNewEditForm({currentCustomer, searched, handleSaveAndSubmit}) {

    // searched znaci da je korisnik trazen prilikom izdavanja b2b

    const navigate = useNavigate();

    const NewUserSchema = Yup.object().shape({
        taxCorePrefix: Yup.number().typeError("Identifikacioni broj je obavezan").required("Identifikacioni broj je obavezan"),
        name: Yup.string().required("Naziv firme je obavezan"),
        tin: Yup.number().typeError("Unesite samo brojeve").required("PIB je obavezan"),
        jmbg: Yup.number().typeError("Unesite samo brojeve").required("Matini broj je obavezan").test("len", "Mora biti najmanje 7 karaktera", val => !val || (val && val.toString().length > 6)),
        address: Yup.string().required("Adresa je obavezna"),
        city: Yup.string().required("Grad je obavezan"),
        country: Yup.string().required("Država je obavezna")
    });
    const defaultValues = useMemo(
        () => ({
            name: currentCustomer?.name || searched?.name || "",
            taxCorePrefix: currentCustomer?.taxCorePrefix || null,
            tin: currentCustomer?.tin || searched?.TIN || "",
            jmbg: currentCustomer?.jmbg || searched?.CIN || "",
            email: currentCustomer?.email || "",
            phoneNumber: currentCustomer?.phoneNumber || "",
            address: currentCustomer?.address || searched?.address || "",
            city: currentCustomer?.city || searched?.city || "",
            country: currentCustomer?.country || "",
            avatarUrl: currentCustomer?.avatarUrl || "",
            isVerified: currentCustomer?.isVerified || true
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentCustomer, searched]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues
    });

    const {
        reset,
        resetField,
        setValue,
        handleSubmit,
        formState: {isSubmitting}
    } = methods;

    useEffect(() => {
        if (currentCustomer) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCustomer]);

    const onSubmit = async (props, a, b, c) => {
        if (searched) {
            await dispatch(addCustomer(props)).unwrap().then(_ => {
                handleSaveAndSubmit(props)
                reset()
            }).catch(e => {
                console.error(e, 'onSubmit')
            })
            // handleSaveAndSubmit(props)
            // reset()
        } else {
            dispatch(currentCustomer ? updateCustomer({...props, uid: currentCustomer.uid})
                : addCustomer(props)).unwrap().then(_ => {
                navigate(PATH_DASHBOARD.customers.root);
            }).catch(_ =>
                navigate(PATH_DASHBOARD.customers.root)
            );
        }
    }

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file)
            });

            if (file) {
                setValue("avatarUrl", newFile);
            }
        },
        [setValue]
    );

    const handleChangeTin = async (e) => {
        setValue("tin", e.target.value);
        if (e.target.value.length >= 8) {
            await dispatch(getClientDetails(e.target.value)).unwrap().then(response => {
                const {CIN, ZIP, address, city, name} = response;
                setValue("name", name);
                setValue("jmbg", CIN);
                setValue("city", city);
                setValue("country", "Serbia");
                setValue("address", `${address}, ${ZIP}`);
            }).catch(_ => {
                resetField("name");
                resetField("jmbg");
                resetField("city");
                resetField("country");
                resetField("address");
            });
        } else {
            resetField("name");
            resetField("jmbg");
            resetField("city");
            resetField("country");
            resetField("address");
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{pt: 10, pb: 5, px: 3}}>
                        <Box sx={{mb: 5}}>
                            <RHFUploadAvatar
                                name="avatarUrl"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 2,
                                            mx: "auto",
                                            display: "block",
                                            textAlign: "center",
                                            color: "text.secondary"
                                        }}
                                    >
                                        Dozvoljeni formati *.jpeg, *.jpg, *.png, *.gif
                                        <br/> maksimalne veličine do {fData(3145728)}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card sx={{p: 3.85}}>
                        <RHFSelect sx={{mb: 2}} name="taxCorePrefix" label="Tip kupca*">
                            <option value=""/>
                            {CUSTOMER_TYPES.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.description}
                                </option>
                            ))}
                        </RHFSelect>
                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: "repeat(1, 1fr)",
                                sm: "repeat(2, 1fr)"
                            }}>
                            <RHFTextField name="tin" label="PIB*" onChange={handleChangeTin}/>
                            <RHFTextField name="name" label="Naziv firme*"/>
                            <RHFTextField name="jmbg" label="Matični broj*"/>
                            <RHFTextField name="email" label="Mejl adresa"/>
                            <RHFTextField name="phoneNumber" label="Kontakt telefon"/>
                            <RHFSelect name="country" label="Zemlja*" placeholder="Zemlja*">
                                <option value=""/>
                                {countries.map((option) => (
                                    <option key={option.code} value={option.label}>
                                        {option.label}
                                    </option>
                                ))}
                            </RHFSelect>
                            <RHFTextField name="city" label="Grad*"/>
                            <RHFTextField name="address" label="Adresa*"/>
                            <RHFTextField name="contactPerson" label="Kontakt osoba"/>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Stack alignItems="flex-end" sx={{mt: 3}}>
                <LoadingButton
                    type={handleSaveAndSubmit ? "button" : "submit"}
                    onClick={handleSaveAndSubmit ? handleSubmit(onSubmit) : null}
                    variant="contained" loading={isSubmitting}>
                    Sačuvaj
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
