import {customAxiosBE} from "../config";

export async function getAllLinesTypeByIdAPI(id) {
    return customAxiosBE.get(`/lineType/${id}`)
}

export async function getAllLineTypeAPI(payload) {
    return customAxiosBE.get(`/lineType?page=${payload.page}&size=${payload.rowsPerPage}`)
}

