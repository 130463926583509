import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteRateCardsAPI,
    getAllRateCardsAPI,
    getAllRateCardsWithOperatorAPI,
    insertRateCardsAPI,
    updateRateCardByProvidedOperatorAPI,
    updateRateCardsAPI
} from "../../api/rateCard";

export const fetchAllRateCards = createAsyncThunk('fetchAllRateCards', async (payload) => {
    return await getAllRateCardsAPI(payload)
})
export const fetchAllRateCardsWithOperator = createAsyncThunk('fetchAllRateCardsWithOperator', async (payload) => {
    return await getAllRateCardsWithOperatorAPI(payload)
})

export const deleteRateCardById = createAsyncThunk('deleteRateCardById', async (id) => {
    return deleteRateCardsAPI(id);
})

export const postRateCard = createAsyncThunk('postRateCard', async (payload, thunkAPI) => {
    try {
        return await insertRateCardsAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

export const updateRateCard = createAsyncThunk('updateRateCard', async (payload,thunkAPI) => {
    try {
        return await updateRateCardsAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})
export const updateRateCardByProvidedOperator = createAsyncThunk('updateRateCardByProvidedOperator', async (payload) => {
    return updateRateCardByProvidedOperatorAPI(payload);
})

const initialState = {
    rateCards: [],
    foundRateCards: [],
    loadingRateCards: false,
}

export const rateCardsSlice = createSlice({
    name: 'rateCards',
    initialState,
    reducers: {
        specialLoading: (state, {payload}) => {
            state.loadingRateCards = payload
        },
        searchRateCardSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundRateCards = payload;
                state.loadingRateCards = false
            }
        }
    },
    extraReducers: {
        [fetchAllRateCards.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [fetchAllRateCards.fulfilled]: (state, {payload}) => {
            state.loadingRateCards = false;
            state.rateCards = payload.data;
            state.foundRateCards = payload.data;
        },
        [fetchAllRateCards.rejected]: (state) => {
            state.loadingRateCards = false;
        },
        [postRateCard.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [postRateCard.fulfilled]: (state) => {
            state.loadingRateCards = false;
        },
        [postRateCard.rejected]: (state) => {
            state.loadingRateCards = false;
        },
        [updateRateCard.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [updateRateCard.fulfilled]: (state) => {
            state.loadingRateCards = false;
        },
        [updateRateCard.rejected]: (state) => {
            state.loadingRateCards = false;
        },
        [fetchAllRateCardsWithOperator.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [fetchAllRateCardsWithOperator.fulfilled]: (state) => {
            state.loadingRateCards = false;
        },
        [fetchAllRateCardsWithOperator.rejected]: (state) => {
            state.loadingRateCards = false;
        },
        [updateRateCardByProvidedOperator.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [updateRateCardByProvidedOperator.fulfilled]: (state) => {
            state.loadingRateCards = false;
        },
        [updateRateCardByProvidedOperator.rejected]: (state) => {
            state.loadingRateCards = false;
        },
        [deleteRateCardById.pending]: (state) => {
            state.loadingRateCards = true;
        },
        [deleteRateCardById.fulfilled]: (state) => {
            state.loadingRateCards = false;
            // let index = getIndexForService(arr, action.meta.arg)
            // if (index !== -1) {
            //     arr.splice(index, 1)
            // }
            // state.rateCards = state.rateCards
        },
        [deleteRateCardById.rejected]: (state) => {
            state.loadingRateCards = false;
        }
    }
});
export const {searchRateCardSlice, specialLoading} = rateCardsSlice.actions;
export const rateCardsReducer = rateCardsSlice.reducer
