import PropTypes from "prop-types";
// @mui
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
// components


LinesListTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterLineName: PropTypes.string,
    filterLicenceName: PropTypes.string,
    filterIssuedFor: PropTypes.string,
    onFilterLineName: PropTypes.func,
    onFilterLicenceName: PropTypes.func,
    onFilterIssuedFor: PropTypes.func,
    onResetFilter: PropTypes.func,
};

export default function LinesListTableToolbar({
                                                  isPreview,
                                                  isFiltered,
                                                  filterLineName,
                                                  filterBorderName,
                                                  filterLicenceName,
                                                  onFilterLineName,
                                                  onFilterBorderName,
                                                  onFilterLicenceName,
                                                  onResetFilter,
                                              }) {

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            <TextField
                size={"small"}
                fullWidth
                value={filterLineName}
                onChange={onFilterLineName}
                placeholder="Naziv linije..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                size={"small"}
                fullWidth
                value={filterLicenceName}
                onChange={onFilterLicenceName}
                placeholder="Naziv licence..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isPreview && <TextField
                size={"small"}
                fullWidth
                value={filterBorderName}
                onChange={onFilterBorderName}
                placeholder="Naziv prevoznika"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />}

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    Očisti
                </Button>
            )}
        </Stack>
    )
}
