import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";

export async function getAllPlatformsAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.get(`/platforms?${parsedParams}`, {stationId: payload?.stationId})
}

export async function getAllPlatformsByStationIdAPI(stationId) {
    return customAxiosBE.get(`/platforms/stationId/${stationId}`)
}

export async function insertNewPlatformAPI(payload) {
    return customAxiosBE.post(`/platforms`, payload)
}

export async function updatePlatformAPI(payload) {
    return customAxiosBE.put(`/platforms`, payload)
}

export async function deletePlatformAPI(id) {
    return customAxiosBE.delete(`/platforms/${id}`,)
}
