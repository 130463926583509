import {Helmet} from "react-helmet-async";

// layouts
import OperatorLayout from "../../../layouts/login/OperatorLayout";

export default function OperatorLoginPage() {
    return (
        <>
            <Helmet>
                <title>BUS Soft -Logovanje operatera</title>
            </Helmet>
            <OperatorLayout/>
        </>
    )
}