import {enUS, ruRU, srRS} from "@mui/material/locale";
import {translate} from "./i18n";

export const allLangs = [
    {
        label: translate("serbian"),
        value: "sr",
        currency: "RSD",
        systemValue: srRS,
        icon: "/assets/icons/flags/ic_flag_sr.svg"
    },
    {
        label: translate("bosnian"),
        value: "ba",
        currency: "KM",
        systemValue: srRS,
        icon: "/assets/icons/flags/ic_flag_ba.svg"
    },
    {
        label: translate("english"),
        value: "en",
        systemValue: enUS,
        currency: "usd",
        icon: "/assets/icons/flags/ic_flag_en.svg"
    },
    {
        label: translate("russian"),
        value: "ru",
        currency: "RSD",
        systemValue: ruRU,
        icon: "/assets/icons/flags/ic_flag_ru.svg"
    },
];

export const defaultLang =  allLangs[0];
