import {getAllCurrentTaxRates} from "../../utils/other";
import {DIRECTION_NAME} from "../../constants";
import moment from "moment";

const PROD_VAT = "A";
const TEST_VAT = "A";

const toNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);

const createSummarized = () => ({
    baseAmount: 0,
    pdv: 0,
    unitPrice: 0,
    discount: 0
});

export const invoiceItemsForShow = (data) => {
    const {
        price = 0,
        returnPrice = 0,
        priceMonthly = 0,
        passengers = [],
        directionType = null
    } = data;

    const prices = {price, returnPrice, priceMonthly};
    let foundPriceByDirection = getDirectionPrice(directionType, prices);

    const itemsArr = passengers.map((passenger) => {
        const discountPCT = passenger?.discountPCT || {};

        if (discountPCT?.exceptionPrice > 0) {
            return {
                price: discountPCT.exceptionPrice,
                name: discountPCT.name,
                quantity: 1
            };
        }

        const basePrice = toNumber(foundPriceByDirection);
        if (discountPCT?.discountPCT > 0) {
            const discount = basePrice * (toNumber(discountPCT.discountPCT) / 100);
            const finalPrice = discountPCT?.applyRoundPrice
                ? Math.ceil((basePrice - discount) / 10) * 10
                : basePrice - discount;

            return {
                price: finalPrice,
                name: discountPCT.name,
                quantity: 1
            };
        }

        if (discountPCT?.discountABS > 0) {
            const discount = basePrice - toNumber(discountPCT.discountABS);
            const finalPrice = discountPCT?.applyRoundPrice
                ? Math.ceil((basePrice - discount) / 10) * 10
                : basePrice - discount;

            return {
                price: finalPrice,
                name: discountPCT.name,
                quantity: 1
            };
        }

        return {
            price: basePrice,
            name: "Osnovna",
            quantity: 1
        };
    });

    return itemsArr.reduce((acc, current) => {
        const existingItem = acc.find(item => item.price === current.price && item.name === current.name);
        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            acc.push(current);
        }
        return acc;
    }, []);
};


export const invoiceTotalAmount = (data) => {
    const {
        price = 0,
        returnPrice = 0,
        priceMonthly = 0,
        passengers = [],
        saleAction = null,
        services = [],
        directionType = null,
        currentTaxRates = [],
        isTicketPriceConfirmation = false
    } = data;

    let totalSummary = createSummarized();
    const prices = {price, returnPrice, priceMonthly}

    let foundPriceByDirection = getDirectionPrice(directionType, prices)
    passengers.forEach((passenger) => {
        const passengerSummary = amountByPassenger(passenger, currentTaxRates, foundPriceByDirection, isTicketPriceConfirmation);
        totalSummary = aggregateSummaries(totalSummary, passengerSummary);
    });

    services.forEach((service) => {
        const serviceSummary = amountByService(service, currentTaxRates, saleAction?.discountPCT || 0, saleAction?.discountABS || 0, passengers.length);
        totalSummary = aggregateSummaries(totalSummary, serviceSummary);
    });

    totalSummary = amountBySalesAction(totalSummary, saleAction, currentTaxRates);

    return totalSummary;
};

// OUTPUT  baseAmount, pdv, price, discount
const amountByPassenger = (passenger, currentTaxRates, price, isTicketPriceConfirmation) => {
    const finalCalculation = createSummarized();
    if (isTicketPriceConfirmation) {
        finalCalculation.unitPrice = toNumber(price);
        finalCalculation.discount = 0;
        finalCalculation.baseAmount = calculateBase(finalCalculation.unitPrice, currentTaxRates)
        finalCalculation.pdv = finalCalculation.unitPrice - finalCalculation.baseAmount;
        return finalCalculation;
    }
    const discountData = passenger.discountPCT || {};
    const {
        exceptionPrice = null
    } = discountData;


    if (exceptionPrice !== null && toNumber(exceptionPrice) > 0) {
        finalCalculation.unitPrice = toNumber(exceptionPrice);
        finalCalculation.discount = price - exceptionPrice;
        finalCalculation.baseAmount = calculateBase(finalCalculation.unitPrice, currentTaxRates)
        finalCalculation.pdv = finalCalculation.unitPrice - finalCalculation.baseAmount;
    } else {
        const unitPrice = calculateDiscountedPrice(discountData, price);
        finalCalculation.unitPrice = unitPrice;
        finalCalculation.discount = price - unitPrice;
        finalCalculation.baseAmount = calculateBase(unitPrice, currentTaxRates);
        finalCalculation.pdv = unitPrice - finalCalculation.baseAmount;
    }
    return finalCalculation;
}

// ARTIKLI
const amountByService = (services, currentTaxRates, discountPCT, discountABS, passengerCount) => {
    const initialSummary = createSummarized(); // Initial empty summary

    return services.reduce((acc, service) => {
        const {
            price,
            includeDiscount
        } = service;

        let finalServiceCalculation = createSummarized();
        const totalPrice = toNumber(price) * passengerCount;
        finalServiceCalculation.unitPrice = totalPrice;

        if (includeDiscount) {
            finalServiceCalculation.discount = calculateDiscount(totalPrice, discountPCT, discountABS);
        }

        finalServiceCalculation.baseAmount = calculateBase(finalServiceCalculation.unitPrice - finalServiceCalculation.discount, currentTaxRates);
        finalServiceCalculation.pdv = finalServiceCalculation.unitPrice - finalServiceCalculation.baseAmount;

        return aggregateSummaries(acc, finalServiceCalculation);
    }, initialSummary);
};

const amountBySalesAction = (totalSummary, salesAction, currentTaxRates) => {
    if (!salesAction) {
        return totalSummary;
    }

    let finalSummaryWithAction = {...totalSummary};
    const {amount, salesActionType} = salesAction;
    const discountABS = salesActionType.isAbsolute ? toNumber(amount) : 0;
    const discountPCT = !salesActionType.isAbsolute ? toNumber(amount) : 0;

    let discountValue = 0;

    if (discountPCT > 0) {
        discountValue = (finalSummaryWithAction.unitPrice * discountPCT) / 100;
    } else if (discountABS > 0) {
        discountValue = discountABS;
    }

    finalSummaryWithAction.unitPrice += discountValue;

    finalSummaryWithAction.baseAmount = calculateBase(finalSummaryWithAction.unitPrice, currentTaxRates);
    finalSummaryWithAction.pdv = finalSummaryWithAction.unitPrice - finalSummaryWithAction.baseAmount;
    return finalSummaryWithAction;
};

const calculateBase = (unitPrice, currentTaxRates) => {
    const tax = toNumber(taxValueOfLabel(currentTaxRates));
    const baseAmount = toNumber(unitPrice) / (tax * 0.01 + 1);
    return toNumber(baseAmount.toFixed(2));
}

const taxValueOfLabel = (currentTaxRates) => {
    let tax = 0;
    const allRates = getAllCurrentTaxRates(currentTaxRates);
    for (let i = 0; i < allRates.length; i++) {
        if (allRates[i].label === TEST_VAT) {
            tax = allRates[i].rate;
            break;
        }
    }
    return tax;
};

export const calculateDiscountedPrice = (discountData, price) => {
    const {discountPCT = 0, discountABS = 0, applyRoundPrice = false} = discountData;
    let discount = 0;

    if (discountPCT > 0) {
        discount = toNumber(price) * (toNumber(discountPCT) / 100);
    } else if (discountABS > 0) {
        discount = toNumber(discountABS);
    }

    let discountedPrice = toNumber(price) - discount;

    if (applyRoundPrice) {
        discountedPrice = Math.ceil(discountedPrice / 10) * 10;
    }
    return discountedPrice;
};

const calculateDiscount = (unitPrice, discountPCT, discountABS, applyRoundPrice) => {
    let discount = 0;
    if (discountPCT > 0) {
        discount = ((unitPrice * (toNumber(discountPCT)) / 100));
    } else if (discountABS > 0) {
        discount = toNumber(discountABS);
    }
    if (applyRoundPrice) {
        discount = Math.ceil(discount / 10) * 10;
    }

    return discount.toFixed(2);
};

const aggregateSummaries = (summary1, summary2) => {
    return {
        baseAmount: toNumber(summary1.baseAmount) + toNumber(summary2.baseAmount),
        pdv: toNumber(summary1.pdv) + toNumber(summary2.pdv),
        unitPrice: toNumber(summary1.unitPrice) + toNumber(summary2.unitPrice),
        discount: toNumber(summary1.discount) + toNumber(summary2.discount)
    };
}

// TODO za akcije dodati logiku i obratiti paznju da li je medjunarodna

function splitItemsByQuantity(items) {
    const result = [];
// sluzi da bi razdvoji na sve iteme po quantity
    items.forEach(item => {
        for (let i = 0; i < item.quantity; i++) {
            const newItem = {
                totalAmount: item.unitPrice,
                quantity: 1,
                unitPrice: item.unitPrice,
                discount: item.discount,
                name: item.name,
                isAfterBorder: item.isAfterBorder,
                isReturnTicket: item.isReturnTicket,
                labels: [...item.labels],
                passengerUid: [item.passengerUid[i]],
                isDiscountApplied: item.isDiscountApplied,
                ticketType: item.ticketType
            };
            result.push(newItem);
        }
    });

    return result;
}

export const processPassengers = (itemsData, passengers, invoiceLine, startStation, endStation, returnTrip, currentTaxRates, directionType) => {
    const ticketObjects = [];
    let itemIndex = 0;
    const splitItems = splitItemsByQuantity(itemsData);
    passengers.forEach((passenger) => {
        if (directionType === DIRECTION_NAME[1]) {
            // Ako je directionType 2, po dva itema idu na jednog putnika
            for (let i = 0; i < 2; i++) {
                const item = splitItems[itemIndex];
                const passUid = item.passengerUid ? item.passengerUid[0] : null;
                const ticket = createTicketObject(
                    item,
                    passenger,
                    invoiceLine,
                    startStation,
                    endStation,
                    returnTrip,
                    item.discount,
                    item.totalAmount,
                    passUid
                );
                ticketObjects.push(ticket);
                itemIndex += 1;
            }
        } else {
            const item = splitItems[itemIndex];
            const passUid = item.passengerUid ? item.passengerUid[0] : null;
            const ticket = createTicketObject(
                item,
                passenger,
                invoiceLine,
                startStation,
                endStation,
                returnTrip,
                item.discount,
                item.totalAmount,
                passUid
            );
            ticketObjects.push(ticket);
            itemIndex += 1;
        }
    });

    return ticketObjects;
};


const createTicketObject = (item, passenger, invoiceLine, startStation, endStation, returnTrip, discountValue, totalAmount, passUid) => {
    return {
        trip: {id: invoiceLine.tripId},
        ticketType: item.ticketType || {id: 1},
        isReturnTicket: item.isReturnTicket,
        departureStation: {id: item.isReturnTicket ? endStation.id : startStation.id},
        arrivalStation: {id: item.isReturnTicket ? startStation.id : endStation.id},
        journeyDate: item.isReturnTicket ? (returnTrip?.departureTripTime || null) : moment.utc(invoiceLine?.departureTripTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        itemName: item.name,
        labels: JSON.stringify(item.labels),
        price: totalAmount,
        discountAmount: discountValue,
        seatReserved: item.isReturnTicket ? null : passenger.seatNumber,
        passenger: {
            passengerUid: passUid,
            passengerName: passenger?.name || null,
            passengerLastName: passenger?.surname || null,
            passengerTelephone: passenger?.phone || null,
            passengerEmail: passenger?.email || null,
            passengerDocumentNumber: passenger?.clientIdentity || null,
            type: passenger?.type || null,
        }
    };
};


export const calculateTotalSum = (allInvoiceItems, saleAction, currentTaxRates, PAYMENT_TYPE) => {
    let totalCalc = {
        baseAmount: 0,
        pdv: 0,
        unitPrice: 0,
        discount: 0,
        totalAmount: 0
    };
    allInvoiceItems.forEach(item => {

        const baseAmount = calculateBase(item.totalAmount, currentTaxRates);
        const pdv = item.totalAmount - baseAmount;
        totalCalc.baseAmount += baseAmount;
        totalCalc.pdv += pdv;
        totalCalc.unitPrice += item.unitPrice;
        totalCalc.totalAmount += item.totalAmount || 0;

        if (item.discount) {
            totalCalc.discount += item.discount;
        }
    });


    if (saleAction) {
        totalCalc = amountBySalesAction(totalCalc, saleAction, currentTaxRates);
    }
    return {
        amount: roundToTwoDecimals(totalCalc.totalAmount),
        paymentType: PAYMENT_TYPE,
        baseAmount: roundToTwoDecimals(totalCalc.baseAmount),
        pdv: roundToTwoDecimals(totalCalc.pdv),
        discount: roundToTwoDecimals(totalCalc.discount)
    };
};


const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

const getDirectionPrice = (directionType, {price, returnPrice, priceMonthly}) => {
    switch (directionType) {
        case DIRECTION_NAME[0]:
            return price;
        case DIRECTION_NAME[1]:
            return returnPrice;
        case DIRECTION_NAME[2]:
            return priceMonthly;
        default:
            return 0;
    }
};
