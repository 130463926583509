import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

// OVO SU CARRIERS
export async function getAllVehiclesAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/vehicle?${parsedParams}`)
}

export async function insertVehiclesAPI(payload) {
    return customAxiosBE.post(`/vehicle`, payload)
}

export async function updateVehiclesAPI(payload) {
    return customAxiosBE.post(`/vehicle`, payload)
}

export async function getBusByIdApi(id) {
    return customAxiosBE.get(`/vehicle/${id}`)
}

export async function getOperatorByBusIdAPI(id) {
    return customAxiosBE.get(`/vehicle/${id}/operator`)
}

export const searchVehicleAPI = async (data, cancelToken) => {
    let response
    const {plateNumber, operatorName, description, seats, operatorId} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/vehicle/search?${params}`, {
            plateNumber: plateNumber,
            operatorName: operatorName,
            description: description,
            seats: seats,
            operatorId: operatorId
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export async function availableSeatsAPI(id) {
    return customAxiosBE.get(`/vehicle/${id}/maxSeats`)
}

export async function getVehiclesByTripAPI(tripId) {
    return customAxiosBE.get(`/vehicle/${tripId}/vehiclesByTrip`)
}

export async function getVehiclesByTripHasVehicleAPI(tripId) {
    return customAxiosBE.get(`/vehicle/${tripId}/vehiclesByTripHasVehicle`)
}

export const searchVehicleByOperatorAPI = async (data, cancelToken) => {
    let response
    const {plateNumber, operatorId} = data
    const params = parsePaginationParams(data)
    await customAxiosBE
        .post(`/vehicle/search/bySubOperator?${params}`, {
            plateNumber: plateNumber,
            operatorId: operatorId
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
