import {Card, CardContent, CardHeader, Divider, IconButton, Stack, Typography} from "@mui/material";
import Iconify from "../../components/Iconify";
import * as React from "react";
import {useLocales} from "../../locales";
import moment from "moment/moment";
import {fCurrency} from "../../utils/formatNumber";
import {getPriceByDirectionType} from "./helper";
import {DIRECTION_NAME} from "../../constants";

export default function ReturnTripCard({trip, onClose}) {
    const {translate} = useLocales();
    return (
        <Card sx={{mt: 1.3, mx: 10}}>
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        pb: 1
                    }
                }}
                title={translate("return")}
                action={
                    <IconButton onClick={onClose}>
                        <Iconify icon="material-symbols:close"/>
                    </IconButton>
                }
            />
            <CardContent
                sx={{
                    paddingTop: 0,
                    display: "flex",
                    flexDirection: "row"
                }}>
                <Stack flexGrow={1}>
                    <Typography
                        variant="h6" sx={{
                        color: "#6DABE4"
                    }}
                        component="p">
                        {moment(trip.departureTripTime).format("DD.MM.YYYY HH:mm")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {trip?.lineName?.toUpperCase()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {trip?.operatorName?.toUpperCase()}
                    </Typography>
                </Stack>
                <Divider
                    orientation={'horizontal'}
                    flexItem
                    sx={{
                        borderRightWidth: 4,
                        borderStyle: 'dashed',
                        mr: 2
                    }}
                />
                <Stack
                    sx={{
                        width: '20%'
                    }}
                    justifyContent="flex-start">
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2"
                                    sx={{fontWeight: 500, color: "text.disabled"}}>
                            Broj vozila:
                        </Typography>
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 500,
                                    color: "text.disabled"
                                }}>
                                {trip?.numberOfVehicles}
                            </Typography>
                            <Iconify sx={{color: '#6DABE4'}} icon="mingcute:bus-line" width={16}/>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2"
                                    sx={{fontWeight: 500, color: "text.disabled"}}>
                            Broj mesta:
                        </Typography>
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 500,
                                    color: "text.disabled"
                                }}>
                                {trip?.freeSeats}
                            </Typography>
                            <Iconify sx={{color: '#6DABE4'}} icon="ic:round-event-seat" width={18}/>
                        </Stack>
                    </Stack>
                    <Typography
                        variant="h5"
                        sx={{textAlign: 'right', color: 'success.main', mt: 1}}>
                        {`${fCurrency(getPriceByDirectionType(DIRECTION_NAME[0], trip)?.toString() || "0")} din`}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
}
