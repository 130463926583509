import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {getAllVehiclesAPI, getBusByIdApi, insertVehiclesAPI, updateVehiclesAPI} from "../../api/vehicle";

export const fetchAllBuses = createAsyncThunk('fetchAllBuses', async (data) => {
    return await getAllVehiclesAPI(data)
})

export const findBusById = createAsyncThunk('findBusById', async (id) => {
    return await getBusByIdApi(id)
})

export const deleteBus = createAsyncThunk('deleteBus', async () => {
    // TODO nije moguce
})

export const postBus = createAsyncThunk(
    'bus/postBus',
    async (payload) => {
        return insertVehiclesAPI(payload);
    }
);

export const updateBus = createAsyncThunk(
    'bus/updateBus',
    async (payload) => {
        return updateVehiclesAPI(payload);
    }
);

const initialState = {
    busesList: [],
    foundVehicles: [],
    loadingBuses: false,
}

const busesSlice = createSlice({
    name: 'buses',
    initialState,
    reducers: {
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingBuses = payload
            }
        },
        searchBusSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundVehicles = payload;
                state.loadingBuses = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBuses.pending, (state) => {
                state.loadingBuses = true;
            })
            .addCase(fetchAllBuses.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundVehicles = payload.data;
                    state.busesList = payload.data;
                }
                state.loadingBuses = false;
            })
            .addCase(fetchAllBuses.rejected, (state) => {
                state.loadingBuses = false;
            })
            .addCase(deleteBus.pending, (state) => {
                state.loadingBuses = true;
            })
            .addCase(deleteBus.fulfilled, (state, action) => {
                const arr = [...current(state.busesList)];
                const index = arr.findIndex((i) => i.id === action.meta.arg);
                if (index !== -1) {
                    arr.splice(index, 1);
                    state.busesList = arr;
                }
                state.loadingBuses = false;
            })
            .addCase(deleteBus.rejected, (state) => {
                state.loadingBuses = false;
            })
            .addCase(postBus.pending, (state) => {
                state.loadingBuses = false;
            })
            .addCase(postBus.fulfilled, (state) => {
                state.loadingBuses = false;
            })
            .addCase(postBus.rejected, (state) => {
                state.loadingBuses = false;
            })
            .addCase(updateBus.pending, (state) => {
                state.loadingBuses = false;
            })
            .addCase(updateBus.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateBus.rejected, (state) => {
                state.loadingBuses = false;
            });
    },
});

export const {searchBusSlice, specialLoading} = busesSlice.actions;
export default busesSlice.reducer;
