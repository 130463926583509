import {createSlice, current} from '@reduxjs/toolkit';
import {Client} from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {BUS_SOFT_API} from "../../config";

let baseURL;

if (process.env.NODE_ENV === "production") {
    baseURL = BUS_SOFT_API;
} else {
    baseURL = BUS_SOFT_API;
}

export const initializeWebSocket = (token) => (dispatch) => {
    const socket = new SockJS(`${baseURL}ws`);
    const client = new Client({
        webSocketFactory: () => socket,
        connectHeaders: {
            Authorization: `Bearer ${token}`,
        },
        reconnectDelay: 1000,
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000,
        maxWebSocketChunkSize: 1073741824,
    });

    client.onConnect = function () {
        dispatch(setConnected(true));
        client.subscribe('/camera', (message) => {
            console.debug("WS: camera", message);
            try {
                if (message?.body) {
                    dispatch(addCameraEvent(JSON.parse(message.body)));
                }
            } catch (error) {
                console.error('Error handling message: ', error);
            }finally {
                dispatch(setConnected(false));
            }
        });
    };
    client.onDisconnect = function (frame) {
        console.error('DISKONEKTIOOVAO SE: ' + frame);
        dispatch(setConnected(false));
    };
    client.onStompError = function (frame) {
        console.error('Broker reported error: ' + frame);
        dispatch(setConnected(false));
    };
    client.onChangeState((state)=>{
        console.debug("PROMENIO STATE", state)
    })
    socket.onerror = (e)=>{
        console.error('socket error: ' + e);
        dispatch(setConnected(false));
    }
    socket.onclose = (e) =>{
        console.error('socket onclose: ' + e);
        dispatch(setConnected(false));
    }

    client.activate();
    dispatch(setStompClient(client));
};

const initialState = {
    stompClient: null,
    connected: false,
    cameraEvents: [],
};

const slice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        setConnected: (state, action) => {
            state.connected = action.payload;
        },
        addCameraEvent: (state, {payload}) => {
            const arr = [...current(state.cameraEvents)]
            arr.push(payload);
            state.cameraEvents = arr;
        },
        setStompClient: (state, action) => {
            state.stompClient = action.payload;
        },
    },
});

export const {setConnected, addCameraEvent, setStompClient} = slice.actions;

export default slice.reducer;

