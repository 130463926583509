import axios from "axios";

const BASE_URL = "https://europe-central2-itc-bussoft.cloudfunctions.net/esir/"

export const customAxios = (token, pac) => axios.create({
    baseURL: BASE_URL,
    headers: {
        'client_os': 'web',
        'client_app': 'busSoft',
        'client_version': '26',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
        "PAC": pac,
    },
    withCredentials: false,
});
