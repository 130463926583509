import {TableHeadCustom, TablePaginationCustom, TableSkeleton, useTable} from "../../../components/table";
import {useLocales} from "../../../locales";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "../../../redux/store";
import {useEffect, useState} from "react";
import {Card, Dialog, Table, TableBody, TableContainer} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import {specialLoading} from "../../../redux/slices/buses";
import {debounceSearch} from "../../../api/searchDebounce";
import {debounce} from "lodash";
import {
    findSubOperatorsBySearchData,
    getAllOperators,
    searchSubOperatorsSlice
} from "../../../redux/slices/operatorsBe";
import {findSubOperatorsBySearchDataAPI} from "../../../api/operators";
import CarriersToolbar from "../../../pages/Carriers/CarriersToolbar";
import OperatorLoginTableRow from "./OperatorLoginTableRow";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useNavigate} from "react-router-dom";
import {PATH_DASHBOARD} from "../../../routes/path";

const TABLE_HEAD = translate => [
    {id: "name", label: translate("carrierName"), align: "left"},
    {id: "country.name", label: translate("country"), align: "left"},
    {id: "operatorDetails.description", label: translate("description"), align: "left"},
    {id: "operatorDetails.status", label: translate("status"), align: "left"},
    {id: "", width: 10, sortable: false}
];

export default function ChangeBusOperatorDialog({open, onClose}) {
    const {changeOperator} = useAuthContext();
    const {user} = useAuthContext();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        setPage,
        setSelectedOne,
    } = useTable({
        defaultOrderBy: "name"
    });

    const dispatch = useDispatch();
    const {foundSubOperators, loadingOperatorsBe} = useSelector(state => state.operatorsBE);
    const [filterName, setFilterName] = useState("");
    const [loading, setLoading] = useState(false);
    const isFiltered =
        filterName !== ""

    useEffect(() => {
        if (filterName) {
            dispatch(specialLoading(true))
            debounceSearchOperator({
                name: filterName,
                page: page,
                rowsPerPage: rowsPerPage,
                operatorId: user.id,
                order,
                orderBy,
            });
        } else {
            dispatch(findSubOperatorsBySearchData({
                page: page,
                rowsPerPage: rowsPerPage,
                operatorId: user.id,
                order,
                orderBy,
            }))
                .unwrap().catch(reason => {
                console.error("getAllOperators", reason)
                enqueueSnackbar(translate("errorFetching"), {variant: "error"});
            });
        }
    }, [page, rowsPerPage, order, orderBy, filterName]);

    const searchOperator = async (value) => {
        try {
            const response = await debounceSearch(findSubOperatorsBySearchDataAPI, value);
            dispatch(searchSubOperatorsSlice(response))
        } catch (error) {
            enqueueSnackbar(translate("errorOnServer"), {variant: "error"})
        }
    };

    const debounceSearchOperator = debounce(searchOperator, 300);

    const handleFilterName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleResetFilter = () => {
        setFilterName("");
        setSelectedOne(null)
    };

    const handleLogin = async data => {
        try {
            setLoading(true)
            await changeOperator(data.firebaseUid)
            navigate(PATH_DASHBOARD.preview.driveParkList)
            onClose();
        } catch (e) {
            enqueueSnackbar(translate("error"), {variant: "error"})
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
            <Card>
                <CarriersToolbar
                    isFiltered={isFiltered}
                    filterName={filterName}
                    onFilterName={handleFilterName}
                    onResetFilter={handleResetFilter}
                />
                <TableContainer sx={{ maxHeight: 600, position: "relative", overflow: "auto" }}>
                        <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD(translate)}
                                rowCount={foundSubOperators?.content?.length}
                                onSort={onSort}
                            />
                            <TableBody>
                                {loadingOperatorsBe ?
                                    Array.from({length: rowsPerPage}, (_, i) => (
                                        <TableSkeleton key={i} columns={5}/>
                                    ))
                                    :
                                    foundSubOperators?.content?.map((row, index) =>
                                        <OperatorLoginTableRow
                                            key={index}
                                            row={row}
                                            loading={loading}
                                            onLoginClick={() => handleLogin(row)}
                                        />)
                                }
                            </TableBody>
                        </Table>
                </TableContainer>
                <TablePaginationCustom
                    loading={loadingOperatorsBe}
                    count={foundSubOperators?.totalElements || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    //
                    dense={dense}
                    onChangeDense={onChangeDense}
                />
            </Card>
        </Dialog>
    );
}
