import PropTypes from 'prop-types';
// form
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import {Autocomplete} from '@mui/material';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
    name: PropTypes.string,
};

export default function RHFAutocomplete({name, icon, ...other}) {
    const {control} = useFormContext();
    const {translate} = useLocales();
    return <Controller
        name={name}
        control={control}
        render={({field}) =>
            <Autocomplete
                loadingText={translate("loadingText")}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={translate("enterAtLeastOneCharacter")}
                fullWidth size="small"
                {...field}
                {...other} />}/>;
}
