import PropTypes from "prop-types";

// @mui
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
// components


// ----------------------------------------------------------------------

PrivilegesToolbar.propTypes = {
    filter: PropTypes.object,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    onResetFilter: PropTypes.func,
};

export default function PrivilegesToolbar({
                                              filter,
                                              onChange,
                                              onSearch,
                                              onResetFilter,
                                          }) {
    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            <TextField
                size={"small"}
                fullWidth
                value={filter.clientName}
                name="clientName"
                onChange={onChange}
                placeholder="Ime"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                size={"small"}
                fullWidth
                value={filter.clientSurname}
                name="clientSurname"
                onChange={onChange}
                placeholder="Prezime"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                size={"small"}
                fullWidth
                value={filter.clientIdentity}
                name="clientIdentity"
                onChange={onChange}
                placeholder="Broj lk."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />
            <Stack direction="row" spacing={2}>
                <Button
                    size="small"
                    color="primary"
                    sx={{flexShrink: 0}}
                    onClick={onSearch}
                    startIcon={<Iconify icon="material-symbols:search"/>}>
                    Potvrdi
                </Button>
                <Button
                    size="small"
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}>
                    Očisti
                </Button>
            </Stack>
        </Stack>
    )
}
