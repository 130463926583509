// @mui
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import {useSelector} from "../../redux/store";

export default function LocationDialog({open, handleLocationClick, ...other }) {
    const {locations, loading} = useSelector((select => select.locations));
  const [selectedLocation, setSelectedLocation] = useState({});

  const handleChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={open} {...other}>
      <DialogTitle sx={{ pb: 2 }}>Izaberi lokaciju</DialogTitle>
      {loading ? <CircularProgress color="success" /> :
        <Select sx={{ ml: 2, mr: 2 }}
                size="small"
                value={selectedLocation}
                onChange={handleChange}>
          {locations.map((item) => (
            <MenuItem
              key={item.uid}
              value={item}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: "body2"
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>}
      <DialogActions>
        <Button variant="outlined" color="success" onClick={(e) => {
          handleLocationClick(e, selectedLocation);
        }}>
          Potvrdi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
