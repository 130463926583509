import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext(null);

export const LoadingProvider = ({children}) => {
    const [loading, setLoading] = useState(false);

    const startLoading = () => {
        if (!loading) {
            setLoading(true);
        }
    }

    const endLoading = () => {
        setLoading(false)
    }

    return (
        <LoadingContext.Provider value={{loading, startLoading, endLoading}}>
            {children}
        </LoadingContext.Provider>
    );
};


function useLoading() {
    return useContext(LoadingContext);
}

export {LoadingContext, useLoading};