import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {insertExceptionRateAPI} from "../../../api/rate";
import {useSnackbar} from "notistack";

export default function PriceChangeDialog({setLoading, open, handleClose, data}) {
    const {enqueueSnackbar} = useSnackbar();
    const [value, setValue] = useState()
    const handleChange = (e) => {
        const {value} = e.target
        setValue(value)
    }

    const handleSave = () => {
        setLoading(true);
        const copy = { ...data };

        if (data.key === "priceOneWay") {
            copy.priceOneWay = Number(value) || null;
        } else if (data.key === "returnTicketPrice") {
            copy.priceReturnTicket = Number(value) || null;
        } else if (data.key === "priceMonthly") {
            copy.priceMonthly = Number(value) || null;
        }

        insertExceptionRateAPI(copy)
            .then(_ => {
                enqueueSnackbar("Uspešno", { variant: "success" });
            })
            .catch(e => {
                console.error(e, 'EE');
                enqueueSnackbar("Greška", { variant: "error" });
            })
            .finally(() => {
                setLoading(false);
                handleClose();
            });
    };

    return (
        <Dialog
            sx={{
                backdropFilter: 'blur(10px)',
                borderRadius: '12px',
            }}
            open={open}
            onClose={handleClose}>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '24px',
                }}>
                <TextField
                    InputProps={{
                        size: "small"
                    }} onChange={handleChange}/>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center', padding: '16px'}}>
                <Button
                    size="small"
                    onClick={handleClose}>
                    Zatvori
                </Button>
                <Button
                    size="small"
                    onClick={handleSave}>
                    Sačuvaj
                </Button>
            </DialogActions>
        </Dialog>
    )
}
