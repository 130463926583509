import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { IconButton, TextField, MenuItem } from '@mui/material';
import Iconify from "../Iconify";

// ----------------------------------------------------------------------

RHFSelectIcon.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    refName: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default function RHFSelectIcon({ name, refName, disabled, children, icon, onChange, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    inputRef={refName || null}
                    size="small"
                    disabled={disabled}
                    select
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <IconButton>
                                <Iconify icon={icon}/>
                            </IconButton>
                        ),
                    }}
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                    onChange={(event) => {
                        field.onChange(event);
                        if (onChange) {
                            onChange(event);
                        }
                    }}
                >
                    {children}
                </TextField>
            )}
        />
    );
}
