import {isArrayContainsTax} from "../other";
import {child, get} from "firebase/database";
import {AUTH, DB, dbRef} from "../../auth/FirebaseContext";
import {collection, getDocs, query, where} from "firebase/firestore";
import {parseInvoice} from "../index";
import {putInvoiceOffline} from "../../store/offlineDb";


export const addRefundTaxItemsFromInvoiceToArray = (array, invoice, isRefound = false) => {
    let taxes = invoice.taxItems
    let length = invoice.taxItems ? taxes.length : 0;
    for (let i = 0; i < length; i++) {
        let indexOfItem = isArrayContainsTax(array, taxes[i]);
        if (indexOfItem === -1) {
            array.push({
                amount: isRefound ? -taxes[i].amount : taxes[i].amount,
                categoryName: taxes[i].categoryName,
                categoryType: taxes[i].categoryType,
                label: taxes[i].label,
                rate: taxes[i].rate
            })
        } else {
            if (!isRefound) {
                array[indexOfItem].amount = parseFloat(array[indexOfItem].amount + parseFloat(taxes[i].amount))
            }
        }
    }
}

export async function getUserInfo() {
    try {
        const tin = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/profile/pub`))).val()
        let userInfo = (await get(child(dbRef, `public/businesses/${tin.TIN}`))).val()
        userInfo.PIB = tin.TIN
        return userInfo;
    } catch (e) {

    }
}

// Import racuna
export async function getOnlineInvoiceByInvoiceNumber(invoiceNumber, parse = false) {
    return new Promise(async (resolve, reject) => {
        try {
            let arr = [];
            const q = query(collection(DB, `invoices/users/${AUTH.currentUser.uid}`),
                where("invoiceNumber", "==", invoiceNumber));
            const querySnapshot = await getDocs(q);
            for (const doc of querySnapshot.docs) {
                if (parse) {
                    const parsedInvoice = parseInvoice(doc.data(), false, doc.id);
                    arr.push(parsedInvoice);
                    await putInvoiceOffline({
                        ...parsedInvoice,
                        isInsertedOffline: false
                    });
                } else {
                    arr.push({
                        ...doc.data(),
                        id: doc.id
                    });
                }
            }
            resolve(arr[0]);
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });
}
