import PropTypes from "prop-types";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
// @mui
import {Box, Drawer, Stack, Typography} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import {NAV} from "../../../config";
// components
import Scrollbar from "../../../components/Scrollbar";
import {NavSectionVertical} from "../../../components/nav-section";
//
import {navConfig, navConfigUzice} from './config';

import NavAccount from "./NavAccount";
import {useAuthContext} from "../../../auth/useAuthContext";
import useActiveLink from "../../../hooks/useActiveLink";
import {PATH_DASHBOARD} from "../../../routes/path";
import {AUTH} from "../../../auth/FirebaseContext";
import {useLocales} from "../../../locales";
import {alpha, styled} from "@mui/material/styles";
import packageJson from '../../../../package.json';
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12)
}));

NavVertical.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func
};

export default function NavVertical({openNav, onCloseNav}) {
    const {translate} = useLocales();
    const {pathname} = useLocation();

    let isDesktop = useResponsive("up", "lg");
    const {active} = useActiveLink(PATH_DASHBOARD.general.app || PATH_DASHBOARD.otpravnik.list);

    const showSideBar =  pathname === PATH_DASHBOARD.otpravnik.list || active
    if (active || showSideBar) {
        isDesktop = false;
    }

    const {user} = useAuthContext();

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);


    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column"
                }
            }}>
           <StyledRoot>
               <Typography textAlign="center" variant="body2"
                           sx={{color: "text.secondary"}}>
                   {translate("appVersion", {
                       number: packageJson.version
                   })}
               </Typography>
           </StyledRoot>
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0
                }}>
                <Typography sx={{ml: 1, fontWeight: 700}}>{user.name}</Typography>
                <NavAccount/>
            </Stack>

            <NavSectionVertical
                data={AUTH.currentUser.email === "graduzice@becejprevoz.com" ? navConfigUzice : navConfig}/>

            <Box sx={{flexGrow: 1}}/>

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                display: showSideBar ? "none" : null,
                flexShrink: {lg: 0},
                width: {lg: NAV.W_DASHBOARD}
            }}>
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD,
                            bgcolor: "transparent",
                            borderRightStyle: "dashed"
                        }
                    }}>
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD
                        }
                    }}>
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
