import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd.MM.yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateNewDate(date){
    return date? format(new Date(date), 'dd.MM.yyyy') : ''
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd.MM.yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}

export function formatDate(inputDate) {
    let momentObj;

    if (inputDate instanceof Date) {
        momentObj = moment.utc(inputDate);
    } else {
        if (typeof inputDate !== 'string') {
            throw new Error('Invalid input. The inputDate must be either a Date object or a string in a supported format.');
        }

        if (inputDate.includes('-')) {
            momentObj = moment.utc(inputDate, 'YYYY-MM-DD');
        } else {
            momentObj = moment.utc(inputDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        }
    }
    return momentObj.tz('Europe/Belgrade').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
}
