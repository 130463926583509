
export const TICKET_TYPE_BASIC_NAME = "Osnovna"

export const ALLOTMENT_TYPE = [
    {id: 1, name: "SVI"},
    {id: 2, name: "PREDEFINISANI"},
    {id: 3, name: "INSTANT"},
    {id: 4, name: "ARHIVIRANI"},
    {id: 5, name: "TRENUTNI"}
]

export const MSG_VIEWERS_GROUP = (translate) => [
    { label: translate("forEveryone"), value: 0 },
    { label: translate("forPassengers"), value: 1 },
    { label: translate("forEmployers"), value: 2 },
    { label: translate("forOtpravnik"), value: 3 },
];

export const DATE_PICKER_FORMAT = "dd/MM/yyyy"
export const DATE_TIME_INSERT_FORMAT = "YYYY-MM-DD 00:00:00"
export const DATE_INSERT_FORMAT = "YYYY-MM-DD"

// KORISTIM U SPECIJALNIM SITUACIJAMA KADA ZNAM DA JE OCEKIVAN REZULTAT MANJI OD 2k
export const DEFAULT_SEARCH_PARAMS_2000 = {
    page: 0,
    rowsPerPage: 2000
}

export const DEFAULT_SEARCH_PARAMS_200 = {
    page: 0,
    rowsPerPage: 200
}

export const DEFAULT_SEARCH_PARAMS_20 = {
    page: 0,
    rowsPerPage: 20
}

export const WAIT_DEBOUNCE_TIME = 500

export const ALERT_TYPES = {
    error: {variant: "error"},
    info: {variant: "info"},
    success: {variant: "success"},
    warning: {variant: "warning"},
}
