import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deletePlatformAPI,
    getAllPlatformsAPI,
    getAllPlatformsByStationIdAPI,
    insertNewPlatformAPI,
    updatePlatformAPI
} from "../../api/platform";

export const fetchAllPlatforms = createAsyncThunk('fetchAllPlatforms', async (data) => {
    return (await getAllPlatformsAPI(data)).data
})

export const fetchAllPlatformsByStationId = createAsyncThunk('fetchAllPlatforms', async (stationId) => {
    return (await getAllPlatformsByStationIdAPI(stationId)).data
})

export const insertNewPlatform = createAsyncThunk('insertNewPlatform', async (data) => {
    return (await insertNewPlatformAPI(data)).data
})

export const deletePlatform = createAsyncThunk('deletePlatform', async (data) => {
    return (await deletePlatformAPI(data)).data
})

export const updatePlatform = createAsyncThunk('updatePlatform', async (data) => {
    return (await updatePlatformAPI(data)).data
})


const initialState = {
    platforms: {
        content: [],
        totalElements: 0,
    },
    platformsById: [],
    loading: false,
    updating: false,
}

const slice = createSlice({
    name: 'platforms',
    initialState,
    reducers: {},
    extraReducers: {

        [fetchAllPlatforms.pending]: state => {
            state.loading = true;
        },
        [fetchAllPlatforms.fulfilled]: (state, {payload}) => {
            state.platforms = payload;
            state.loading = false;
        },
        [fetchAllPlatforms.rejected]: state => {
            state.loading = false;
        },

        [fetchAllPlatformsByStationId.pending]: state => {
            state.loading = true;
        },
        [fetchAllPlatformsByStationId.fulfilled]: (state, {payload}) => {
            state.platformsById = payload;
            state.loading = false;
        },
        [fetchAllPlatformsByStationId.rejected]: state => {
            state.loading = false;
        },
        [insertNewPlatform.pending]: state => {
            state.loading = true;
        },
        [insertNewPlatform.fulfilled]: (state, {payload}) => {
            const currentState = current(state.platforms);
            const platforms = [...currentState.content];
            const index = platforms.findIndex(x => x.id === payload.id);
            if (index === -1) {
                platforms.push(platforms);
                state.platforms = {
                    ...currentState,
                    content: platforms
                }
            }
            state.loading = false;
        },
        [insertNewPlatform.rejected]: state => {
            state.loading = false;
        },
        [updatePlatform.pending]: state => {
            state.loading = true;
        },
        [updatePlatform.fulfilled]: (state, {payload}) => {
            const currentState = current(state.platforms);
            const platforms = [...currentState.content];
            const index = platforms.findIndex(x => x.id === payload.id);
            if (index !== -1) {
                platforms[index] = payload;
                state.platforms = {
                    ...currentState,
                    content: platforms
                }
            }
            state.loading = false;
        },
        [updatePlatform.rejected]: state => {
            state.loading = false;
        },
        [deletePlatform.pending]: state => {
            state.loading = true;
        },
        [deletePlatform.fulfilled]: (state, {meta}) => {
            const currentState = current(state.platforms);
            const platforms = [...currentState.content];
            const index = platforms.findIndex(x => x.id === meta.arg);
            platforms.splice(index, 1)
            state.platforms = {
                ...currentState,
                content: platforms
            }
            state.loading = false;
        },
        [deletePlatform.rejected]: state => {
            state.loading = false;
        },
    }
});

export default slice.reducer

