import PropTypes from "prop-types";
import {useState} from "react";

import {IconButton, MenuItem, Switch, TableCell, TableRow} from "@mui/material";
import Iconify from "../../../components/Iconify";
import Label from "../../../components/label";

import MenuPopover from "../../../components/menu-popover";
import {useLocales} from "../../../locales";
import moment from "moment/moment";
import {LINE_TRAIFFIC_TYPES} from "../../../constants";
import {dispatch} from "../../../redux/store";
import {deactiavteAction} from "../../../redux/slices/actions";

ActionsValueTableRow.propTypes = {
    row: PropTypes.object,
    onEditRow: PropTypes.func,
};

export default function ActionsValueTableRow({row, onEditRow, reload}) {

    const {amount, trafficType, vat, dateFrom, dateTo, salesActionType, id} = row;

    const {translate} = useLocales();

    const [openPopover, setOpenPopover] = useState(null);


    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const changeStatus = () => {
        dispatch(deactiavteAction(id)).unwrap().then(response => {
            reload();
        }).catch(e => console.error(e, "e"));
    };

    const handleSwitchChange = (event) => {
        if (isDateToFuture) {
            changeStatus();
        }
    };

    const currentTimeInBelgrade = moment.tz("Europe/Belgrade").format("YYYY-MM-DD HH:mm:ss");
    const isDateToFuture = moment(dateTo).isAfter(currentTimeInBelgrade);

    return (
        <>
            <TableRow hover>

                <TableCell align="left">{salesActionType?.name}</TableCell>
                <TableCell align="left">
                    <Label
                        variant="soft"
                        color={salesActionType.isAbsolute ? 'success' : 'info'}
                    >
                        {salesActionType.isAbsolute ? translate("absolute") : translate("relative")}
                    </Label>
                </TableCell>
                <TableCell align="left">{amount}</TableCell>
                <TableCell align="left">{vat}</TableCell>
                <TableCell align="left">{LINE_TRAIFFIC_TYPES.find(item => item.id === trafficType)?.name}</TableCell>
                <TableCell align="left">{moment(dateFrom).format("DD-MM-YYYY")}</TableCell>
                <TableCell align="left">{dateTo ? moment(dateTo).format("DD-MM-YYYY") : ""}</TableCell>
                <TableCell align="right">
                    <Switch checked={isDateToFuture} onChange={handleSwitchChange} color="primary"/>
                </TableCell>
                <TableCell align="right">
                    <IconButton color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{width: 140}}>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        handleClosePopover();
                    }}
                >
                    <Iconify icon="eva:edit-fill"/>
                    {translate("edit")}
                </MenuItem>
            </MenuPopover>
        </>
    )
}
