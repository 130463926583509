import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {
    deleteLocationAPI,
    getAllLocationsAPI,
    getLocationByIdAPI,
    insertLocationAPI,
    updateLocationAPI
} from "../../api/locations";

export const fetchAllStationsLocation =
    createAsyncThunk('fetchAllStationsLocation', async (data) => {
        return getAllLocationsAPI(data);
    })

export const deleteLocationById = createAsyncThunk('deleteLocationById', async (payload) => {
    return deleteLocationAPI(payload);
})

export const postLocation = createAsyncThunk('postLocation', async (payload) => {
    return insertLocationAPI(payload);
})

export const updateLocation = createAsyncThunk('updateLocation', async (payload) => {
    return updateLocationAPI(payload);
})

export const getLocationById = createAsyncThunk('getLocationById', async (payload) => {
    return getLocationByIdAPI(payload);
})

const initialState = {
    stationLocations: [],
    stationLocation: null,
    loadingStationsLocations: false,
    searchParam: ""
}

export const stationLocationsSlice = createSlice({
    name: 'apiStation',
    initialState,
    reducers: {
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        }
    },
    extraReducers: {
        [fetchAllStationsLocation.pending]: (state) => {
            state.loadingStationsLocations = true;
        },
        [fetchAllStationsLocation.fulfilled]: (state, {payload}) => {
            state.stationLocations = payload.data.content;
            state.loadingStationsLocations = false;
        },
        [fetchAllStationsLocation.rejected]: (state) => {
            state.loadingStationsLocations = false;
        },
        [postLocation.pending]: (state) => {
            state.loadingCountries = true;
        },
        [postLocation.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.stationLocations)];
            arr.push(payload.data)
            state.stationLocations = arr
            state.loadingStationsLocations = false;
        },
        [postLocation.rejected]: (state) => {
            state.loadingStationsLocations = false;
        },
        [updateLocation.pending]: (state) => {
            state.loadingStationsLocations = false;
        },
        [updateLocation.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.stationLocations)]
            const index = arr.findIndex(o => o.id === payload.data.id)
            if (index !== -1) {
                arr[index] = payload.data
                state.stationLocations = arr
            }
            state.loadingStationsLocations = false;
        },
        [updateLocation.rejected]: (state) => {
            state.loadingStationsLocations = false;
        },
        [deleteLocationById.pending]: (state) => {
            state.loadingStationsLocations = true;
        },
        [deleteLocationById.fulfilled]: (state, action) => {
            let arr = [...current(state.stationLocations)]
            let index = arr.findIndex(i => i.id === action.meta.arg)
            if (index !== -1) {
                arr.splice(index, 1)
                state.stationLocations = arr
            }
            state.loadingStations = false;
        },
        [deleteLocationById.rejected]: (state) => {
            state.loadingStationsLocations = false;
        },
        [getLocationById.pending]: (state) => {
            state.loadingStationsLocations = true;
        },
        [getLocationById.fulfilled]: (state, {payload}) => {
            state.stationLocations = payload.data.content;
            state.loadingStationsLocations = false;
        },
        [getLocationById.rejected]: (state) => {
            state.loadingStationsLocations = false;
        },
    }
});

export const stationLocationsReducer = stationLocationsSlice.reducer
