import {clearSession, setSessionAppVersion} from "../session";
import {INVOICE_TYPE, TRANSACTION_TYPE} from "../../constants";
import moment from "moment";

export function get_browser() {
    let ua = window.navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return {name: 'Opera', version: tem[1]};
        }
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
}

export function shortenTime(time) {
    const timeFormat = /^\d{2}:\d{2}:\d{2}$/;

    if (timeFormat.test(time)) {
        return time.slice(0, 5);
    }

    return time;
}

export function extendTime(time) {
    const timeFormat = /^\d{2}:\d{2}$/;

    if (timeFormat.test(time)) {
        return time + ":00";
    }

    return time;
}

export function sortByDateDescending(array) {
    return array.sort((a, b) => {
        const dateA = new Date(a.sdcDateTime);
        const dateB = new Date(b.sdcDateTime);
        return dateB - dateA;
    });
}

export function getCounterFromInvoice(invoice) {
    let invoiceNumber = invoice.invoiceNumber;
    let index = invoiceNumber.lastIndexOf("-") + 1
    let counter = invoiceNumber.substring(index, invoiceNumber.length)
    let firstLetter;
    switch (invoice.invoiceType) {
        case INVOICE_TYPE.training:
            firstLetter = "О"
            break;
        case INVOICE_TYPE.copy:
            firstLetter = "К"
            break;
        case INVOICE_TYPE.normal:
            firstLetter = "П"
            break;
        case INVOICE_TYPE.advance:
            firstLetter = "А"
            break;
        case INVOICE_TYPE.proforma:
            firstLetter = "Р"
            break;
        default:
            firstLetter = "#"
            break;
    }
    let secondLetter = invoice.transactionType === TRANSACTION_TYPE.sale ? "П" : "Р";
    return `${counter}/${firstLetter}${secondLetter}`
}

export function clearCache(appVersion) {
    if ('caches' in window) {
        caches.keys().then(async (names) => {
            // Delete all the cache files
            for (const name of names) {
                await caches.delete(name);
            }
        });
    }
    clearSession()
    window.location.reload(true);
    setSessionAppVersion(appVersion)
}

export function taxValueForLabel(item, currentTaxRates) {
    const vat = item.vat || item.labels[0]
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === vat)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export function taxValueForSingleLabel(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === label)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export const calculateTrafficLog = (price, tax, rates) => {
    const taxValue = taxValueForSingleLabel(tax, rates);
    return (price / (taxValue * 0.01 + 1))
}

export function getTaxValueByLabelRate(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        if (currentTaxRates[i].label === label) {
            tax = currentTaxRates[i].rate
        }
    }
    return tax
}

export function getTaxDetailsByLabel(label, currentTaxRates) {
    for (let obj of currentTaxRates) {
        for (let taxRate of obj.taxRates) {
            if (taxRate.label === label) {
                return {
                    name: obj.name,
                    rate: taxRate.rate,
                    label: taxRate.label
                };
            }
        }
    }
    return null;
}

export function calculateBasicOfItem(item, currentTaxRates) {
    let tax = taxValueForLabel(item, currentTaxRates)
    return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) *
        parseFloat(item.quantity)
}


export function isArrayContainsTax(array, tax) {
    let index = -1;
    if (!array)
        return index;
    if (array.length === 0)
        return index;
    for (let i = 0; i < array.length; i++) {
        if (array[i].label === tax.label) {
            index = i;
            return index
        }
    }
    return index;
}

export function convertItemName(name) {
    const chars = {'č': 'c', 'Č': 'C', 'ć': 'c', 'Ć': 'c', 'đ': 'dj', 'Đ': 'Đ', 'š': 's', 'Š': 'S', 'ž': 'z', 'Ž': 'Z'}
    return name?.replace(/[čČćĆđĐšŠžŽ]/g, m => chars[m])
}

export function isArrayContainsTaxA4(arr, item) {
    let index = -1
    if (!arr)
        return index;
    if (arr.length === 0)
        return index;
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].label.length; j++) {
            for (let k = 0; k < item.labels.length; k++) {
                if (arr[i].label[j] === item.labels[k]) {
                    return i
                }
            }
        }
    }
    return index;
}

export const findRoleName = (role) => {
    switch (role) {
        case 0:
            return "Administrator"
        case 1:
            return "Menadžer"
        case 2:
            return "Korisnik"
        case 3:
            return "Knjigovođa"
        case 4:
            return "Vozač"
        default:
            return "Ostalo"
    }
}

export const nameSlice = (name, width) => {
    return name.length > width ? name.slice(0, width) : name
}

export function validateValues(obj) {
    const filteredObject = {};

    if (typeof obj !== 'object') return undefined;
    for (const [key, value] of Object.entries(obj)) {
        if (value !== null && value !== undefined && value !== "") {
            filteredObject[key] = value;
        }
    }

    return Object.keys(filteredObject).length > 0 ? filteredObject : undefined;

}

export function blobToImage(image) {
    if (image) {
        return URL.createObjectURL(image);
    }
    return undefined;
}

export function createAdvanceProductName(vat) {
    if (!vat) {
        return undefined;
    }
    let name;
    switch (vat.toString()) {
        case "F":
            name = "10: Avans (F)";
            break;
        case "E":
            name = "11: Avans (E)";
            break;
        case "T":
            name = "12: Avans (T)";
            break;
        case "A":
            name = "13: Avans (A)";
            break;
        case "Ђ":
            name = "10: Avans (Ђ)";
            break;
        case "Е":
            name = "11: Avans (Е)";
            break;
        case "Г":
            name = "12: Avans (Г)";
            break;
        case "А":
            name = "13: Avans (А)";
            break;
        default:
            name = undefined;
    }
    return name;
}

export function calculatePdv(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        sum += parseFloat(items[i].unitPrice) *
            parseFloat(items[i].addedQuantity || items[i].quantity) - calculateBasicOfItem(items[i], currentTaxRates);
    }
    return sum;
}

export function calculateBasic(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        let tax = taxValueForLabel(items[i], currentTaxRates);
        sum += (parseFloat(items[i].unitPrice) / (tax * 0.01 + 1)) *
            parseFloat(items[i].addedQuantity || items[i].quantity);
    }
    return sum;
}

export const getCustomerIdentification = (customer) => {
    const tin = [10, 12, 40];
    const taxCorePrefix = parseInt(customer.taxCorePrefix);
    if (tin.includes(taxCorePrefix)) {
        return `${taxCorePrefix}:${customer.tin}`;
    } else if (taxCorePrefix === 11) {
        return `${taxCorePrefix}:${customer.jmbg}`;
    } else if (taxCorePrefix === 12) {
        return `${taxCorePrefix}:${customer.tin}:${customer.jbkjc}`;
    }
    return `${taxCorePrefix}:${customer.otherIndificationNumber}`;
};

export const handleCommentGroup = (id) => {
    //0-Bilteari\n1-Klijenti\n2-Svi
    switch (id) {
        case "0": {
            return "Svi"
        }
        case 0: {
            return "Svi"
        }
        case "1": {
            return "Putnici"
        }
        case 1: {
            return "Putnici"
        }
        case "2": {
            return "Zaposleni"
        }
        case 2: {
            return "Zaposleni"
        }
        case "3": {
            return "Otpravnik"
        }
        case 3: {
            return "Otpravnik"
        }
        default:
            return "/"
    }
}

export const convertUrlToBase64 = async (imageUrl) => {
    if (!imageUrl) {
        return null;
    }
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = imageUrl;
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL.replace(/^data:image\/png;base64,/, '')); // Samo uklanjamo "data:image/png;base64," deo
        };
        img.onerror = (error) => {
            reject(error);
        };
    });
};

export function calculateBasicOfSpecific(price, vat, currentTaxRates) {
    let sum = 0;
    let tax = taxValueForSingleLabel(vat, currentTaxRates);
    sum += (parseFloat(price) / (tax * 0.01 + 1))
    return sum;
}

export const getDurationFromLog = (log) => {
    const arrivalMoment = moment.utc(log.arrivalTime);
    const departureMoment = moment.utc(log.departureTime);

    const duration = moment.duration(departureMoment.diff(arrivalMoment));
    return Math.ceil(duration.asMinutes());
}
