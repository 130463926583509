import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {RHFAutocomplete, RHFCheckbox, RHFSelect, RHFTextField} from "../../../components/hook-form";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {dispatch} from "../../../redux/store";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {discountsOptionLabel} from "../helper";
import Iconify from "../../../components/Iconify";
import {useSelector} from "react-redux";
import {useLocales} from "../../../locales";
import {getDiscountForSale} from "../../../redux/slices/discounts";
import PassengerDetails from "./PassengerDetails";
import PrivilegeDialog from "../PrivilegeDialog";
import SeatsDialog from "../SeatsDialog";
import {getDirectionIndex} from "../../../constants";

export default function Passenger({departureTime, trip, open}) {
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const {loadingDiscounts} = useSelector(state => state.discounts);
    const [foundDiscounts, setFoundDiscounts] = useState([])
    const [privilegeDialogProps, setPrivilegeDialogProps] = useState({
        open: false,
        passengerIndex: undefined
    })
    const [seatDialogProps, setSeatDialogProps] = useState({
        open: false,
        passIndex: undefined,
    });

    const {
        control,
        setValue
    } = useFormContext();

    const {fields, append, remove, update} = useFieldArray({
        control,
        name: "passengers",
    });

    const passengers = useWatch({
        control,
        name: "passengers"
    })

    const passengersCount = useWatch({
        control,
        name: "passengersCount"
    })

    const directionType = useWatch({
        control,
        name: "directionType"
    })

    const passengerFullName = (index) => {
        const name = passengers[index]?.name
        const lastName = passengers[index]?.surname
        const clientIdentity = passengers[index]?.clientIdentity
        if(name && lastName && clientIdentity) return `${name} ${lastName} ${clientIdentity}`
        return `Putnik ${index+1}`
    }
    useEffect(() => {
        fetchData(trip);
    }, [open, trip, directionType])

    const fetchData = (trip) => {
        if (trip?.operatorId) {
            dispatch(getDiscountForSale({
                tripId: trip.id,
                operatorId: trip.operatorId,
                directionType: getDirectionIndex(directionType),
                isOnline: null,
                depStationId: trip.depStationId,
                arrStationId: trip.arrStationId,
            })).unwrap()
                .then(response => setFoundDiscounts(response)).catch(reason => {
                console.error("reason", reason)
                enqueueSnackbar(translate("errorFetching"), {variant: "error"});
            });
        }
    }


    const handlePassengerNumberChange = (event) => {
        const { value } = event.target;
        const passengersArr = [...passengers];
        if (value < passengersArr.length) {
            passengersArr.length = value;
        } else {
            for (let i = passengersArr.length; i < value; i++) {
                passengersArr.push({
                    havePrivilege: false,
                    discountPCT: null,
                    type: "adult",
                    priceException: null,
                    email: "",
                    insurance: false,
                    name: "",
                    phone: "",
                    sms: "",
                    surname: "",
                    clientIdentity: ""
                });
            }
        }

        setValue("passengers", passengersArr);
        setValue("passengersCount", value);
    }


    const handleAddPassClick = () => {
        append({
            havePrivilege: false,
            discountPCT: null,
            type: "adult",
            priceException: null,
            email: "",
            insurance: false,
            name: "",
            phone: "",
            sms: "",
            surname: "",
            clientIdentity: ""
        });
        setValue("passengersCount", Number(passengersCount) + 1)
    }

    const handleRemovePassClick = (index) => {
        remove(index);
        setValue("passengersCount", Number(passengersCount) - 1)
    }

    const handleHavePrivilegeChange = (event, index) => {
        const {checked} = event.target;
        update(index, {
            havePrivilege: checked,
            discountPCT: null,
            type: "adult",
            priceException: null,
            email: "",
            insurance: false,
            name: "",
            phone: "",
            sms: "",
            surname: "",
            clientIdentity: ""
        })
        if (checked) {
            setPrivilegeDialogProps({
                open: true,
                passengerIndex: index
            })
        }
    }

    const handleClosePrivilegeDialog = () => {
        update(privilegeDialogProps.passengerIndex, {
            havePrivilege: false,
            discountPCT: null,
            type: "adult",
            priceException: null,
            email: "",
            insurance: false,
            name: "",
            phone: "",
            sms: "",
            surname: "",
            clientIdentity: ""
        })
        setPrivilegeDialogProps({
            open: false,
            passengerIndex: undefined
        })
    }

    const handleOpenSeatChooser = (passIndex) => {
        if (trip.externalSale) {
            return;
        }
        setSeatDialogProps({
            open: true,
            passIndex
        })
    }

    const handleSeatConfirm = (vehicle) => {
        // Prolazi kroz sve putnike i pronalazi onog koji već ima ovo sedište i vozilo
        passengers.forEach((passenger, index) => {
            if (passenger.seatNumber === vehicle.seat && passenger.vehicleId === vehicle.vehicleId) {
                // Ako nađemo putnika sa istim seatNumber i vehicleId, postavi mu seatNumber i vehicleId na null
                setValue(`passengers.${index}.seatNumber`, '');
                setValue(`passengers.${index}.vehicleId`, '');
            }
        });

        setValue(`passengers.${seatDialogProps.passIndex}.seatNumber`, vehicle.seat || "");
        setValue(`passengers.${seatDialogProps.passIndex}.vehicleId`, vehicle.vehicleId || "");
    };

    const onSubmitPrivilege = (index, passenger) => {
        setPrivilegeDialogProps({
            open: false,
            passengerIndex: undefined
        })
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" marginTop={2} marginBottom={3}>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h6" sx={{color: 'text.disabled'}}>
                        Podaci putnika:
                    </Typography>
                    <IconButton
                        disabled={trip?.freeSeats === fields.length}
                        onClick={handleAddPassClick}>
                        <Iconify width={25} icon="eva:plus-fill"/>
                    </IconButton>
                </Stack>
                <RHFSelect
                    name={`passengersCount`}
                    label="Broj putnika"
                    InputLabelProps={{shrink: true}}
                    SelectProps={{native: false, sx: {textTransform: 'capitalize'}}}
                    onChange={handlePassengerNumberChange}
                    sx={{maxWidth: 200}}>
                    {Array.from({length: trip?.freeSeats}, (_, index) => index + 1).map((option) => (<MenuItem
                        key={`seat-${option}`}
                        value={option}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            '&:first-of-type': {mt: 0},
                            '&:last-of-type': {mb: 0},
                        }}>
                        {option}
                    </MenuItem>))}
                </RHFSelect>
            </Stack>
            <Box
                gap={2}
                display={fields.length > 2 ? "grid" : "flex"}
                gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(3, 1fr)",
                }}>
                {fields.map((field, index) =>
                    <Card
                        key={field.id}
                        sx={{
                            flex: 1
                        }}>
                        <CardHeader
                            title={passengerFullName(index) || `Putnik ${index}`}
                            action={
                                <IconButton
                                    disabled={fields.length === 1}
                                    onClick={() => handleRemovePassClick(index)}>
                                    <Iconify width={25} icon="mdi:remove"/>
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Stack gap={2}>
                                <Stack
                                    gap={3}
                                    flexDirection={fields.length > 2 ? "column" : "row"}>
                                    <RHFCheckbox
                                        disabled={trip.externalSale}
                                        onChange={(event) => handleHavePrivilegeChange(event, index)}
                                        label="Registrovan putnik sa povlasticom"
                                        name={`passengers.${index}.havePrivilege`}
                                    />
                                    {trip?.alSeatNumbered &&
                                        <RHFTextField
                                            disabled={trip.externalSale || !trip?.alSeatNumbered}
                                            label="Broj sedišta"
                                            name={`passengers.${index}.seatNumber`}
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() => handleOpenSeatChooser(index)}
                                        />
                                    }
                                    {(trip?.depCountryName === "Srbija" || trip.arrCountryName === "Srbija") &&
                                        <RHFAutocomplete
                                            fullWidth
                                            disabled={trip.externalSale}
                                            options={foundDiscounts}
                                            getOptionLabel={(option) => discountsOptionLabel(option)}
                                            onChange={(event, value) => {
                                                setValue(`passengers.${index}.discountPCT`, value)
                                            }}
                                            value={passengers[index]?.discountPCT || null}
                                            name={`passengers.${index}.discountPCT`}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Izaberite povlasticu / popust"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        size: "small",
                                                        startAdornment: (
                                                            loadingDiscounts ? <CircularProgress size={16}/> :
                                                                <Iconify
                                                                    width={16}
                                                                    icon={'mdi:discount-outline'}/>
                                                        ),
                                                    }}

                                                />}/>
                                    }
                                </Stack>
                                <PassengerDetails
                                    showRows={fields.length > 2}
                                    index={index}/>
                            </Stack>
                        </CardContent>
                    </Card>
                )}
            </Box>
            <Divider sx={{borderStyle: 'dashed'}}/>
            {privilegeDialogProps.open &&
                <PrivilegeDialog
                    sales={foundDiscounts}
                    setValue={setValue}
                    open={privilegeDialogProps.open}
                    onClose={handleClosePrivilegeDialog}
                    onSubmit={onSubmitPrivilege}
                    passengerIndex={privilegeDialogProps.passengerIndex}
                />
            }
            {seatDialogProps.open &&
                // TODO dodati tajmer da dohvati sva sedista kad istekne rezervacija
                <SeatsDialog
                    passengers={passengers}
                    open={seatDialogProps.open}
                    onClose={() => setSeatDialogProps({
                        open: false,
                        passIndex: undefined
                    })}
                    selected={fields[seatDialogProps.passIndex].seatNumber}
                    departureTime={departureTime}
                    arrStationId={trip.arrStationId}
                    depStationId={trip.depStationId}
                    tripId={trip.tripId}
                    onConfirm={handleSeatConfirm}
                />}
        </>
    )
}
