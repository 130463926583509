import {customAxiosBE} from "../config";

export async function getAllOperationalIntervalAPI(payload) {
    return customAxiosBE.get(`/operationalInterval?page=${payload.page}&size=${payload.rowsPerPage}`)
}

export async function getOperationalIntervalAPI(id) {
    return customAxiosBE.get(`/operationalInterval/${id}`)
}

