import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {useLocales} from "../../../locales";
import {useCallback, useEffect, useState} from "react";
import Iconify from "../../../components/Iconify";
import {getStatusFirebaseVpfr} from "../../../api/vpfr";
import {getDefaultPrinterLpfr} from "../../../api/lpfr";
import {searchTicketsApi} from "../../../api/tickets";
import {getCashierName, parsePaginationParams} from "../../../utils/other";
import {useSelector} from "../../../redux/store";
import {Invoice} from "../../../helper/invoice/Invoice";

export default function TestDialog({open, onClose}) {
    const [checkResults, setChecksResults] = useState({
        vpfr: null,
        printer: null,
        lastTicket: null
    });
    const {silentPrint, printerSize} = useSelector(state => state.settings);
    const {translate} = useLocales();

    const items = [
        {id: 1, value: "vpfr", label: translate("vpfrCheck")},
        {id: 2, value: "printer", label: translate("printerCheck")},
        {id: 3, value: "lastTicket", label: translate("lastTicketCheck")}
    ];

    const setLoading = useCallback((name) => {
        setChecksResults(prevState => ({...prevState, [name]: "loading"}));
    }, []);

    const setSuccess = useCallback((name) => {
        setChecksResults(prevState => ({...prevState, [name]: "success"}));
    }, []);

    const setError = useCallback((name) => {
        setChecksResults(prevState => ({...prevState, [name]: "error"}));
    }, []);

    const checkVpfrStatus = useCallback(async () => {
        setLoading("vpfr");
        try {
            await getStatusFirebaseVpfr();
            setSuccess("vpfr");
        } catch (e) {
            console.error("checkVpfrStatus", e)
            setError("vpfr");
        }
    }, [setLoading, setSuccess, setError]);

    const checkPrinterStatus = useCallback(async () => {
        setLoading("printer");
        try {
            await getDefaultPrinterLpfr();
            setSuccess("printer");
        } catch (e) {
            console.error("checkPrinterStatus", e)
            setError("printer");
        }
    }, [setLoading, setSuccess, setError]);

    const printLastTicket = useCallback(async () => {
        setLoading("lastTicket");
        try {
            const current = getCashierName();
            const parsedParams = parsePaginationParams({
                page: 0,
                rowsPerPage: 1,
                order: "desc"
            });
            const tickets = (await searchTicketsApi({cashier: current}, parsedParams)).data?.content;
            const invoice = new Invoice();
            invoice.setSilentPrint(silentPrint);
            invoice.setPrinterSize(printerSize);
            await invoice.printTickets(
                tickets.map(ticket => ({
                    ...ticket,
                    forQR: translate("testTicketText")
                })),
                [], {
                    carriersList: []
                }, null, null, translate("testTicketText"), null, null, null, null);
            setSuccess("lastTicket");
        } catch (e) {
            console.error("printLastTicket", e)
            setError("lastTicket");
        }
    }, [setLoading, setSuccess, setError, silentPrint, printerSize]);

    useEffect(() => {
        Promise.all([checkVpfrStatus(), checkPrinterStatus(), printLastTicket()]);
    }, [checkVpfrStatus, checkPrinterStatus, printLastTicket]);

    const getIconAndColor = (status) => {
        switch (status) {
            case "loading":
            case null:
                return {icon: "eos-icons:loading", color: "primary.main"};
            case "error":
                return {icon: "ooui:error", color: "error.main"};
            case "success":
                return {icon: "mdi:success-bold", color: "success.main"};
            default:
                return {icon: "eos-icons:loading", color: "primary.main"};
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{translate("systemCheck")}</DialogTitle>
            <DialogContent sx={{
                mt: 2
            }}>
                <Stack direction="column" spacing={2}>
                    {items.map(item => {
                        const forIcon = getIconAndColor(checkResults[item.value]);
                        return (
                            <Stack key={item.id} direction="row" spacing={2}>
                                <Typography>{item.label}</Typography>
                                <Iconify icon={forIcon.icon} sx={{color: forIcon.color}}/>
                            </Stack>
                        );
                    })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translate("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}
