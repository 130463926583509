import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../components/Iconify";
import B2BDialog from "./InvoiceForm/B2BDialog";
import {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {IconButtonAnimate} from "../../components/animate";

export default function B2B() {
    const {
        setValue, control, formState: {errors},
    } = useFormContext();

    const [openTo, setOpenTo] = useState(false);

    const selectedCustomer = useWatch({
        control, name: "customer"
    })

    const handleOpenTo = () => {
        setOpenTo(true);
    };

    const handleCloseTo = () => {
        setOpenTo(false);
    };

    const handleRemoveCustomer = () => {
        setValue('customer', "")
    }

    return (<Stack sx={{ml: 5}} direction="column" spacing={1}>
        <Stack direction="row"  spacing={1} justifyContent="space-between">
            {selectedCustomer !== "" ? (<B2BInfo customer={selectedCustomer}/>) : (
                <Typography typography="caption" sx={{color: 'error.main'}}>
                    {errors.invoiceTo?.message}
                </Typography>)}
            <Stack direction="row" alignItems="flex-start">
                <Stack direction="row" justifyContent="center">
                    <Button
                        size="small"
                        startIcon={<Iconify icon={'eva:plus-fill'}/>}
                        onClick={handleOpenTo}
                    >
                        {selectedCustomer !== "" ? "Promeni B2B" : "Izaberi B2B"}
                    </Button>
                    {selectedCustomer && <IconButtonAnimate
                        color={'error'}
                        onClick={handleRemoveCustomer}
                        sx={{
                            width: 45, height: 45,
                        }}
                    >
                        <Iconify icon="solar:trash-bin-minimalistic-bold-duotone"/>
                    </IconButtonAnimate>}
                </Stack>

            </Stack>
        </Stack>
        {openTo && <B2BDialog
            open={openTo}
            onClose={handleCloseTo}
        />}
    </Stack>)
}

// ----------------------------------------------------------------------

function B2BInfo({customer}) {
    return (<Stack spacing={1} alignItems="center" direction="row">
        <Typography variant="body2" sx={{color: 'text.disabled'}}>
            Podaci kupca:
        </Typography>
        <Typography variant="body2">Naziv: {customer?.name}</Typography>
        <Typography variant="body2">PIB: {customer?.tin}</Typography>
        {/*<Typography variant="body2">Adresa: {customer?.address}</Typography>*/}
        {/*<Typography variant="body2">Grad: {customer?.city}</Typography>*/}
        {/*<Typography variant="body2">Telefon: {customer?.telephone}</Typography>*/}
    </Stack>);
}
