// searchUtils.js
import axios from 'axios';

let cancelToken;

// KORISTIM ZA SVE SEARCH KOJIMA TREBA KASNJENJE TJ CANCELLATION TOKEN
export const debounceSearch = async (apiFunc, value) => {
    if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = axios.CancelToken.source();

    try {
        return await apiFunc(value, cancelToken.token);
    } catch (error) {
        console.error(error);
        throw error;
    }
};
