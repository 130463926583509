import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';

export const fetchcarriersList = createAsyncThunk('fetchcarriersList', async () => {

})

export const updateCarrier = createAsyncThunk("updateCarrier", async (data) => {

})

export const addCarrier = createAsyncThunk("addCarrier", async (data) => {

})

export const removeCarrier = createAsyncThunk("removeCarrier", async (data) => {

})

const initialState = {
    carriersList: [],
    foundCarriers: [],
    loadingCarriers: false,
    error: undefined,
}

const carriersSlice = createSlice({
    name: 'carrier',
    initialState,
    reducers: {
        resetIsSuccessAdded: (state) => {
            state.isSuccessAdded = false;
        },
        searchCarriersSlice: (state, action) => {

            // if (content) {
            //     state.foundCarriers = content;
            //     state.loadingCarriersCarriers = false
            // }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchcarriersList.pending, (state) => {
                state.loadingCarriers = true;
            })
            .addCase(fetchcarriersList.fulfilled, (state, {payload}) => {
                state.loadingCarriers = false;
                state.carriersList = payload;
            })
            .addCase(fetchcarriersList.rejected, (state, action) => {
                state.error = action.payload;
                state.carriersList = [];
                state.loadingCarriers = false;
            })
            .addCase(updateCarrier.pending, (state) => {
                state.loadingCarriers = true;
            })
            .addCase(updateCarrier.fulfilled, (state, action) => {
                state.loadingCarriers = false;
                state.error = undefined;
            })
            .addCase(updateCarrier.rejected, (state, action) => {
                state.error = action.payload;
                state.carriersList = [];
                state.loadingCarriers = false;
            })
            .addCase(addCarrier.pending, (state) => {
                state.loadingCarriers = true;
                state.isSuccessAdded = false;
            })
            .addCase(addCarrier.fulfilled, (state, action) => {
                const arr = [...current(state.carriersList)]
                arr.push(action.meta.arg)
                state.carriersList = arr
                state.loadingCarriers = false;
                state.isSuccessAdded = true;
            })
            .addCase(addCarrier.rejected, (state, action) => {
                state.error = action.payload;
                state.carriersList = [];
                state.loadingCarriers = false;
                state.isSuccessAdded = false;
            })
            .addCase(removeCarrier.pending, (state) => {
                state.loadingCarriers = true;
            })
            .addCase(removeCarrier.fulfilled, (state, {payload}) => {
                const arr = [...current(state.carriersList)]
                const index = arr.findIndex(i => i.uid === payload.uid)
                if (index !== -1) {
                    arr.splice(index, 1)
                    state.carriersList = arr
                }
                state.loadingCarriers = false;
            })
            .addCase(removeCarrier.rejected, (state, action) => {
                state.error = action.payload;
                state.carriersList = [];
                state.loadingCarriers = false;
            })
    }
});


export const {searchCarriersSlice} = carriersSlice.actions;
export default carriersSlice.reducer;
