import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {cancelReservationAPI, createReservationApi, searchTicketsApi, updateReservationAPI} from "../../api/tickets";
import moment from "moment";
import {parsePaginationParams} from "../../utils/other";

export const createReservation =
    createAsyncThunk("createReservation", async (payload) => {
        return (await createReservationApi(payload)).data
    })

export const searchReservations =
    createAsyncThunk('searchReservations', async (payload) => {
        const {filter, params} = payload;
        let salesTime = filter.salesTime;
        let journeyDate = filter.journeyDate;
        if (salesTime) {
            salesTime = moment(salesTime).format("yyyy-MM-DD")
        }
        if (journeyDate) {
            journeyDate = moment(journeyDate).format("yyyy-MM-DD")
        }
        const parsedParams = parsePaginationParams(params)
        return (await searchTicketsApi({
            ...filter,
            salesTime,
            journeyDate,
            isReservations: true,
        }, parsedParams)).data;
    })

export const updateReservation = createAsyncThunk("updateReservation",
    async (data)=>{
    return (await updateReservationAPI(data)).data
})

export const cancelReservation = createAsyncThunk("cancelReservation",
    async (data)=>{
    return (await cancelReservationAPI(data)).data
})

const initialState = {
    reservations: {
        content: [],
        totalElements: 0,
    },
    loading: false,
    submitting: false,
}

export const slice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        removeReservation:(state, {payload}) => {
            const currentState = current(state.reservations);
            const reservations = [...currentState.content];
            const index = reservations.findIndex(value => value.id === payload);
            reservations.splice(index, 1)
            state.reservations = {
                ...currentState,
                content: reservations,
                totalElements: reservations.length,
            };
        }
    },
    extraReducers: {
        [searchReservations.pending]: (state) => {
            state.loading = true;
        },
        [searchReservations.fulfilled]: (state, {payload}) => {
            state.reservations = payload;
            state.loading = false;
        },
        [searchReservations.rejected]: (state) => {
            state.loading = false;
        },
        [createReservation.pending]: (state) => {
            state.loading = true;
        },
        [createReservation.fulfilled]: (state) => {
            state.loading = false;
        },
        [createReservation.rejected]: (state) => {
            state.loading = false;
        },
        [updateReservation.pending]: (state) => {
            state.submitting = true;
        },
        [updateReservation.fulfilled]: (state, {payload}) => {
            const currentState = current(state.reservations);
            const reservations = [...currentState.content];
            const index = reservations.findIndex(value => value.id === payload.id);
            if(index !== -1){
                reservations[index] = payload;
            }
            state.reservations = {
                ...currentState,
                content: reservations
            };
            state.submitting = false;
        },
        [updateReservation.rejected]: (state) => {
            state.submitting = false;
        },
        [cancelReservation.pending]: (state) => {
            state.loading = true;
        },
        [cancelReservation.fulfilled]: (state, {meta}) => {
            const currentState = current(state.reservations);
            const reservations = [...currentState.content];
            const index = reservations.findIndex(value => value.id === meta.arg);
            reservations.splice(index, 1)
            state.reservations = {
                ...currentState,
                content: reservations
            };
            state.loading = false;
        },
        [cancelReservation.rejected]: (state) => {
            state.loading = false;
        },
    }
});

export const {removeReservation} = slice.actions;
export const reservationsReducer = slice.reducer
