import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  discount: undefined,
  savedInvoice: undefined,
  customer: undefined,
  selectedItem: -1
};
export const slice = createSlice({
  name: "createInvoice",
  initialState,
  reducers: {
    setCustomer: (state, { payload }) => {
      state.customer = payload;
    },
    removeItemFromCart: (state, { payload }) => {
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === payload.uid);
      if (payload === "last") {
        index = state.selectedItem;
      }
      arr.splice(index, 1);
      state.cart = arr;
    },
    changeSelectedItem: (state) => {
      let arr = [...current(state.cart)];
      const selectedItem = state.selectedItem;
      if (arr.length > 0) {
        if (arr.length - 1 > selectedItem) {
          state.selectedItem = selectedItem + 1;
        } else {
          state.selectedItem = 0;
        }
      }
    },

    resetCart: (state) => {
      state.cart = [];
      state.discount = undefined;
      state.customer = undefined;
      state.savedInvoice = undefined;
      state.selectedItem = -1;
    },
    setDiscountCart: (state, { payload }) => {
      let arr = [...current(state.cart)];
      if (payload) {
        arr = arr.map(item => {
          const discount = item.price * (payload.discountPCT / 100);
          const discountedPrice = item.price - discount;
          return {
            ...item,
            discountedPrice: discountedPrice
          };
        });
      } else {
        arr = arr.map(item => ({
          ...item,
          discountedPrice: undefined
        }));
      }
      state.cart = arr;
      state.discount = payload;
    },

    setCartItemQuantity: (state, { payload }) => {
      const { item, quantity } = payload;
      const arr = [...current(state.cart)];
      const index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (quantity > 0) {
        const discountState = state.discount;
        let discountedPrice;
        if (discountState) {
          const discount = item.price * (discountState.discountPCT / 100);
          discountedPrice = item.price - discount;
        }
        if (index !== -1) {
          arr[index] = {
            ...arr[index],
            addedQuantity: quantity,
            discountedPrice
          };
        } else {
          arr.push({ ...item, unitPrice: item.price, addedQuantity: quantity, discountedPrice });
        }
      } else {
        arr.splice(index, 1);
      }
      state.cart = arr;
    },
    addItemToCart: (state, { payload }) => {
      const { item, quantity } = payload;
      if (quantity < 1) {
        return;
      }
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (item === "last") {
        index = state.selectedItem;
        if (arr.length === 0 || index === -1) {
          return;
        }
      }
      const discountState = state.discount;
      let discountedPrice;
      if (discountState) {
        const discount = item.price * (discountState.discountPCT / 100);
        discountedPrice = Number(Number(item.price - discount).toFixed(2));
      }
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          addedQuantity: Number(Number(arr[index].addedQuantity + quantity).toFixed(4)),

        };
        if(discountedPrice){
          arr[index].discountedPrice = discountedPrice
        }
      } else {
        arr.unshift({
          category: item.category,
          name: item.name,
          vat: item.vat,
          uid: item.uid,
          unitPrice: item.price,
          price: item.price,
          addedQuantity: Number(quantity)});
      }
      state.cart = arr;
    },
    reduceItemFromCart: (state, { payload }) => {
      const { item, quantity } = payload;
      const arr = [...current(state.cart)];
      let index = arr.findIndex(lItem => lItem.uid === item.uid);
      if (item === "last") {
        index = state.selectedItem;
      }
      if (arr.length === 0) {
        index = -1;
      }
      if (index !== -1) {
        const newQty = arr[index].addedQuantity - (quantity || 0);
        if (newQty > 0) {
          arr[index] = {
            ...arr[index],
            addedQuantity: Number(Number(newQty).toFixed(4))
          };
        } else {
          arr.splice(index, 1);
          state.selectedItem = -1;
        }
      }
      state.cart = arr;
    }
  }
});

export const {
  removeItemFromCart,
  addItemToCart,
  changeSelectedItem,
  reduceItemFromCart,
  resetCart,
  setDiscountCart,
  setSavedInvoice,
  setCartItemQuantity,
  changeProductOnCart,
  setCustomer
} = slice.actions;
export default slice.reducer;

