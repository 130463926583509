export const INVOICE_TYPE = {
    normal: "Normal", //PROMET
    proforma: "Proforma", //PREDRAČUN
    copy: "Copy", //KOPIJA
    training: "Training", //OBUKA
    advance: "Advance", //AVANS
}

export const TRANSACTION_TYPE = {
    sale: "Sale", //PRODAJA
    refund: "Refund" //REFUNDACIJA
}

export const PAYMENT_TYPE = {
    other: "Other", //DRUGO BEZGOTOVINSKO PLACANJE
    cash: "Cash", // GOTOVINA
    card: "Card", //PLATNA KARTICA
    check: "Check", //ČEK
    wireTransfer: "WireTransfer", //PRENOS NA RAČUN
    voucher: "Voucher", // VAUČER
    mobileMoney: "MobileMoney" // INSTANT PLAĆANJE
}

export const ALL_LICENSE = {
    webESIR: 0,
    webLPFR: 1,
    eFacture: 2,
    restaurant: 3
}

export const ONE_DAY_SECONDS_TIMEOUT = 86400000;
export const ONE_MINUTE_SECONDS_TIMEOUT = 60000;

export const ESIR_NUMBER = "426/1.0"
// TODO IZMENITI ZA ZADNJA 2
export const OPERATORS_GROUP = {
    admin: 0,
    manager: 1,
    operator: 2,
    accountant: 3,
    driver: 4,
    superAdmin: 11,
    tripPreview: 12,
    dispatcher: 13,
    publisher: 14,
    informer: 15,
}

export const ROLES_CAN_ISSUE_TICKETS = [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.operator]
export const ALL_ROLES = Object.values(OPERATORS_GROUP)

export const REPORTS_ROLES = [OPERATORS_GROUP.admin, OPERATORS_GROUP.manager, OPERATORS_GROUP.accountant, OPERATORS_GROUP.driver]

export const LINE_TRAIFFIC_TYPES = [
    {id: 1, name: "Međugradski"},
    {id: 2, name: "Međumesni"},
    {id: 3, name: "Međunarodni"}
]

export const LINE_TYPE = {
    municipality: 0,
    intermunicipal: 1,
    international: 2,
    all: 3,
}

export const CUSTOMER_TYPES = [
    {
        value: 10,
        description: "Domaće pravno lice identifikovano PIB-om"
    },
    {
        value: 11,
        description: "Domaće fizičko lice koje obavlja samostalnu delatnost identifikovano JMBG-om"
    },
    {
        value: 12,
        description: "Domaće pravno lice koje je identifikovano PIB-om i JBKJS-om"
    },
    {
        value: 20,
        description: "Domaće fizičko lice identifikovano ličnom kartom"
    },
    {
        value: 21,
        description: "Domaće fizičko lice identifikovano izbegličkom legitimacijom"
    },
    {
        value: 22,
        description: "Strano fizičko lice koje ima prijavljeni boravak u Srbiji - EBS"
    },
    {
        value: 23,
        description: "Domaće fizičko lice identifikovano pasošem"
    },
    {
        value: 30,
        description: "Strano fizičko lice koje se identifikuje pasošem"
    },
    {
        value: 32,
        description: "Strano fizičko lice - koje se identifikuje ličnom kartom iz Makedonije"
    },
    {
        value: 33,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Crne Gore"
    },
    {
        value: 34,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Albanije"
    },
    {
        value: 35,
        description: "Strano fizičko lice lice - koje se identifikuje ličnom kartom iz Bosne i Hercegovine"
    },
    {
        value: 40,
        description: "Poreski identifikacioni broj izdat u inostranstvu"
    }
]

export const ITEM_UNIT_ONLY_STRINGS = ["kom", "l", "kg", "m2", "m3", "h", "tona", "par", "metar", "sum", "ostalo"];

export const STATUS_OPTIONS = ['SVI', 'AKTIVAN', 'PASIVAN'];

export const DIRECTION_NAME = ["ONE_WAY", "RETURN_TRIP", "MONTHLY"]

export function getDirectionIndex(direction) {
    return DIRECTION_NAME.indexOf(direction);
}
export const COUNTRY_BORDERS = [
    {id: 1, name: "MADJARSKA/MAĐARSKA", icon: "ic_hungary", borders: [
            "HORGOŠ",
            "KELEBIJA",
            "ĐALA",
            "BAČKI BREG",
            "BEZDAN",
            "BEZDAN",
            "BAČKI VINOGRADI",
            "SOT",
            "TOVARNIK",
            "ILOK",
            "BOGOJEVO",
            "APATIN",
            "BEZDAN"
        ]},
    {id: 2, name: "RUMUNIJA", icon: "ic_romania", borders: [
            "SRPSKA CRNJA",
            "VATIN",
            "ĐERDAP 1",
            "ĐERDAP 2",
            "KALUĐEREVO",
            "VRBICA",
            "JAŠA TOMIĆ",
            "NAKOVO"
        ]},
    {id: 3, name: "BUGARSKA", icon: "ic_bulgaria", borders: [
            "GRADINA",
            "RIBARCI",
            "STREZIMIROVCI",
            "MOKRANJE",
            "VRŠKA ČUKA"
        ]},
    {id: 4, name: "MAKEDONIJA", icon: "ic_macedonia", borders: [
            "PREŠEVO",
            "PROHOR PČINJSKI",
            "ĐENERAL JANKOVIĆ",
            "GLOBOČNICA",
            "GOLEŠ"
        ]},
    {id: 5, name: "ALBANIJA", icon: "ic_albania", borders: [
            "ĆAFA PRUŠIT",
            "VRBNICA",
        ]},
    {id: 6, name: "CRNA GORA", icon: "ic_monteNegro", borders: [
            "GOSTUN",
            "JABUKA",
            "ŠPILJANI"
        ]},
    {id: 7, name: "BOSNA I HERCEGOVINA", icon: "ic_bosnia", borders: [
            "BAJINA BAŠTA",
            "UVAC",
            "KOTROMAN",
            "LJUBOVIJA",
            "SREMSKA RAČA",
            "BADOVINCI",
            "TRBUŠNICA",
            "MALI ZVORNIK",
            "JAMENA",
        ]},
    {id: 8, name: "HRVATSKA", icon: "ic_croatia", borders: [
            "BATOVCI",
            "BEZDAN",
            "BOGOJEVO",
            "BAČKA PALANKA",
            "NEŠTIN",
            "ŠID",
            "SOT",
            "LJUBA"
        ]},
    {id: 9, name: "KOSOVO I METOHIJA", icon: "ic_kosovo", borders: [
            "BRNJAK",
            "JARINJE",
            "MERDARE",
            "MUTIVODE",
            "BELA ZEMLJA",
            "MUČIBABA"
        ]},
    {id: 10, name: "AUSTRIJA", icon: "ic_austria", borders: []},
    {id: 11, name: "NEMAČKA", icon: "ic_germany", borders: []},
]

export const SETTINGS_PAYMENTS = [
    {name: "cash", value: PAYMENT_TYPE.cash, icon: "mdi:cash", displayName: "Gotovina"},
    {
        name: "card",
        value: PAYMENT_TYPE.card,
        icon: "icon-park-solid:bank-transfer",
        displayName: "Kartica"
    },
    {name: "check", value: PAYMENT_TYPE.check, icon: "material-symbols:fact-check-outline", displayName: "Ček"},
    {
        name: "mobile",
        value: PAYMENT_TYPE.mobileMoney,
        icon: "mdi:mobile-phone-sound",
        displayName: "Mobilno"
    },
    {name: "voucher", value: PAYMENT_TYPE.voucher, icon: "mdi:voucher", displayName: "Vaučer"},
    {name: "virman", value: PAYMENT_TYPE.wireTransfer, icon: "mdi:credit-card-wireless-outline", displayName: "Virman"},
    {name: "other", value: PAYMENT_TYPE.other, icon: "basil:other-1-outline", displayName: "Ostalo"}
];

export const MONTHS_NAME = [
    { id: 1, name: "JANUAR", num: '01'},
    { id: 2, name: "FEBRUAR", num: '02'},
    { id: 3, name: "MART" ,num: '03'},
    { id: 4, name: "APRIL", num: '04'},
    { id: 5, name: "MAJ",num: '05'},
    { id: 6, name: "JUN",num: '06'},
    { id: 7, name: "JUL", num: '07'},
    { id: 8, name: "AVGUST", num: '08'},
    { id: 9, name: "SEPTEMBAR", num: '09'},
    { id: 10, name: "OKTOBAR", num: '10'},
    { id: 11, name: "NOVEMBAR", num: '11'},
    { id: 12, name: "DECEMBAR", num: '12'},
];
export const INPUT_WIDTH = 160

export const SALES_ACTION_TYPE = [
    {id: 1, value: false, name: "Relativna"},
    {id: 2, value: true, name: "Absolutna"}
];

export const TIMES = [
    {id: 1, value: "00:00"},
    {id: 2, value: "01:00"},
    {id: 3, value: "02:00"},
    {id: 4, value: "03:00"},
    {id: 5, value: "04:00"},
    {id: 6, value: "05:00"},
    {id: 7, value: "06:00"},
    {id: 8, value: "07:00"},
    {id: 9, value: "08:00"},
    {id: 10, value: "09:00"},
    {id: 11, value: "10:00"},
    {id: 12, value: "11:00"},
    {id: 13, value: "12:00"},
    {id: 14, value: "13:00"},
    {id: 15, value: "14:00"},
    {id: 16, value: "15:00"},
    {id: 17, value: "16:00"},
    {id: 18, value: "17:00"},
    {id: 19, value: "18:00"},
    {id: 20, value: "19:00"},
    {id: 21, value: "20:00"},
    {id: 22, value: "21:00"},
    {id: 23, value: "22:00"},
    {id: 24, value: "23:00"}
];

export const DEPARTURE_TYPE = [
    {id: 1, value: 1, name: "Redovan"},
    {id: 2, value: 2, name: "Vanredan"}
];


export const SERBIA_ALPHA_2_CODE = "RS"

export const REFUND_TYPE = {
    normal: 0,
    vgp: 1,
    vzp: 2
}
