import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllOperationalIntervalAPI} from "../../api/operationalInterval";

export const fetchAllOperationIntervals = createAsyncThunk('fetchAllOperationIntervals', async (data) => {
    return getAllOperationalIntervalAPI(data);
})


const initialState = {
    allOperationIntervals: [],
    loadingOperationIntervals: false,
}

export const operationIntervalSlice = createSlice({
    name: 'operationIntervals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOperationIntervals.pending, (state) => {
                state.loadingOperationIntervals = true;
            })
            .addCase(fetchAllOperationIntervals.fulfilled, (state, {payload}) => {
                state.allOperationIntervals = payload.data.content;
                state.loadingOperationIntervals = false;
            })
            .addCase(fetchAllOperationIntervals.rejected, (state) => {
                state.loadingOperationIntervals = false;
            })
    }
});

export const operationIntervalReducer = operationIntervalSlice.reducer
