import React, {useState} from "react";
import {Box, Chip, Collapse, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {dispatch} from "../../redux/store";
import {getAllLineStationsByLineIds} from "../../redux/slices/lineStations";
import Iconify from "../../components/Iconify";
import moment from "moment";

export default function DepartureFromSpotTableRow({row, openRowId, setOpenRowId, index}) {
    const {lineName, operatorName, departureTime,arrivalTime, printText, lineId, canceledNote} = row;
    const [extraData, setExtraData] = useState(null);

    const isOpen = openRowId === index;

    const handleExpandClick = async () => {
        if (isOpen) {
            setOpenRowId(null);
        } else {
            setOpenRowId(index);
            if (!extraData) {
                try {
                    dispatch(getAllLineStationsByLineIds([lineId])).then(({payload}) => {
                        if (payload?.data) {
                            const arr = extractStationNames(payload.data)
                            setExtraData(arr);
                        }
                    });
                } catch (error) {
                    console.error("Failed to fetch extra data", error);
                }
            }
        }
    };

    return (
        <>
            <TableRow hover>
                <TableCell align="left">{printText || lineName}</TableCell>
                <TableCell align="left">{operatorName}</TableCell>
                <TableCell align="left">
                    {arrivalTime ? moment.utc(arrivalTime).format("HH:mm") : "/"}
                </TableCell>
                <TableCell align="left">
                    {departureTime ? moment.utc(departureTime).format("HH:mm") : "/"}
                </TableCell>
                <TableCell>
                    <Typography textAlign="center" variant="subtitle2"
                                color={canceledNote ? "darkred" : null}>{canceledNote ? canceledNote : "/"}</Typography>
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleExpandClick}>
                        {isOpen ? <Iconify icon="eva:arrow-ios-upward-fill"/> :
                            <Iconify icon="eva:arrow-ios-downward-fill"/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={4}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {extraData ? (
                                <div>
                                    {extraData.map((data, index) => (
                                        <Chip
                                            key={index}
                                            label={data}
                                            color="primary"
                                            variant="outlined"
                                            style={{ margin: '4px' }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p>Trenutak...</p>
                            )}

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function extractStationNames(dataArray) {
    return dataArray.map(item => item.station.name);
}

