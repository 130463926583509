import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    changeStatusDiscountApi,
    deleteDiscountApi,
    findTicketTypeBySearchDataAPI,
    getAllTicketTypeAPI,
    getDiscountForSaleAPI,
    insertDiscountAPI,
    searchDiscountByTripIdAPI,
    searchTicketTypeAPI,
    searchTicketTypeByOperatorIdAPI,
    updateDiscountAPI
} from "../../api/ticketType/ticketType";
import {parsePaginationParams} from "../../utils/other";

export const findTicketTypeBySearchData = createAsyncThunk('findTicketTypeBySearchData', async (data) => {
    return await findTicketTypeBySearchDataAPI(data)
})

export const getAllTicketType =
    createAsyncThunk('getAllTicketType', async (params) => {
        const parsedParams = parsePaginationParams(params)
        return (await getAllTicketTypeAPI(parsedParams)).data
    })

export const deleteDiscountById = createAsyncThunk('deleteDiscountById', async (payload) => {
    return deleteDiscountApi(payload);
})

export const changeStatusDiscount = createAsyncThunk('activateDiscountById', async (payload) => {
    return changeStatusDiscountApi(payload);
})

export const postDiscount = createAsyncThunk('postTicketType', async (payload) => {
    return insertDiscountAPI(payload);
})

export const updateDiscount = createAsyncThunk('updateDiscount', async (payload) => {
    return updateDiscountAPI(payload)
})

export const searchDiscount =
    createAsyncThunk('searchDiscount', async (payload) => {
        return (await searchTicketTypeAPI(payload)).data
    })

export const searchDiscountByProvidedOperatorId =
    createAsyncThunk('searchDiscountByProvidedOperatorId', async (operatorId) => {
        return (await searchTicketTypeByOperatorIdAPI(operatorId)).data
    })

export const searchDiscountByTripId =
    createAsyncThunk('searchDiscountByTripId', async (data) => {
        return (await searchDiscountByTripIdAPI(data)).data
    })

export const getDiscountForSale =
    createAsyncThunk('getDiscountForSale', async (data) => {
        return (await getDiscountForSaleAPI(data)).data
    })


const initialState = {
    searchResult: {
        content: [],
    },
    discountList: [],
    foundDiscounts: [],
    loadingDiscounts: false,
}

export const discountSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        setSearchParam: (state, action) => {
            state.searchParam = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findTicketTypeBySearchData.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(findTicketTypeBySearchData.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundDiscounts = payload.data;
                }
                state.loadingDiscounts = false;
            })
            .addCase(findTicketTypeBySearchData.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllTicketType.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(getAllTicketType.fulfilled, (state, {payload}) => {
                state.foundDiscounts = payload;
                state.discountList = payload;
                state.loadingDiscounts = false;
            })
            .addCase(getAllTicketType.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(searchDiscount.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscount.fulfilled, (state, {payload}) => {
                state.searchResult = payload;
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscount.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByProvidedOperatorId.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscountByProvidedOperatorId.fulfilled, (state, {payload}) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByProvidedOperatorId.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByTripId.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(searchDiscountByTripId.fulfilled, (state, {payload}) => {
                state.loadingDiscounts = false;
            })
            .addCase(searchDiscountByTripId.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(deleteDiscountById.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(deleteDiscountById.fulfilled, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(deleteDiscountById.rejected, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(changeStatusDiscount.pending, (state) => {
                state.loadingDiscounts = true;
            })
            .addCase(changeStatusDiscount.fulfilled, (state) => {
                state.loadingDiscounts = false;
            })
            .addCase(changeStatusDiscount.rejected, (state) => {
                state.loadingDiscounts = false;
            })
    }
});

export const discountReducer = discountSlice.reducer
