import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {deleteHolidaysByIdAPI, getAllHolidaysAPI, insertHolidaysAPI, updateHolidaysAPI} from "../../api/holidays";

export const fetchAllHolidays = createAsyncThunk('fetchAllHolidays', async (payload) => {
    return await getAllHolidaysAPI(payload)
})

export const deleteHolidaysById = createAsyncThunk('deleteHolidaysById', async (id) => {
    return deleteHolidaysByIdAPI(id);
})

export const postHolidays = createAsyncThunk('postHolidays', async (payload, thunkAPI) => {
    try {
        return await insertHolidaysAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

export const updateHolidays = createAsyncThunk('updateHolidays', async (payload, thunkAPI) => {
    try {
        return await updateHolidaysAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

const initialState = {
    holidays: [],
    foundHolidays: [],
    loadingHolidays: false,
}

export const holidaysSlice = createSlice({
    name: 'holidays',
    initialState,
    reducers: {
        specialLoading: (state, {payload}) => {
            state.loadingHolidays = payload
        },
        searchRateCardSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundHolidays = payload;
                state.loadingHolidays = false
            }
        }
    },
    extraReducers: {
        [fetchAllHolidays.pending]: (state) => {
            state.loadingHolidays = true;
        },
        [fetchAllHolidays.fulfilled]: (state, {payload}) => {
            state.loadingHolidays = false;
            state.holidays = payload.data;
            state.foundHolidays = payload.data;
        },
        [fetchAllHolidays.rejected]: (state) => {
            state.loadingHolidays = false;
        },
        [postHolidays.pending]: (state) => {
            state.loadingHolidays = true;
        },
        [postHolidays.fulfilled]: (state) => {
            state.loadingHolidays = false;
        },
        [postHolidays.rejected]: (state) => {
            state.loadingHolidays = false;
        },
        [updateHolidays.pending]: (state) => {
            state.loadingHolidays = true;
        },
        [updateHolidays.fulfilled]: (state) => {
            state.loadingHolidays = false;
        },
        [updateHolidays.rejected]: (state) => {
            state.loadingHolidays = false;
        },
        [deleteHolidaysById.pending]: (state) => {
            state.loadingHolidays = true;
        },
        [deleteHolidaysById.fulfilled]: (state) => {
            state.loadingHolidays = false;
            // let index = getIndexForService(arr, action.meta.arg)
            // if (index !== -1) {
            //     arr.splice(index, 1)
            // }
            // state.holidays = state.holidays
        },
        [deleteHolidaysById.rejected]: (state) => {
            state.loadingHolidays = false;
        }
    }
});
export const {specialLoading} = holidaysSlice.actions;
export const holidaysReducer = holidaysSlice.reducer
