import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    activateLineApi, checkIfStationExistOnLineAPI,
    deleteLineApi,
    getAllLinesAPI,
    getLineByIdAPI, getStationIdByLineIdAPI,
    insertLineByIdAPI,
    searchLineAPI,
    updateBySubOperatorByIdAPI,
    updateLineByIdAPI
} from "../../api/lines";
import {insertInstantAllotmentAPI} from "../../api/alltoments";

export const fetchAllLines = createAsyncThunk('fetchAllLines', async (payload) => {
    return await getAllLinesAPI(payload)
})

export const getLineByFilter =
    createAsyncThunk('getLineByFilter', async (payload) => {
        return await searchLineAPI(payload)
    })

export const getLineById = createAsyncThunk('getLineById', async (payload) => {
    return await getLineByIdAPI(payload)
})

export const getStationIdByLineId = createAsyncThunk('getStationIdByLineId', async (payload) => {
    return await checkIfStationExistOnLineAPI(payload)
    //return await getStationIdByLineIdAPI(payload)
})

export const activateLineById = createAsyncThunk('activateLineById', async (payload) => {
    return (await activateLineApi(payload)).data
})

export const deleteLineById = createAsyncThunk('deleteLineById', async (payload) => {
    return (await deleteLineApi(payload)).data
})

export const updateLineById = createAsyncThunk('updateLineById', async (payload) => {
    return await updateLineByIdAPI(payload)
})
export const updateBySubOperatorById = createAsyncThunk('updateBySubOperatorById', async (payload,thunkAPI) => {
    try {
        return await updateBySubOperatorByIdAPI(payload);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.data ? error.data : error);
    }
})

export const insertLine = createAsyncThunk('insertLine', async (payload) => {
    return await insertLineByIdAPI(payload)
})


const initialState = {
    allLines: [],
    foundLines: [],
    linesData: {},
    allLinesBackend: [],
    insertedLine: null,
    // singleLine: null,
    loadingAllLines: false,
    lineError: null,
    searchParam: ""
}

export const linesSlice = createSlice({
    name: 'lineAutocomplete',
    initialState,
    reducers: {
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        }
    },
    extraReducers: {
        [fetchAllLines.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [fetchAllLines.fulfilled]: (state, {payload}) => {
            if (payload.data) {
                state.allLines = payload.data;
                state.foundLines = payload.data;
            }
            state.loadingAllLines = false;
        },
        [getLineById.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [getLineById.fulfilled]: (state, action) => {
            state.loadingAllLines = false;
            state.linesData[action.meta.arg] = action.payload;
        },
        [getStationIdByLineId.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [getStationIdByLineId.fulfilled]: (state, action) => {
            state.loadingAllLines = false;
        },
        [getStationIdByLineId.rejected]: (state) => {
            state.loadingAllLines = false;
        },
        [updateLineById.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [updateLineById.fulfilled]: (state) => {
            state.loadingAllLines = false;
        },
        [updateLineById.rejected]: (state) => {
            state.loadingAllLines = false;
        },
        [updateBySubOperatorById.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [updateBySubOperatorById.fulfilled]: (state) => {
            state.loadingAllLines = false;
        },
        [updateBySubOperatorById.rejected]: (state) => {
            state.loadingAllLines = false;
        },
        [insertLine.pending]: (state) => {
            state.loadingAllLines = true;
        },
        [insertLine.fulfilled]: (state) => {
            state.loadingAllLines = false;
        },
        [insertLine.rejected]: (state, action) => {
            state.loadingAllLines = false;
            state.lineError = action.arg.msg
        }
    }
});

export const linesReducer = linesSlice.reducer

