import {AUTH} from "../auth/FirebaseContext";
import {getSessionSelectedLocation} from "../helper/session";
import axios from "axios";
import {getStatusLpfr} from "./lpfr";
import {BUS_SOFT_FUNCTIONS_API} from "../config";

const customAxios = (token, pac) => axios.create({
    baseURL: BUS_SOFT_FUNCTIONS_API,
    headers: {
        'client_os': 'web',
        'client_app': 'busSoft-new',
        'client_version': '1',
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "PAC": pac
    },
    withCredentials: false
});

export async function getClientInfo(tin) {
    try {
        const token = await AUTH.currentUser.getIdToken(true);
        const PIB = tin?.tin || tin;

        if (PIB !== undefined && typeof PIB !== 'object' && PIB.length > 8 && PIB.length < 14) {
            const response = await customAxios(token).get(`/esir/getCompanyDetails?TIN=${PIB}`);

            if (response.status === 200) {
                return response.data;
            } else {
                console.error(`Error: Received status code ${response.status}`);
                return null;
            }
        }

        return null;
    } catch (error) {
        if (error.response) {
            console.error(`API Error: ${error.response.status} - ${error.response.statusText}`);
            if (error.response.status === 404) {
                return null;
            }
        } else if (error.request) {
            console.error('No response received from the server.');
        } else {
            console.error('Error in request setup:', error.message);
        }
        return null;
    }
}


export async function getNewReports(from, to) {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    return customAxios(token, pac).get(`/esir/report?from=${from}&to=${to}`)
}

export async function getTaxCoreStatus(isAlreadyPosted = false) {
    return new Promise(async (resolve) => {
        await getStatusFirebaseVpfr().then(responseVpfr => {
            return resolve({
                response: responseVpfr.data,
                type: "vpfr"
            });
        }).catch(async errorVpfr => {
            if (!isAlreadyPosted) {
                await loginOnError403(errorVpfr).then(_ => {
                    getTaxCoreStatus(true).then(r => {
                        return resolve({
                            response: r.response,
                            type: "vpfr"
                        });
                    });
                });
            } else {
                getStatusLpfr()
                    .then(data => {
                        console.log("Received data:", data);
                    })
                    .catch(error => {
                        //console.error("Error handling in calling component:", error);
                    });
            }
        });
    });
}

export async function getStatusFirebaseVpfr() {
    const token = await AUTH.currentUser.getIdToken(true);
    const pac = getSessionSelectedLocation().PAC;
    return customAxios(token, pac).get("esir/api/v3/status");
}

export async function loginOnError403(e) {

    if (e?.response?.status === 403) {
        try {
            // const bytes = CryptoJS.AES.decrypt(getLoggedUser(), 'esiresir');
            // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //await signInWithEmailAndPassword(AUTH, AUTH.currentUser.email, AUTH.password);
        } catch {
            console.error("nije proslo logovanje");
        }
    }
}
