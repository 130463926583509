import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {child, get, set} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";


export const fetchAllUserMessages = createAsyncThunk('fetchAllUserMessages', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox`))).forEach(child => {
        let childValue = child.val();
        arr.push({
            uid: child.key,
            ...childValue
        });
    });
    return arr;
})

export const setNotificationAsRead = createAsyncThunk('setNotificationAsRead', async (data) => {
    return await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${data.tripId}`), {
        ...data,
        isRead: true
    })
})

export const createNotification = createAsyncThunk('createNotification', async (data) => {
    const {userUid, ...obj} = data
    return await set(child(dbRef, `users/${userUid}/private/messages/inbox/${data.tripId}`), obj)
})

export const setAllNotificationAsRead = createAsyncThunk('setAllNotificationAsRead', async (data) => {
    ;
    const updatePromises = data.map(async (msg) => {
        await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/messages/inbox/${msg.uid}`), msg);
    });
    await Promise.all(updatePromises);
    return data;
})

const initialState = {
    messages: [],
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index === -1) {
                    arr.push(payload)
                    state.messages = arr;
                }
            }
        },
        changeMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr[index] = payload;
                    state.messages = arr;
                }
            }
        },
        deleteMessage: (state, {payload}) => {
            if (payload) {
                let arr = [...current(state).messages]
                let index = arr.findIndex((message) => message.uid === payload.uid)
                if (index !== -1) {
                    arr.splice(index, 1)
                    state.messages = arr;
                }
            }
        }
    },
    extraReducers: {
        [fetchAllUserMessages.pending]: (state) => {
            state.loading = true
        },
        [fetchAllUserMessages.fulfilled]: (state, {payload}) => {
            state.messages = payload
            state.loading = false
        },
        [fetchAllUserMessages.rejected]: (state) => {
            state.loading = false
        },
        [setNotificationAsRead.fulfilled]: (state, {meta}) => {
            let newData = {
                ...meta.arg,
                isRead: true
            };
            let arr = [...current(state).messages]
            let index = arr.findIndex((notification) => notification.uid === newData.uid)
            if (index !== -1) {
                arr[index] = newData;
            }
            state.messages = arr;
        },
        [setAllNotificationAsRead.pending]: (state) => {
            state.loading = true
        },
        [setAllNotificationAsRead.fulfilled]: (state, {payload}) => {
            state.messages = payload;
            state.loading = false
        },
        [setAllNotificationAsRead.rejected]: (state) => {
            state.loading = false
        }
    }
});
export const notificationsReducer = notificationsSlice.reducer

