import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {child, get, set} from "firebase/database";

export async function fetchDefaultItems(itemsUID = []) {
    const arr = [];
    for (const uid of itemsUID) {
        const item = (await get(child(dbRef, `/users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
        if(item && item.vat ){
            arr.push({
                ...item,
                uid,
            })
        }
    }
    return arr;
}

export async function updateImagePathFavoriteDestination(data) {
    return await set(child(dbRef, `/users/${AUTH.currentUser.uid}/private/settings/bussoft/web/favoriteDestinations`), data)
}
