import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {getAllRegionsAPI, insertRegionAPI} from "../../api/regions";

export const fetchAllRegions =
    createAsyncThunk('fetchAllRegions', async (data) => {
        return getAllRegionsAPI(data);
    })

export const insertRegion = createAsyncThunk('insertRegion', async (payload) => {
    return insertRegionAPI(payload);
})


const initialState = {
    allRegions: [],
    loadingRegions: false,
    searchParam: ""
}

export const regionsSlice = createSlice({
    name: 'regions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRegions.pending, (state) => {
                state.loadingRegions = true;
            })
            .addCase(fetchAllRegions.fulfilled, (state, {payload}) => {
                state.allRegions = payload.data.content;
                state.loadingRegions = false;
            })
            .addCase(fetchAllRegions.rejected, (state) => {
                state.loadingRegions = false;
            })
            .addCase(insertRegion.pending, (state) => {
                state.loadingRegions = false;
            })
            .addCase(insertRegion.fulfilled, (state, {payload}) => {
                const arr = [...current(state.allRegions)];
                arr.push(payload.data);
                state.allRegions = arr;
                state.loadingRegions = false;
            })
            .addCase(insertRegion.rejected, (state) => {
                state.loadingRegions = false;
            });
    },
});

export const regionsReducer = regionsSlice.reducer
