import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import lineTypeReducer from "./slices/lineType";
import actionsReducer from "./slices/actions";
import webSocketReducer from "./slices/websocket";
import transportationTypesReducer from "./slices/salesActionType";
import disabilityReducer from "./slices/disability";
import tripViewReducer from "./slices/tripView";
import {groupsPublicReducer} from "./slices/groupsPublic";
import {operatorsReducer} from "./slices/operators";
import settingsReducer from "./slices/settings";
import userSettingsReducer from "./slices/userSettings";
import locationsReducer from "./slices/locations";
import platformsReducer from "./slices/platforms";
import synchronizationsReducer from "./slices/synchronization";
import springReportsReducer from "./slices/springReports";
import cameraReducer from "./slices/camera";
import {licenseReducer} from "./slices/license";
import {notificationsReducer} from './slices/notifications';
import taxCoreReducer from "./slices/taxCore";
import {discountReducer} from "./slices/discounts";
import busesReducer from "./slices/buses";
import allotmentsReducer from "./slices/allotment";
import {rateCardsReducer} from "./slices/rateCards";
import {ratesReducer} from "./slices/rate";
import customersReducer from "./slices/customers";
import carriersReducer from "./slices/carriers";
import {lineStationReducer} from "./slices/lineStations";
import {stationLocationsReducer} from "./slices/stationLocations";
import {linesReducer} from "./slices/lines";
import {departureReducer} from "./slices/departures";
import {RFIDReducer} from "./slices/rfid";
import {servicesReducer} from "./slices/services";
import {itemsReducer} from "./slices/items";
import {stationsReducer} from "./slices/stations";
import {ticketsReducer} from "./slices/tickets";
import countriesReducer from "./slices/countries";
import {regionsReducer} from "./slices/regions";
import {operationIntervalReducer} from "./slices/operationIntervals";
import {esirInvoicesReducer} from "./slices/esirInvoices";
import {logoReducer} from "./slices/userLogo";
import {newReportsReducer} from "./slices/newReport";
import {reportsReducer} from "./slices/reports";
import {invoicesReducer} from "./slices/invoices";
import {persistReducer} from "redux-persist";
import createInvoiceReducer from "./slices/createInvoice";
import {operatorsBEReducer} from "./slices/operatorsBe";
import {reservationsReducer} from "./slices/reservations";
import {trafficLogsReducer} from "./slices/trafficLog";
import {holidaysReducer} from "./slices/holidays";
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: ['createInvoice', 'camera']
};


const createInvoicePersistConfig = {
    key: "createInvoice",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

const cameraPersistConfig = {
    key: "camera",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

const rootReducer = combineReducers({
    webSocket: webSocketReducer,
    synchronizations: synchronizationsReducer,
    actions: actionsReducer,
    lineType: lineTypeReducer,
    tripView: tripViewReducer,
    salesActionType: transportationTypesReducer,
    notifications: notificationsReducer,
    taxCore: taxCoreReducer,
    settings: settingsReducer,
    trafficLogs: trafficLogsReducer,
    userSettings: userSettingsReducer,
    operators: operatorsReducer,
    locations: locationsReducer,
    groupsPublic: groupsPublicReducer,
    user: userReducer,
    license: licenseReducer,
    discounts: discountReducer,
    disability: disabilityReducer,
    bus: busesReducer,
    allotment: allotmentsReducer,
    rateCards: rateCardsReducer,
    holidays: holidaysReducer,
    rates: ratesReducer,
    customers: customersReducer,
    lineStations: lineStationReducer,
    stationLocations: stationLocationsReducer,
    lines: linesReducer,
    departures: departureReducer,
    RFID: RFIDReducer,
    services: servicesReducer,
    items: itemsReducer,
    stations: stationsReducer,
    tickets: ticketsReducer,
    countries: countriesReducer,
    regions: regionsReducer,
    operationIntervals: operationIntervalReducer,
    esirInvoices: esirInvoicesReducer,
    logo: logoReducer,
    newReports: newReportsReducer,
    reports: reportsReducer,
    invoices: invoicesReducer,
    operatorsBE: operatorsBEReducer,
    carriers: carriersReducer,
    reservations: reservationsReducer,
    platforms: platformsReducer,
    springReports: springReportsReducer,
    createInvoice: persistReducer(createInvoicePersistConfig, createInvoiceReducer),
    camera: persistReducer(cameraPersistConfig, cameraReducer),
});

export {rootPersistConfig,rootReducer};
