import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";

export async function insertTrafficLogAPI(payload) {
    return customAxiosBE.post(`/trafficLog`, payload)
}

export async function insertOtherTrafficLogAPI(payload) {
    return customAxiosBE.post(`/trafficLog/other`, payload)
}

export async function updateOtherTrafficLogAPI(payload) {
    return customAxiosBE.put(`/trafficLog/other`, payload)
}

export async function insertOrUpdateTrafficLogAPI(payload) {
    return customAxiosBE.post(`/trafficLog/insertOrUpdate`, payload)
}

export async function updateTrafficLogAPI(payload) {
    return customAxiosBE.put(`/trafficLog`, payload)
}

export async function searchTripWithTrafficLogApi(data) {
    return customAxiosBE.post(`/trafficLog/getAllDepAndArr`, data)
}

export async function setIssuedTrafficLogAPI(id) {
    return customAxiosBE.patch(`/trafficLog/issued/${id}`)
}

export async function searchTrafficLogsApi(data) {
    return customAxiosBE.post(`/trafficLog/search`, data)
}

export async function searchOtherTrafficLogsApi(data) {
    const parsedParams = parsePaginationParams(data)
    return customAxiosBE.get(`/trafficLog/other?${parsedParams}${data.search}`)
}

export async function finishTrafficLogParkingApi(data) {
    return customAxiosBE.post(`/trafficLog/finish-parking`, data)
}

export async function startTrafficLogParkingApi(data) {
    return customAxiosBE.post(`/trafficLog/start-parking`, data)
}

export async function getTrafficLogsForFiscalisationAPI(){
    return customAxiosBE.get(`/trafficLog/fiscalization`)
}
