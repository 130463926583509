import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllLineTypeAPI} from "../../api/lineType";

export const fetchAllLineType = createAsyncThunk('fetchAllLineType', async (data) => {
    return await getAllLineTypeAPI(data)
})

const initialState = {
    allLineType: [],
    foundLineType: [],
    loadingLineType: false,
}

export const lineTypeSlice = createSlice({
    name: 'lineType', initialState,
    reducers: {

    },
    extraReducers: {
        [fetchAllLineType.pending]: (state) => {
            state.loadingLineType = true;
        },
        [fetchAllLineType.fulfilled]: (state, {payload}) => {
            state.allLineType = payload.data;
            state.foundLineType = payload.data;
            state.loadingLineType = false;
        },
        [fetchAllLineType.rejected]: (state) => {
            state.loadingLineType = false;
        }
    }
});

export default lineTypeSlice.reducer

