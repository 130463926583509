import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import Login from '../pages/auth/LoginPage';
import OperatorLogin from '../pages/auth/OperatorLoginPage';
import {useAuthContext} from './useAuthContext';
import {getIniLoggedUser, getSessionOperator} from "../helper/session";
import LoadingScreen from "../components/loading-screen";
import DeparturePreview from "../pages/DeparturePreview";
import {OPERATORS_GROUP} from "../constants";
import {AUTH} from "./FirebaseContext";
import {navConfig} from "../layouts/dashboard/nav/config";
import Bus from "../pages/OperatorPreview/PreviewDriveParkList";
import DashboardLayout from "../layouts/dashboard";
import {PATH_DASHBOARD} from "../routes/path";
import {OtpravnikList} from "../routes/elements";

// ----------------------------------------------------------------------

const AuthStatus = {
    AUTHENTICATED: 'AUTHENTICATED',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    AUTHENTICATED_AS_OPERATOR: 'AUTHENTICATED_AS_OPERATOR',
    AUTHENTICATED_AS_TRIP_PREVIEW: 'AUTHENTICATED_AS_TRIP_PREVIEW',
    AUTHENTICATED_AS_DISPATCHER: 'AUTHENTICATED_AS_DISPATCHER',
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const operator = getSessionOperator();
    const {isAuthenticated, isInitialized} = useAuthContext();
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);
    const primaryUser = getIniLoggedUser();
    const isPrimary = primaryUser?.uid === AUTH?.currentUser?.uid
    const navigate = useNavigate();
    if (!isInitialized) {
        return <LoadingScreen/>;
    }

    const determineAuthStatus = () => {
        if (!isAuthenticated) {
            return AuthStatus.NOT_AUTHENTICATED;
        }
        if (!operator) {
            return AuthStatus.AUTHENTICATED_AS_OPERATOR;
        }
        if (isAuthenticated && operator && operator?.group === OPERATORS_GROUP.tripPreview) {
            return AuthStatus.AUTHENTICATED_AS_TRIP_PREVIEW;
        }
        if (isAuthenticated && operator && operator?.group === OPERATORS_GROUP.dispatcher) {
            return AuthStatus.AUTHENTICATED_AS_DISPATCHER;
        }
        return AuthStatus.AUTHENTICATED;
    };

    const authStatus = determineAuthStatus();

    if (authStatus === AuthStatus.NOT_AUTHENTICATED) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login/>;
    }

    if (authStatus === AuthStatus.AUTHENTICATED_AS_OPERATOR) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <OperatorLogin/>;
    }

    if (authStatus === AuthStatus.AUTHENTICATED_AS_TRIP_PREVIEW) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <DeparturePreview/>;
    }


    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    // const needPrimaryUser = findNeedPrimaryUser(pathname, navConfig);
    // if (needPrimaryUser !== isPrimary) {
    //     return <DashboardLayout>
    //         <Bus/>
    //     </DashboardLayout>;
    // }
    return <>{children}</>;
}

function findNeedPrimaryUser(pathname, navConfig) {
    for (const item of navConfig) {
        for (const child of item.items) {
            if (pathname.includes(child.path)) {
                return child.needPrimaryUser;
            }
        }
    }
    return null;
}
