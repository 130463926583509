import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";

export function getRegionByIdAPI(id) {
    return customAxiosBE.get(`/region/${id}`)
}

export function getAllRegionsAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/region?${parsedParams}`)
}

export function insertRegionAPI(data) {
    return customAxiosBE.post(`/region`, data)
}

export function updateRegionAPI(data) {
    return customAxiosBE.put(`/region`, data)
}

export function deleteRegionAPI(id) {
    return customAxiosBE.delete(`/region/${id}`)
}
