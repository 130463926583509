import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {child, get, set} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {fetchDefaultItems} from "../../helper/realtimeDB";
import {getSessionSelectedLocation} from "../../helper/session";

export const setDefaultUserSettings =
    createAsyncThunk("setDefaultUserSettings", async () => {
        let settings = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/bussoft/web`))).val();
        const location = getSessionSelectedLocation()?.PAC;
        if (settings?.defaultOutboundStations?.[0]) {
            localStorage.setItem("defaultStation", JSON.stringify(settings?.defaultOutboundStations?.[0]));
        }
        let services = [];
        let defaultOutboundStations = [];
        let forbiddenPages = settings.forbiddenPages || [];
        if (!location) {
            return {
                services,
                defaultOutboundStations,
                forbiddenPages
            }
        }
        if (settings?.defaultItems?.[location]) {
            services = await fetchDefaultItems(settings?.defaultItems?.[location])
        }
        return {
            ...settings,
            forbiddenPages,
            services,
            issuingTicket: !!settings.issuingTicket,
            roundedPrice: !!settings.roundedPrice,
            advertisementTextTicket: settings?.advertisementTextTicket || ""
        }
    });

export const addDefaultItemOnLocations =
    createAsyncThunk("addDefaultItemOnLocations", async (data) => {
        const {uid, locations} = data;
        let defaultItems = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/bussoft/web/defaultItems`))).val();
        const locationItems = defaultItems || {};

        for (const location of locations) {
            const current = (locationItems[location.id] || []);
            if (current.indexOf(uid) === -1) {
                locationItems[location.id] = [...current, uid];
            }

        }
        await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/bussoft/web/defaultItems`), locationItems);
        const location = getSessionSelectedLocation()?.PAC;
        return await fetchDefaultItems(locationItems?.[location]);
    })

export const removeDefaultItemOnLocations =
    createAsyncThunk("removeDefaultItemOnLocations", async (data) => {
        const {uid, locations} = data;
        let defaultItems = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/bussoft/web/defaultItems`))).val();
        const locationItems = defaultItems || {};
        if (locations.length > 0) {
            for (const location of locations) {
                const current = (locationItems[location.id] || []);
                const index = current.indexOf(uid);
                if (index !== -1) {
                    locationItems[location.id] = current.splice(index, 1);
                }

            }
            await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/settings/bussoft/web/defaultItems`), locationItems);
        }
        const location = getSessionSelectedLocation()?.PAC;
        return await fetchDefaultItems(locationItems?.[location]);
    })

const initialState = {
    favoriteDestinations: [],
    services: [],
    forbiddenPages: [],
    defaultDepStations: null,
    ticketPriceConfirmation: null,
    loading: false,
    fetched: undefined,
};

const slice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        resetIsFetchedUserSettings: state => {
            state.loading = true;
            state.fetched = undefined;
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setDefaultUserSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(setDefaultUserSettings.fulfilled, (state, {payload}) => {
                state.services = payload.services;
                state.defaultDepStations = payload.defaultOutboundStations;
                state.favoriteDestinations = payload?.favoriteDestinations || [];
                state.forbiddenPages = payload?.forbiddenPages;
                state.ticketPriceConfirmation = payload.ticketPriceConfirmation;
                state.issuingTicket = payload.issuingTicket;
                state.roundedPrice = payload.roundedPrice;
                state.advertisementTextTicket = payload.advertisementTextTicket;
                state.fetched = new Date();
                state.loading = false;
            })
            .addCase(setDefaultUserSettings.rejected, (state) => {
                state.loading = false;
                state.fetched = undefined;
            })
            .addCase(addDefaultItemOnLocations.pending, (state) => {
                state.loading = true;
            })
            .addCase(addDefaultItemOnLocations.fulfilled, (state, {payload}) => {
                state.services = payload;
                state.loading = false;
            })
            .addCase(addDefaultItemOnLocations.rejected, (state) => {
                state.loading = false;
            })
            .addCase(removeDefaultItemOnLocations.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeDefaultItemOnLocations.fulfilled, (state, {payload}) => {
                state.services = payload;
                state.loading = false;
            })
            .addCase(removeDefaultItemOnLocations.rejected, (state) => {
                state.loading = false;
            })
    },
});
export const {resetIsFetchedUserSettings} = slice.actions
export default slice.reducer;
