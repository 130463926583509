import {getSessionOperator, getSessionSelectedLocation} from "../../../helper/session";
import {Box, Typography} from "@mui/material";
import moment from "moment-timezone";
import {useEffect, useState} from "react";
import {getUserInfo} from "../../../helper/firestore";
import {AUTH} from "../../../auth/FirebaseContext";

export default function UserInfo() {
    const [user, setUser] = useState()
    const operator = getSessionOperator()
    const location = getSessionSelectedLocation()
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const time = moment().tz('Europe/Belgrade').format('DD-MM-YYYY hh:mm');
            setCurrentTime(time);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        getUserInfo().then(response =>
            setUser(response)).catch(e => {
            console.error(e, 'getUserInfo')
        })
    }, [AUTH?.currentUser])

    // TODO dodati da cita pib
    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            {user &&
                <Typography variant="subtitle1" color="text.disabled">
                    {user?.name} ({user?.PIB}) | {operator?.username} | {location?.name} | {currentTime}
                </Typography>
            }
        </Box>
    )
}
