import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {insertAllUserInvoicesOffline, insertAllUserInvoicesOfflineForPeriod} from "../../store/offlineDb";

export const syncAllInvoices = createAsyncThunk('syncAllInvoices', async () => {
    return insertAllUserInvoicesOffline();
});

export const syncInvoiceInPeriod = createAsyncThunk('syncInvoiceInPeriod', async (period) => {
    return insertAllUserInvoicesOfflineForPeriod(period);
});

const initialState = {
    loading: true,
};

const slice = createSlice({
    name: 'synchronizations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(syncAllInvoices.pending, (state) => {
                state.loading = true;
            })
            .addCase(syncAllInvoices.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(syncAllInvoices.rejected, (state) => {
                state.loading = false;
            })
            .addCase(syncInvoiceInPeriod.pending, (state) => {
                state.loading = true;
            })
            .addCase(syncInvoiceInPeriod.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(syncInvoiceInPeriod.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default slice.reducer;
