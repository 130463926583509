import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllDisabilitiesAPI} from "../../api/ticketType/ticketType";

export const fetchAllDisabilities = createAsyncThunk('fetchAllDisabilities', async (data) => {
    return await getAllDisabilitiesAPI(data)
})

const initialState = {
    disabilitiesList: [],
    foundDisabilities: [],
    loadingDisabilities: false,
}

const disabilitySlice = createSlice({
    name: 'disability',
    initialState,
    reducers: {
        specialLoading: (state, {payload}) => {
            if (payload) {
                state.loadingDisabilities = payload
            }
        },
        searchDisabilitySlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundDisabilities = payload;
                state.loadingDisabilities = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDisabilities.pending, (state) => {
                state.loadingDisabilities = true;
            })
            .addCase(fetchAllDisabilities.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundDisabilities = payload.data;
                    state.disabilitiesList = payload.data;
                }
                state.loadingDisabilities = false;
            })
            .addCase(fetchAllDisabilities.rejected, (state) => {
                state.loadingDisabilities = false;
            })
    },
});

export const {searchDisabilitySlice, specialLoading} = disabilitySlice.actions;
export default disabilitySlice.reducer;
