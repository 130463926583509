import moment from "moment/moment";
import {addDays, isAfter} from "date-fns";
import {isEqual} from "lodash";

export function parseInvoiceDate(invoice) {
    let sdcTime = undefined;
    let referentDTparsed = undefined;
    if (invoice?.sdcDateTime) {
        sdcTime = invoice.sdcDateTime.substring(8, 10) + "."
            + invoice.sdcDateTime.substring(5, 7) + "."
            + invoice.sdcDateTime.substring(0, 4) + ". "
            + invoice.sdcDateTime.substring(11, 19);
    }
    if (invoice?.referentDocumentDT) {
        referentDTparsed = invoice.referentDocumentDT.substring(8, 10) + "."
            + invoice.referentDocumentDT.substring(5, 7) + "."
            + invoice.referentDocumentDT.substring(0, 4) + ". "
            + invoice.referentDocumentDT.substring(11, 19);
    }
    return {sdcTime, referentDTparsed}
}

export function parseDateToDDMMYYYY(date){
    if(date){
        return moment(date).format("DD.MM.YYYY")
    }
   return "/"
}

export function parseDateToDDMMYYYYHHSSMM(date){
    if(date){
        return moment(date).format("DD.MM.YYYY HH:mm:ss")
    }
    return "/"
}

export function dateTimeToTimestamp(dateString, timeString) {
    const date = new Date(dateString);

    const [hours, minutes] = timeString.split(':').map(Number);

    date.setUTCHours(hours, minutes, 0, 0);

    return date.getTime();
}

export const nextDay = (date) => {
    if(date){
       return  addDays(date, 1);
    }
    return new Date(new Date().setDate(new Date().getDate() + 1))
}

export const nextDayIfDateToIsBefore = (newDate, dateTo) => {
    if (!dateTo) {
        return addDays(newDate, 1);
    }
    if (isAfter(newDate, dateTo) || isEqual(newDate, dateTo)) {
        return addDays(newDate, 1);
    }
    return dateTo;
};
