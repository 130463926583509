import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {RHFTextField} from "../../../components/hook-form";
import {Button, Divider, Stack, Typography} from "@mui/material";
import ItemsDrawer from "./ItemsDrawer";
import {useCallback, useState} from "react";
import Iconify from "../../../components/Iconify";
import {getTaxValueByLabelRate} from "../../../helper/other";
import {fCurrency} from "../../../utils/formatNumber";
import {useSelector} from "../../../redux/store";
import {parseService, prepareService} from "../../../helper/homePage";

export default function Services() {
    const {control, setValue} = useFormContext();

    const [opeItemsDialog, setOpenItemsDialog] = useState({open: false, index: 0});
    const {currentTaxRates} = useSelector(((state) => state.taxCore));


    const {fields, append, remove} = useFieldArray({
        control,
        name: 'services',
    });

    const handleAdd = () => {
        append({
            id: '',
            quantity: 0,
            name: '',
            price: 0,
            totalAmount: 0,
        });
    };

    const handleRemove = (index) => {
        remove(index);
    };

    const handleCloseItemDrawer = _ => setOpenItemsDialog({open: false, index: 0})

    const onSelectEvent = (data, index) => {
        setValue(`services[${index}]`, parseService(data, currentTaxRates, data.quantity))
        handleCloseItemDrawer()
    }

    const handleChangeQuantity = useCallback(
        (event, index) => {
            setValue(`services[${index}].quantity`, Number(event.target.value));
        },
        [setValue]
    );

    const handleChangePrice = useCallback(
        (event, index) => {
            setValue(`services[${index}].price`, Number(event.target.value));
        },
        [setValue]
    );

    const totalOnRow = fields?.length > 0 && fields.map((item) => {
        return item.quantity * item.price
    })
    const totalOnRowPdv = fields?.length > 0 && fields.map((item) => {
        return item.quantity * (item.price - item.price / (1 + getTaxValueByLabelRate(item.vat, currentTaxRates) / 100))
    })

    const totalOnRowBase = fields?.length > 0 && fields.map((item) => {
        return item.quantity * (item.price / (1 + getTaxValueByLabelRate(item.vat, currentTaxRates) / 100))
    })

    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Typography variant="h6" sx={{color: 'text.disabled', mb: 3}}>
                    Dodatne usluge:
                </Typography>
                <Button size="small" startIcon={<Iconify icon="eva:plus-fill"/>} onClick={handleAdd}
                        sx={{width: 150}}>
                    Dodaj uslugu
                </Button>
            </Stack>
            <Stack divider={<Divider flexItem sx={{borderStyle: 'dashed'}}/>} spacing={3}>
                {fields.map((item, index) => (
                    <Stack key={`field-${item.id}-${index}`} alignItems="flex-end" spacing={1}>
                        <Stack direction={{xs: 'column', md: 'row'}} spacing={1} sx={{width: 1}}>
                            <RHFTextField
                                sx={{minWidth: 250}}
                                onClick={() => {
                                    setOpenItemsDialog({open: true, index: index})
                                }}
                                size="small"
                                name={`services[${index}].name`}
                                label="Naziv"
                                InputLabelProps={{shrink: true}}
                            />

                            <RHFTextField
                                size="small"
                                type="number"
                                name={`services[${index}].quantity`}
                                label="Kolicina"
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => handleChangeQuantity(event, index)}
                            />

                            <RHFTextField
                                size="small"
                                name={`services[${index}].price`}
                                label="Cena"
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => handleChangePrice(event, index)}
                            />

                            <RHFTextField
                                disabled
                                size="small"
                                name={`services[${index}].baseAmount`}
                                value={fCurrency(totalOnRowBase[index])}
                                label="Osnovica"
                                InputLabelProps={{shrink: true}}
                            />

                            <RHFTextField
                                disabled
                                size="small"
                                name={`services[${index}].pdv`}
                                value={fCurrency(totalOnRowPdv[index])}
                                label="PDV"
                                InputLabelProps={{shrink: true}}
                            />

                            <RHFTextField
                                disabled
                                size="small"
                                name={`services[${index}].totalAmount`}
                                label="Ukupno"
                                value={fCurrency(totalOnRow[index])}
                                InputLabelProps={{shrink: true}}
                            />
                            <Button
                                size="small"
                                color="error"
                                startIcon={<Iconify icon="solar:trash-bin-trash-bold-duotone"/>}
                                onClick={() => handleRemove(index)}
                            />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            <Divider sx={{my: 3, borderStyle: 'dashed'}}/>
            {opeItemsDialog.open && <ItemsDrawer open={opeItemsDialog.open} index={opeItemsDialog.index}
                                                 onClose={handleCloseItemDrawer}
                                                 onSelectEvent={onSelectEvent}
                                                 control={control}/>}
        </>
    )
}
