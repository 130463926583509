import moment from "moment"; // Import the debounce function from a library like lodash
import 'moment-timezone';
import {dispatch} from "../../redux/store";
import {getClientDetails} from "../../redux/slices/customers";
import {DIRECTION_NAME} from "../../constants";
import {fCurrency} from "../../utils/formatNumber";
import {formulaForDiscount} from "../../helper/invoice/busItems";

export const lineStationOptionLabel = (option) => `${option?.name} (${(option?.location?.region?.name || option?.location?.name) || ''})`
export const lineEndStationOptionLabel = (option) => `${option?.stationName} (${option.regionName || option?.locationName || ''})`
export const stationDepLabel = (option) => `${option?.name} (${option?.location?.name || ''})`
export const discountsOptionLabel = (option) => `${option?.name}`

export const DIRECTIONS = [{id: 1, name: "Jedan smer", value: DIRECTION_NAME[0]},
    {id: 2, name: "Povratna", value: DIRECTION_NAME[1]},
    {id: 3, name: "Mesečna", value: DIRECTION_NAME[2]}]

export const TICKET_TYPE =
    [
        {id: 1, value: "Osnovna"},
        {id: 2, value: "Povlašćena"},
    ]

export const SEAT_TYPE = {sitting: "Sedenje", standing: "Stajanje"}
export const SEAT_STATUS = {free: "SL", taken: "RZ"}
export const sumTimes = (time1, time2) => {
    try {
        // Convert the times to moment durations
        const momentTime1 = moment.duration(time1, 'HH:mm:ss');
        const momentTime2 = moment.duration(time2, 'HH:mm:ss');

        // Sum the durations in milliseconds
        const totalMilliseconds = momentTime1.asMilliseconds() + momentTime2.asMilliseconds();

        // Calculate total hours, minutes, and seconds
        const totalSeconds = Math.floor(totalMilliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600) % 24; // Modulo 24 to handle overflow beyond 24 hours
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Return formatted result
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } catch (err) {
        console.error(`Error: ${err.message}`);
        return '';
    }
}



export const getCarrierNameByTin = async (data) => {
    const arr = [...data]
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
            if (arr[i] > 8) {
                await dispatch(getClientDetails(arr[i])).unwrap().then(response => {
                    arr[i] = {name: response.name, tin: arr[i]}
                })
            }
        }
    }
    return arr
}

export const getPriceByDirectionType = (type, data) => {
    if(data.externalSale){
        return data.priceOneWay
    }
    if (type === DIRECTION_NAME[0]) {
        return data.priceOneWay
    } else if (type === DIRECTION_NAME[1]) {
        return data.priceReturnTicket
    } else return data.priceMonthly
}

export const handleDirectionName = (name) => {
    switch (name) {
        case DIRECTION_NAME[0]:
            return "Jedan smer"
        case DIRECTION_NAME[1]:
            return "Povratna"
        case DIRECTION_NAME[2]:
            return "Mesečna"
        default:
            return "Greska"
    }
}

export function isTimeTenMinutesBefore(depTime) {
    const lowerTime = moment().add(10, 'minutes');
    const targetTime = moment(depTime);
    return targetTime.isBefore(lowerTime);
}


// SAMO ZA PRIKAZ AMOUNTA PRILIKOM KREIRANJA RACUNA
// export const amount = (state, directionType, discountPCT) => {
//     const getPrice = (price, discount, depDistance, arrDistance) => {
//         let ticketPriceBeforeBorder, ticketPriceAfterBorder;
//         let totalAmount;
//
//         if (state.borderName !== undefined && state.borderName !== "") {
//             ticketPriceBeforeBorder = formulaForBorderPrice(price, depDistance, arrDistance, discount);
//             ticketPriceAfterBorder = formulaAfterForBorderPrice(price, depDistance, arrDistance, discount);
//             totalAmount = ticketPriceBeforeBorder + ticketPriceAfterBorder;
//         } else {
//             totalAmount = formulaForPrice(price, discount);
//         }
//         return totalAmount;
//     };
//
//     let totalAmount = 0;
//     let discount = 0;
//     switch (directionType) {
//         case DIRECTION_NAME[0]:
//             if (state.priceOneWay) {
//                 discount = formulaForDiscount(state.priceOneWay, discountPCT?.discountPCT);
//                 totalAmount = getPrice(state.priceOneWay, discount, state.depBorderDistance, state.arrBorderDistance);
//             }
//             break;
//         case DIRECTION_NAME[1]:
//             if (state.priceReturnTicket) {
//                 discount = formulaForDiscount(state.priceReturnTicket, discountPCT?.discountPCT);
//                 totalAmount = getPrice(state.priceReturnTicket, discount, state.depBorderDistance, state.arrBorderDistance);
//             }
//             break;
//         default:
//             if (state.priceMonthly) {
//                 discount = formulaForDiscount(state.priceMonthly, discountPCT?.discountPCT);
//                 totalAmount = getPrice(state.priceMonthly, discount, state.depBorderDistance, state.arrBorderDistance);
//             }
//     }
//
//     const pdv = totalAmount * 0.1;
//     const baseAmount = totalAmount - pdv;
//
//     return {
//         baseAmount,
//         pdv,
//         discount,
//         totalAmount,
//     };
// };


//FORMULA KOJA RACUNA CENU SA POPUSTOM DO GRANICE
export const formulaForBorderPrice = (price, beforeBorderDistance, afterBorderDistance) => {
    if(beforeBorderDistance && afterBorderDistance){
        if (isNaN(price) || !(price >= 0)) {
            throw new Error("Vrednost karte nije validna");
        }

        return (price * beforeBorderDistance) / (beforeBorderDistance + afterBorderDistance);
    }
   return price
};


//FORMULA KOJA RACUNA CENU SA POPUSTOM OD GRANICE
export const formulaAfterForBorderPrice = (price, beforeBorderDistance, afterBorderDistance) => {
    if(beforeBorderDistance && afterBorderDistance){
        if (isNaN(price) && !(price >= 0)) {
            // eslint-disable-next-line no-throw-literal
            throw "Vrednost karte nije validna"
        }
        return (price  * afterBorderDistance) / (beforeBorderDistance + afterBorderDistance)
    }
    return price
}
// TODO izbaciti iz upotrebe!!
export const formulaForPrice = (price, discount) => {
    if (isNaN(price) && !(price > 0)) {
        // eslint-disable-next-line no-throw-literal
        throw "Vrednost karte nije validna"
    }
    if (isNaN(discount) && !(discount >= 0)) {
        // eslint-disable-next-line no-throw-literal
        throw "Vrednost popusta nije validna!!"
    }
    return Math.ceil((price - discount) / 10) * 10;
}

export const handleServices = (services, quantity) => {
    const arr = []

    services.forEach(item => {
        const parsedQty = quantity ? Number(fCurrency(`${quantity}`)) : Number(fCurrency(item.quantity));
        const parsedPrice = Number(fCurrency(item.price));
        arr.push({
            uid: item.uid,
            unit: item.unit,
            passengerUid: [],
            labels: [item.vat],
            name: item.name,
            quantity: parsedQty,
            totalAmount: parsedQty * parsedPrice,
            unitPrice: parsedPrice,
        })
    })
    return arr
}
