import {Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {useLocales} from "../../../locales";
import Iconify from "../../../components/Iconify";
import {RHFCheckbox, RHFTextField} from "../../../components/hook-form";
import {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";

export default function PassengerDetails({index, showRows}) {
    const {translate} = useLocales()
    const [open, setOpen] = useState(false);

    const {
        control
    } = useFormContext();

    const passengers = useWatch({
        control,
        name: "passengers"
    })

    const phone = passengers[index]?.phone

    return (
        <Accordion
            open={open}
            onClose={() => setOpen(true)}>
            <AccordionSummary
                expandIcon={<Iconify icon="ep:arrow-down-bold"/>}>
                <Typography>{translate("moreDetails")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    gap={2}
                    display="grid"
                    gridTemplateColumns={{
                        xs: `repeat(1, 1fr)`,
                        sm: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                        md: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                        lg: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                    }}>
                    <RHFTextField
                        size="small"
                        name={`passengers.${index}.name`}
                        label="Ime"
                        InputLabelProps={{shrink: true}}
                    />

                    <RHFTextField
                        size="small"
                        name={`passengers.${index}.surname`}
                        label="Prezime"
                        InputLabelProps={{shrink: true}}
                    />
                    <RHFTextField
                        size="small"
                        name={`passengers.${index}.phone`}
                        label="Telefon"
                        InputLabelProps={{shrink: true}}
                    />
                    <RHFTextField
                        size="small"
                        name={`passengers.${index}.email`}
                        label="Mejl"
                        InputLabelProps={{shrink: true}}
                    />
                    <RHFTextField
                        select
                        size="small"
                        name={`passengers.${index}.type`}
                        label="Tip">
                        <MenuItem value="adult">Odrasla osoba</MenuItem>
                        <MenuItem value="child">Dete</MenuItem>
                    </RHFTextField>
                    <Stack direction={{md: 'row', sm: 'column'}} spacing={1.2}>
                        <Tooltip title="Poruka sa detaljima kupovine karte će biti prosleđena korisniku na telefon">
                            <div>
                                <RHFCheckbox
                                    disabled={phone?.length < 7}
                                    name={`passengers.${index}.sms`}
                                    label="Prosledi poruku"/>
                            </div>
                        </Tooltip>
                        <Tooltip title="Putno osiguranje ukoliko postoji sklopljen ugovor sa osiguravajućom kućom">
                            <div>
                                <RHFCheckbox
                                    disabled
                                    name={`passengers.${index}.insurance`}
                                    label="Putno osiguranje"/>
                            </div>
                        </Tooltip>
                    </Stack>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
