import {customAxiosBE} from "../config";
import {parsePaginationParams} from "../../utils/other";

export function getLocationByIdAPI(id) {
    return customAxiosBE.get(`/location/${id}`)
}

export function getAllLocationsAPI(params) {
    const parsedParams = parsePaginationParams(params)
    return customAxiosBE.get(`/location?${parsedParams}`)
}

export function insertLocationAPI(data) {
    return customAxiosBE.post(`/location`, data)
}

export function updateLocationAPI(data) {
    return customAxiosBE.put(`/location`, data)
}

export function deleteLocationAPI(id) {
    return customAxiosBE.delete(`/location/${id}`)
}
