import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {child, get} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {getSessionSelectedLocation} from "../../helper/session";

export const fetchAllLocations = createAsyncThunk('fetchAllLocations', async () => {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/protected/se`))).forEach(child => {
        if (child.val().PAC) {
            arr.push({
                ...child.val(),
                uid: child.key
            })
        }
    });
    return arr;
})

const initialState = {
    locations: [],
    selectedLocation: getSessionSelectedLocation(),
    loading: false,
}

export const slice = createSlice({
    name: 'locations',
    initialState,
    extraReducers: {
        //fetchAllLocations
        [fetchAllLocations.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllLocations.fulfilled]: (state, {payload}) => {
            state.locations = payload;
            state.loading = false;
        },
        [fetchAllLocations.rejected]: (state) => {
            state.loading = false;
        },
    }
})

export default slice.reducer
