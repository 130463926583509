import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
// redux
import {child, get, push, update} from "firebase/database";
import {AUTH, dbRef} from "../../auth/FirebaseContext";
import {putOperatorsOffline} from "../../store/offlineDb";
import {OPERATORS_GROUP} from "../../constants";
import {sanitizeString} from "../../utils/other";
// ----------------------------------------------------------------------

export const fetchAllOperators = createAsyncThunk("fetchAllOperators", async () => {
    const arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators`))).forEach(child1 => {
        if (GROUPS_IDS_OPERATORS.includes(parseInt(child1.val().group))) {
            arr.push({
                ...child1.val(),
                uid: child1.key
            });
        }
    });
    await putOperatorsOffline(arr)
    return arr;
});

export const createOperator = createAsyncThunk("createOperator", async (operator) => {
    const obj = {
        username: operator.username,
        secret: operator.secret,
        printText: sanitizeString(operator?.printText),
        group: Number(operator.group),
        inactive: false
    };
    (await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators`),
        obj));
    return obj;
});

export const changeOperatorStatus = createAsyncThunk("changeOperatorStatus", async (operator) => {
    (await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators/${operator.uid}`), {
        ...operator,
        inactive: !operator.inactive
    }));
    return {
        ...operator,
        inactive: !operator.inactive
    };
});

export const updateOperator = createAsyncThunk("updateOperator", async (operator) => {
    const obj = {
        group: Number(operator.group),
        inactive: false,
        printText: sanitizeString(operator?.printText),
        secret: operator.secret,
        uid: operator.uid,
        username: operator.username
    };
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators/${operator.uid}`), obj);
    return obj;
});

const initialState = {
    loading: true,
    error: null,
    allOperators: [],
    operator: null
};

// Reducer
export const operatorsSlice = createSlice({
    name: "operators",
    initialState,
    reducers: {
        // logInOperator: (state, {payload}) => {
        //     setSessionOperator(payload);
        //     state.operator = payload;
        // },
        // logOutOperator: (state) => {
        //     removeSessionOperator();
        //     state.operator = undefined;
        // }
    },
    extraReducers: {
        [fetchAllOperators.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllOperators.fulfilled]: (state, {payload}) => {
            state.allOperators = payload.sort((a, b) => a.group - b.group);
            state.loading = false;
        },
        [fetchAllOperators.rejected]: (state) => {
            state.loading = false;
        },
        [changeOperatorStatus.pending]: (state) => {
            state.loading = true;
        },
        [changeOperatorStatus.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allOperators)];
            const index = arr.findIndex(o => o.uid === payload.uid);
            if (index !== -1) {
                arr[index] = payload;
                state.allOperators = arr;
            }
            state.loading = false;
        },
        [changeOperatorStatus.rejected]: (state) => {
            state.loading = false;
        },
        [updateOperator.pending]: (state) => {
            state.loading = true;
        },
        [updateOperator.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allOperators)];
            const index = arr.findIndex(o => o.uid === payload.uid);
            if (index !== -1) {
                arr[index] = payload;
                state.allOperators = arr;
            }
            state.loading = false;
        },
        [updateOperator.rejected]: (state) => {
            state.loading = false;
        },
        [createOperator.pending]: (state) => {
            state.loading = true;
        },
        [createOperator.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allOperators)];
            arr.push(payload);
            state.allOperators = arr;
            state.loading = false;
        },
        [createOperator.rejected]: (state) => {
            state.loading = false;
        }
    }
});

export const operatorsReducer = operatorsSlice.reducer;

const GROUPS_IDS_OPERATORS = Object.values(OPERATORS_GROUP);
