import {DIRECTION_NAME, ESIR_NUMBER, INVOICE_TYPE, PAYMENT_TYPE, TRANSACTION_TYPE} from "../../constants";
import moment from "moment/moment";
import {fCurrency} from "../../utils/formatNumber";

let fontSize = "0.7rem";

const style = `
  @media print{ 
    @page{
      size: portrait;  
      margin: 0px !important;
      padding: 0;
      width: 100%
    }
    body {
      margin-bottom: 20mm; 
    }
  }
  .lineDivider{
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .lineDivider:before, .lineDivider:after {
    content: replaceToqut ;
    flex: 1 1;
    border-bottom: 1px solid #000;
    margin: auto;
  }
  .dashedDivider{
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .dashedDivider:before, .dashedDivider:after {
    content: replaceToqut ;
    flex: 1 1;
    height: 4px;
    border-bottom: 1.5px dashed #000;
    border-top: 1.5px dashed #000;
    margin: auto;
  }
.dashedDividerLine {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center; 
}

.dashedDividerLine:before, .dashedDividerLine:after {
  content: replaceToqut;
  flex: 1;
  height: 4px;
  border-top: 1.5px dashed #000;
  margin: 0 2px;
}

`;
// 80 mm font-size 29px width 560
// 58 mm font-size 19px width 360
export default function InvoiceSlip(invoice, response, logo, advance,
                                    exchange, advertisement, ticketValidityPeriod, isSilentPrint = false,
                                    printerSize) {
    console.debug("InvoiceSlip", {
        invoice, response, logo, advance,
        exchange, advertisement, ticketValidityPeriod, isSilentPrint,
        printerSize
    })
    let width = 360;
    fontSize = isSilentPrint ? "19px" : fontSize;
    if (printerSize) {
        if (printerSize == "80") {
            width = 560;
            fontSize = "29px"
        }
    }

    return (
        <html lang="sr">
        <head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <style type="text/css">{style}</style>
            <title>invoice</title>
        </head>
        <body
            id="forPrint"
            style={{
                fontFamily: "Calibri",
                margin: 0,
                width: "100%",
                maxWidth: isSilentPrint ? "100%" : "80mm",
                justifyContent: "center",
                display: "inline-block",
                height: "auto",
                wordBreak: "break-word",
                fontSize: fontSize
            }}>
        <table style={{
            margin: 0,
            width: isSilentPrint ? width : "auto",
        }}>
            <tbody>
            {logo ?
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <img alt="logo" src={logo} width="50%" height="auto"/>
                    </td>
                </tr> : <></>
            }
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    <div className="dashedDivider">
                        {isFiscalText(invoice.invoiceType)}
                    </div>
                </td>
            </tr>
            {invoiceHeader(invoice, response)}
            <tr>
                <td
                    style={{
                        fontSize: fontSize,
                        textAlign: "center"
                    }}
                    colSpan={4}>
                    <div className="dashedDividerLine">
                        {invoiceTitle(invoice.invoiceType, invoice.transactionType)}
                    </div>
                </td>
            </tr>
            <tr>
                <td
                    style={{
                        textAlign: "center",
                        fontSize: fontSize
                    }}
                    colSpan={4}>
                    ARTIKLI
                </td>
            </tr>
            <tr>
                <td
                    style={{
                        textAlign: "center",
                        fontSize: fontSize
                    }} colSpan={4}>
                    <div className="dashedDivider"/>
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}}>Naziv</td>
                <td style={{textAlign: "center", fontSize: fontSize}}>Cena</td>
                <td style={{textAlign: "center", fontSize: fontSize}}>Kol.</td>
                <td style={{textAlign: "right", fontSize: fontSize}}>Ukupno</td>
            </tr>
            {invoiceItems(invoice.items, invoice.invoiceType, invoice.transactionType)}
            <tr>
                <td
                    style={{
                        fontSize: fontSize,
                        textAlign: "center"
                    }}
                    colSpan={4}>
                    <div className="dashedDividerLine"/>
                </td>
            </tr>
            {invoicePayments(invoice.payment, response.totalAmount, advance, exchange)}
            <tr>
                <td
                    style={{
                        fontSize: fontSize,
                        textAlign: "center"
                    }}
                    colSpan={4}>
                    <div className="dashedDivider"/>
                </td>
            </tr>
            {notFiscalText(invoice.invoiceType)}
            {invoiceTax(response.taxItems)}
            <tr>
                <td
                    style={{
                        fontSize: fontSize,
                        textAlign: "center"
                    }}
                    colSpan={4}>
                    <div className="dashedDivider"/>
                </td>
            </tr>
            {invoiceFooter(response)}
            {invoiceQR(response, isSilentPrint)}
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    <div className="dashedDivider">
                        {isFiscalText(invoice.invoiceType, true)}
                    </div>
                </td>
            </tr>
            {invoiceSignature(invoice.invoiceType, invoice.transactionType)}
            {/*<tr>*/}
            {/*    <td style={{textAlign: "center", fontSize: fontSize, fontWeight: 700}} colSpan={4}>*/}
            {/*        PODACI O PREVOZNOJ KARTI*/}
            {/*    </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*    <td colSpan={4}>*/}
            {/*        {invoiceAdvertisement(advertisement, ticketValidityPeriod)}*/}
            {/*    </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*    <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>*/}
            {/*        Fiskalni račun je i prevozna karta, molimo Vas da čuvate Vaš racun.*/}
            {/*    </td>*/}
            {/*</tr>*/}
            {advertisement?.text?.selectedCarrier?.length > 1 ?
                <tr>
                    <td
                        style={{
                            fontSize: fontSize,
                            textAlign: "center"
                        }}
                        colSpan={4}>
                        <div className="dashedDivider"/>
                    </td>
                </tr> :
                <tr/>
            }
            {advertisement?.text?.selectedCarrier?.length > 1 ? <tr>
                <td
                    style={{
                        fontSize: fontSize,
                        textAlign: "center"
                    }}
                    colSpan={4}>
                    IZDATO ZA PREVOZNIKA: {advertisement?.text?.selectedCarrier}
                </td>
            </tr> : <></>}
            </tbody>
        </table>
        </body>
        </html>
    );
};

const invoiceTitle = (invoiceType, transactionType) => {
    const object = {
        [INVOICE_TYPE.training]: {
            [TRANSACTION_TYPE.sale]: " OBUKA PRODAJA ",
            [TRANSACTION_TYPE.refund]: " OBUKA REFUNDACIJA "
        },
        [INVOICE_TYPE.proforma]: {
            [TRANSACTION_TYPE.sale]: " PREDRAČUN PRODAJA ",
            [TRANSACTION_TYPE.refund]: " PREDRAČUN REFUNDACIJA "
        },
        [INVOICE_TYPE.advance]: {
            [TRANSACTION_TYPE.sale]: " AVANS PRODAJA ",
            [TRANSACTION_TYPE.refund]: " AVANS REFUNDACIJA "
        },
        [INVOICE_TYPE.copy]: {
            [TRANSACTION_TYPE.sale]: " KOPIJA PRODAJA ",
            [TRANSACTION_TYPE.refund]: " KOPIJA REFUNDACIJA "
        },
        [INVOICE_TYPE.normal]: {
            [TRANSACTION_TYPE.sale]: " PROMET PRODAJA ",
            [TRANSACTION_TYPE.refund]: " PROMET REFUNDACIJA "
        }
    };
    if (object[invoiceType]) {
        if (object[invoiceType][transactionType]) {
            return object[invoiceType][transactionType];
        }
    }
    return " PREGLED ";
};

const isFiscalText = (invoiceType, end = false) => {
    const object = {
        [INVOICE_TYPE.normal]:
            " FISKALNI RAČUN ",
        [INVOICE_TYPE.advance]:
            " FISKALNI RAČUN ",
        [INVOICE_TYPE.proforma]:
            " OVO NIJE FISKALNI RAČUN ",
        [INVOICE_TYPE.copy]:
            " OVO NIJE FISKALNI RAČUN ",
        [INVOICE_TYPE.training]:
            " OVO NIJE FISKALNI RAČUN "
    };
    if (object[invoiceType]) {
        if (end) {
            return " KRAJ FISKALNOG RAČUNA ";
        }
        return object[invoiceType];
    }
    return ` ${invoiceType} `;
};

const notFiscalText = (invoiceType) => {
    if (INVOICE_TYPE.training === invoiceType || INVOICE_TYPE.copy === invoiceType ||
        INVOICE_TYPE.proforma === invoiceType) {
        return (
            <>
                <tr>
                    <td
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: fontSize
                        }}
                        colSpan={4}>
                        OVO NIJE FISKALNI RAČUN
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            textAlign: "center",
                            fontSize: fontSize
                        }}
                        colSpan={4}>
                        <div className="dashedDivider"/>
                    </td>
                </tr>
            </>
        );
    }
    return <></>;
};

export const parsePaymentMethodText = (paymentType) => {
    const object = {
        [PAYMENT_TYPE.cash]: "Uplaćeno - gotovina:",
        [PAYMENT_TYPE.card]: "Uplaćeno - kartica:",
        [PAYMENT_TYPE.check]: "Uplaćeno - ček:",
        [PAYMENT_TYPE.wireTransfer]: "Uplaćeno - prenos na račun:",
        [PAYMENT_TYPE.voucher]: "Uplaćeno - vaučer:",
        [PAYMENT_TYPE.mobileMoney]: "Uplaćeno - instant plaćanje:",
        [PAYMENT_TYPE.other]: "Uplaćeno - Drugi bezgotovinski način plaćanja:"
    };
    if (object[paymentType]) {
        return object[paymentType];
    }
    return paymentType;
};

const invoiceHeader = (invoice, response) => {

    let printCustomerName = invoice.invoiceType === INVOICE_TYPE.normal && invoice.transactionType === TRANSACTION_TYPE.sale;
    const headers = [
        {title: "PIB:", value: response.tin, dontShowTitle: true},
        {title: "Preduzeće:", value: response.businessName, dontShowTitle: true},
        {title: "Mesto prodaje:", value: response.locationName, dontShowTitle: true},
        {title: "Adresa:", value: response.address, dontShowTitle: true},
        {title: "Opština:", value: response.district, dontShowTitle: true},
        {title: "Kasir:", value: invoice.cashier},
        {title: "ID kupca:", value: invoice.buyerId},
        {title: "Opciono polje kupca:", value: printCustomerName ? invoice.buyersCosCenter : undefined},
        {title: "ESIR broj:", value: ESIR_NUMBER},
        {title: "Ref. broj:", value: invoice.referentDocumentNumber},
        {
            title: "Ref. vreme:",
            value: !invoice?.referentDocumentDT ? undefined : moment(invoice?.referentDocumentDT).format("DD.MM.YYYY HH:mm:ss")
        },
        {title: "ESIR vreme:", value: invoice.dateAndTimeOfIssue}
    ].filter(option => option.value !== undefined && option.value !== null);
    return headers.map((item, index) => {
            if (item.dontShowTitle) {
                return (
                    <tr key={`header-${index}`}>
                        <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>{item.value}</td>
                    </tr>
                )
            }
            return (
                <tr key={`invoice-header-${index}`}>
                    <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>{item.title}</td>
                    <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>{item.value}</td>
                </tr>
            );
        }
    )
};

const invoiceFooter = (response) => {
    return (
        <>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>PFR vreme:</td>
                <td style={{textAlign: "right", fontSize: fontSize}}
                    colSpan={2}>{moment(response.sdcDateTime).format("DD.MM.YYYY HH:mm:ss")}</td>
            </tr>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}} colSpan={1}>PFR broj računa</td>
                <td style={{textAlign: "right", fontSize: fontSize}} colSpan={3}>{response.invoiceNumber}</td>
            </tr>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>Brojač racuna:</td>
                <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>{response.invoiceCounter}</td>
            </tr>
        </>
    );
}

const invoiceQR = (response, isSilentPrint) => {
    if (response?.verificationQRCode) {
        return (
            <>
                <tr>
                    <td
                        style={{
                            fontSize: fontSize,
                            textAlign: "center"
                        }}
                        colSpan={4}>
                        <div className="dashedDivider"/>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <img
                                style={{
                                    minWidth: isSilentPrint ? 360 : "40mm",
                                    minHeight: isSilentPrint ? 360 : "40mm",
                                    maxWidth: isSilentPrint ? 360 : "50mm",
                                    maxHeight: isSilentPrint ? 360 : "50mm"
                                }}
                                width={isSilentPrint ? 360 : "100%"}
                                height={isSilentPrint ? 360 : "100%"}
                                alt="qr code"
                                src={`data:image/gif;base64,${response.verificationQRCode}`}/>
                        </div>
                    </td>
                </tr>
            </>
        );
    }
    return <></>;
};

const invoiceSignature = (invoiceType, transactionType) => {
    if (invoiceType === INVOICE_TYPE.copy && TRANSACTION_TYPE.refund === transactionType) {
        return (
            <>
                <tr>
                    <td style={{fontSize: fontSize}} colSpan={4}>
                        POTPIS KUPCA
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            textAlign: "center",
                            width: "100%",
                            borderBottom: "1px solid black",
                            fontSize: fontSize
                        }}
                        colSpan={4}/>
                </tr>
            </>
        );
    }
    return <></>;
};

const renderTableRow = (key, text) => {
    return (
        <tr>
            <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>
                {key}:
            </td>
            <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>
                {text}
            </td>
        </tr>
    );
};


const renderTableRowDate = (key, text) => {
    return (
        <tr>
            <td style={{textAlign: "left", fontSize: fontSize}} colSpan={1}>
                {key}:
            </td>
            <td style={{textAlign: "right", fontSize: fontSize}} colSpan={3}>
                {text}
            </td>
        </tr>
    );
};

const getPassenger = (passenger) => {
    const rows = [];

    if (passenger?.name?.length > 0) {
        rows.push(renderTableRow("IME PUTNIKA", passenger.name));
    }
    if (passenger?.surname?.length > 0) {
        rows.push(renderTableRow("PREZIME PUTNIKA", passenger.surname));
    }
    if (passenger?.phone?.length > 0) {
        rows.push(renderTableRow("TELEFON", passenger.phone));
    }
    if (passenger?.email?.length > 0) {
        rows.push(renderTableRow("MEJL", passenger.email));
    }
    if (passenger?.seatNumber > 0) {
        rows.push(renderTableRow("SEDIŠTE BROJ", passenger.seatNumber));
    }

    return rows;
};

// SVE OSTALE KLJUCEN INFORMACIJE KAO NAZIV RELACIJE, VREME POLASKA ITD
const getTicketInformation = (data, ticketValidityPeriod) => {
    const rows = [];

    if (data?.selectedLine) {
        rows.push(renderTableRow("RELACIJA", data.selectedLine));
    }
    if (data?.departureTripTime) {
        rows.push(renderTableRow("VREME POLASKA", data.departureTripTime.slice(0, -3)));
    }

    if (data?.dateFrom) {
        rows.push(renderTableRow("DATUM POLASKA", moment(data?.dateFrom).format("DD.MM.YYYY")));
    }
    if (data?.startStation) {
        rows.push(renderTableRow("POLAZNA STANICA", data.startStation));
    }
    if (data?.endStation?.length > 0) {
        rows.push(renderTableRow("KRAJNJA STANICA", data.endStation));
    }
    if (data?.seatNumber > 0) {
        rows.push(renderTableRow("POLAZAK", moment(data.dateFrom).format('DD-MM-YYYY') + "."));
    }

    if (data?.distance > 0) {
        rows.push(renderTableRow("BROJ KILOMETARA", `${data.distance}km`));
    }
    if (data?.directionType === DIRECTION_NAME[1]) {
        rows.push(<tr>
            <td
                style={{
                    fontSize: fontSize,
                    textAlign: "center"
                }}
                colSpan={4}>
                <div className="dashedDivider"/>
            </td>
        </tr>)
        rows.push(renderTableRow(`Povratna karta važi ${ticketValidityPeriod || "/"} dana od dana izdavanja`, null));
        rows.push(renderTableRow("POLAZNA STANICA", data.endStation));
        rows.push(renderTableRow("KRAJNJA STANICA", data.startStation));
        rows.push(renderTableRowDate("DATUM POVRATKA", "_________________"));
    }
    if (data?.discount) {
        rows.push(renderTableRow("POPUST", `${data?.discount?.name?.toUpperCase()} (${data?.discount?.discountPCT}%)`));
    }
    if (data?.selectedCarrier) {
        rows.push(renderTableRow("KARTA IZDATA ZA PREVOZNIKA",
            `${data?.selectedCarrier?.name?.toUpperCase()} (${data?.selectedCarrier?.tin})`));
    }

    if (data?.monthlyTicketDescription) {
        rows.push(renderTableRow("IME I PREZIME",
            `${data?.monthlyTicketDescription?.client?.toUpperCase()}`));
        rows.push(renderTableRow("BROJ KARTICE",
            `${data?.monthlyTicketDescription?.cardNumber?.toUpperCase()}`));
        rows.push(renderTableRow("BROJ DOKUMENTA",
            `${data?.monthlyTicketDescription?.clientIdentity?.toUpperCase()}`));
        rows.push(renderTableRow("MESEC VAŽENJA",
            `${data?.monthlyTicketDescription?.month?.toUpperCase()}`));
        rows.push(renderTableRow("MPOČETNA STANICA",
            `${data?.monthlyTicketDescription?.startStation?.toUpperCase()}`));
        rows.push(renderTableRow("kRAJNJA STANICA",
            `${data?.monthlyTicketDescription?.endStation?.toUpperCase()}`));
        rows.push(renderTableRow("LINIJA",
            `${data?.monthlyTicketDescription?.linesList?.toUpperCase()}`));
    }

    if (data?.customText) {
        rows.push(renderTableRow("REKLAMNI TEKST: ",
            `${data?.customText?.toUpperCase()}`));
    }
    return rows;
}

const invoiceAdvertisement = (advertisement, ticketValidityPeriod) => {

    if (!advertisement) {
        return <></>
    }
    return (
        <tr>
            <td style={{fontSize: fontSize}} colSpan={4}>
                {advertisement.text ? advertisement.text : ""}
                {getPassenger(advertisement?.text?.passenger)}
                {getTicketInformation(advertisement?.text, ticketValidityPeriod)}
            </td>
        </tr>
    )
}

const invoiceItems = (items, invoiceType, transactionType) => {
    const formatItemName = (name, label, unit) => {
        let formatted;
        if (invoiceType === INVOICE_TYPE.advance) {
            formatted = `${name}`;
        } else if (unit) {
            formatted = `${name} (${label})/${unit}`;
        } else {
            formatted = `${name}/(${label})`;
        }
        return formatted;
    };

    return items.map((item, index) => {
        let formattedName = formatItemName(item.name, item.labels[0], item.unit);
        if (formattedName.length < 20) {
            return (
                <tr key={`item-${index}`}>
                    <td style={{textAlign: "left", fontSize: fontSize}}>{formattedName}</td>
                    <td style={{textAlign: "center", fontSize: fontSize}}>{item.unitPrice}</td>
                    <td style={{textAlign: "center", fontSize: fontSize}}>{item.quantity}</td>
                    <td style={{textAlign: "right", fontSize: fontSize}}>
                        {transactionType === TRANSACTION_TYPE.refund ? "-" : ""}{fCurrency(`${item.totalAmount}`)}
                    </td>
                </tr>
            )
        } else {
            return (
                <>
                    <tr>
                        <td style={{fontSize: fontSize}} colSpan={4}>{formattedName}</td>
                    </tr>
                    <tr>
                        <td/>
                        <td style={{textAlign: "center", fontSize: fontSize}}>{fCurrency(`${item.unitPrice}`)}</td>
                        <td style={{textAlign: "center", fontSize: fontSize}}>{item.quantity}</td>
                        <td style={{textAlign: "right", fontSize: fontSize}}>
                            {transactionType === TRANSACTION_TYPE.refund ? "-" : ""}{fCurrency(`${item.totalAmount}`)}
                        </td>
                    </tr>
                </>
            );
        }
    })
}

const invoicePayments = (payment, totalAmount, advance, exchange) => {

    return (
        <>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>
                    Za uplatu:
                </td>
                <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>
                    {fCurrency(`${totalAmount}`)}
                </td>
            </tr>
            {payment.map((p, index) =>
                <tr key={`payment-${index}`}>
                    <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>
                        {parsePaymentMethodText(p.paymentType)}
                    </td>
                    <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>
                        {fCurrency(`${p.amount + exchange}`)}
                    </td>
                </tr>
            )}
            {(exchange && exchange > 0) ?
                <tr>
                    <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>
                        Kusur:
                    </td>
                    <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>
                        {fCurrency(`${exchange}`)}
                    </td>
                </tr> : <></>
            }
        </>
    )
}

const invoiceTax = (tax) => {
    if (!tax)
        return <></>
    const sum = tax.reduce((acc, {amount}) => acc + amount, 0);
    return (
        <>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}}>Oznaka</td>
                <td style={{textAlign: "center", fontSize: fontSize}}>Ime</td>
                <td style={{textAlign: "center", fontSize: fontSize}}>Stopa</td>
                <td style={{textAlign: "right", fontSize: fontSize}}>Porez</td>
            </tr>

            {tax.map((item, index) =>
                <tr key={`tax-${index}`}>
                    <td style={{textAlign: "left", fontSize: fontSize}}>{item.label}</td>
                    <td style={{textAlign: "center", fontSize: fontSize}}>{item.categoryName}</td>
                    <td style={{textAlign: "center", fontSize: fontSize}}>{fCurrency(`${item.rate}`)}</td>
                    <td style={{textAlign: "right", fontSize: fontSize}}>{fCurrency(`${item.amount}`)}</td>
                </tr>
            )}
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    <div className="dashedDividerLine"/>
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "left", fontSize: fontSize}} colSpan={2}>Ukupan iznos poreza:</td>
                <td style={{textAlign: "right", fontSize: fontSize}} colSpan={2}>{fCurrency(`${sum}`)}</td>
            </tr>
        </>
    );
}
