import React, {Suspense} from 'react';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import reportWebVitals from './reportWebVitals';

// redux
import {persistor, store} from './redux/store';
// @mui
import {LocalizationProvider} from '@mui/x-date-pickers';

import App from './App';

import {SettingsProvider} from "./components/settings";
import ScrollToTop from "./components/scrollToTop";
import {AuthProvider} from "./auth/FirebaseContext";
import LoadingScreen from "./components/loading-screen";

import de from 'date-fns/locale/de';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<LoadingScreen/>}>
        <AuthProvider>
            <HelmetProvider>
                <ReduxProvider store={store}>
                    <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                            <SettingsProvider>
                                <BrowserRouter>
                                    <ScrollToTop/>
                                    <App/>
                                </BrowserRouter>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </PersistGate>
                </ReduxProvider>
            </HelmetProvider>
        </AuthProvider>
    </Suspense>
);

reportWebVitals();
