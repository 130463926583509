import PropTypes from 'prop-types';
// @mui
import { Box, FormControlLabel, Switch, TablePagination } from '@mui/material';
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

TablePaginationCustom.propTypes = {
    dense: PropTypes.bool,
    loading: PropTypes.bool,
    onChangeDense: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    sx: PropTypes.object,
};

export default function TablePaginationCustom({
                                                  dense,
                                                  loading,
                                                  onChangeDense,
                                                  rowsPerPageOptions = [5, 10, 25],
                                                  sx,
                                                  ...other
                                              }) {
    const { translate } = useLocales();
    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <TablePagination
                disabled={loading}
                labelRowsPerPage={translate("rowsPerPage")}
                labelDisplayedRows={({ from, to, count }) =>
                    `${translate("shownRows")} ${from}-${to} ${translate("ofTotal")} ${count}`
                }
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                showFirstButton
                showLastButton
                {...other}
            />

            {onChangeDense && (
                <FormControlLabel
                    label={translate("reducedSpacing")}
                    control={<Switch disabled={loading} checked={dense} onChange={onChangeDense} />}
                    sx={{
                        pl: 2,
                        py: 1.5,
                        top: 0,
                        position: {
                            sm: 'absolute',
                        },
                    }}
                />
            )}
        </Box>
    );
}
