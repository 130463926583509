import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {getAllUnUsedEventsAPI, setCameraEventToUsedAPI} from "../../api/camera";

export const fetchAllUnusedEvents = createAsyncThunk(
    "fetchAllUnusedEvents", async ({
                                       filter, params
                                   }) => {
        return (await getAllUnUsedEventsAPI(filter, params)).data
    })

const initialState = {
    notifications: [],
    unUsedEvents: {
        content: [],
        totalElements: 0,
    },
    loading: false
}

const slice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        removeCameraNotification: (state, {payload}) => {
            if(payload.trafficLogId){
                setCameraEventToUsedAPI({
                    id: payload.id,
                    trafficLogId: payload.trafficLogId,
                }).then(() => {
                    console.debug('setCameraEventToUsedAPI SUCCESS')
                })
            }
            const arr = [...current(state.notifications)];
            const index = arr.findIndex(value => value.id === payload.id);
            arr.splice(index, 1);
            state.notifications = arr;
        },
        addCameraNotification: (state, {payload}) => {
            const arr = [...current(state.notifications)];
            const index = arr.findIndex(value => value.id === payload.id);
            if (index === -1) {
                arr.push(payload);
                state.notifications = arr;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUnusedEvents.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchAllUnusedEvents.fulfilled, (state, {payload}) => {
                state.unUsedEvents = payload;
                state.loading = false
            })
            .addCase(fetchAllUnusedEvents.rejected, (state) => {
                state.loading = false
            })
    },
});

export const {removeCameraNotification, addCameraNotification} = slice.actions;
export default slice.reducer;
