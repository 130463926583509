import PropTypes from "prop-types";
import {IconButton, TableCell, TableRow} from "@mui/material";
import Label from "../../../components/label";
import Iconify from "../../../components/Iconify";

OperatorLoginTableRow.propTypes = {
    row: PropTypes.object,
    onLoginClick: PropTypes.func,
    loading: PropTypes.bool,
};

export default function OperatorLoginTableRow({row, onLoginClick, loading}) {
    const {name, country, operatorDetails, inactive} = row;

    return (
        <TableRow hover>
            <TableCell align="left">{name}</TableCell>
            <TableCell align="left">{country?.name}</TableCell>
            <TableCell align="left">{operatorDetails?.description || ""}</TableCell>
            <TableCell align="left">
                <Label
                    variant="soft"
                    color={(!inactive && 'success') || 'error'}>
                    {!inactive ? 'Aktivan' : 'Pasiviziran'}
                </Label>
            </TableCell>

            <TableCell align="right">
                <IconButton color="primary" onClick={loading ? null : onLoginClick}>
                    <Iconify icon={loading ? "line-md:loading-twotone-loop" : "material-symbols-light:login"}/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
