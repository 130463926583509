import {useSettingsContext} from "../../../components/settings";
import {Button, Card, Container, Table, TableBody, TableContainer} from "@mui/material";
import {PATH_DASHBOARD} from "../../../routes/path";
import Iconify from "../../../components/Iconify";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {useEffect, useState} from "react";
import {TableHeadCustom, TableNoData, TablePaginationCustom, TableSkeleton, useTable} from "../../../components/table";
import Scrollbar from "../../../components/Scrollbar";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet-async";
import {fetchAllBuses, searchBusSlice, specialLoading} from "../../../redux/slices/buses";
import {dispatch, useSelector} from "../../../redux/store";
import {useLocales} from "../../../locales";
import {debounce} from "lodash";
import {debounceSearch} from "../../../api/searchDebounce";
import {searchVehicleAPI} from "../../../api/vehicle";
import {useAuthContext} from "../../../auth/useAuthContext";
import BusTableToolbar from "../../Bus/BusTableToolbar";
import BusTableRow from "../../Bus/BusTableRow";

// ----------------------------------------------------------------------

const TABLE_HEAD = translate => [
    {id: "operator.name", label: translate("carrier"), align: "left"},
    {id: "plateNumber", label: translate("plateNumber"), align: "left"},
    {id: "seats", label: translate("capacity"), align: "left",},
    {id: "description", label: translate("description"), align: "left", width: 300, sortable: false},
    {id: "status", label: translate("status"), align: "left", width: 20, sortable: false},
    {id: "", align: "right", sortable: false}
];

export default function Bus() {
    const {translate} = useLocales();
    const {enqueueSnackbar} = useSnackbar();
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        selectedOne,
        setSelectedOne,
    } = useTable({
        defaultOrderBy: "id"
    });
    const {changeOperator} = useAuthContext();
    const {themeStretch} = useSettingsContext();

    const navigate = useNavigate();

    const {foundVehicles, loadingBuses} = useSelector((state => state.bus))

    const [filterPlateNumber, setFilterPlateNumber] = useState("");
    const [filterOperatorName, setFilterOperatorName] = useState("");
    const [filterSeatsCapacity, setFilterSeatsCapacity] = useState("");
    const [filterDescription, setFilterDescription] = useState("");
    const [filterStatus, setFilterStatus] = useState("SVI");
    const [openPreview, setOpenPreview] = useState({view: false, data: null, items: []});


    useEffect(() => {
        if (filterPlateNumber || filterOperatorName || filterSeatsCapacity || filterDescription) {
            dispatch(specialLoading(true))
            debounceSearchVehicle({
                plateNumber: filterPlateNumber,
                operatorName: filterOperatorName,
                seats: filterSeatsCapacity,
                description: filterDescription,
                page: page,
                rowsPerPage: rowsPerPage,
                order,
                orderBy
            });
        } else {
            dispatch(fetchAllBuses({
                page: page,
                rowsPerPage: rowsPerPage,
                order,
                orderBy
            }))
                .unwrap().catch(reason => {
                //logError("CarriersList", "getAllOperators", errorFetch)
                console.error("fetchAllBuses", reason)
                enqueueSnackbar(translate("errorFetching"), {variant: "error"});
            });
        }
    }, [page, rowsPerPage, order, orderBy, filterPlateNumber, filterOperatorName, filterSeatsCapacity, filterDescription]);


    const searchVehicle = async (value) => {
        try {
            const response = await debounceSearch(searchVehicleAPI, value);
            dispatch(searchBusSlice(response))
        } catch (error) {
            enqueueSnackbar(translate("errorOnServer"), {variant: "error"})
        }
    };

    const debounceSearchVehicle = debounce(searchVehicle, 300);

    const isFiltered = filterPlateNumber !== "" || filterSeatsCapacity !== "" || filterDescription !== "" || filterStatus !== "SVI";

    const handleEditRow = async (data) => {

        await changeOperator(data.firebaseUid)
        navigate(PATH_DASHBOARD.bus.list);
    };


    const handleFilterPlateNumber = (event) => {
        setPage(0);
        setFilterPlateNumber(event.target.value);
    };
    const handleFilterOperatorName = (event) => {
        setPage(0);
        setFilterOperatorName(event.target.value);
    };

    const handleFilterSeat = (event) => {
        setPage(0);
        setFilterSeatsCapacity(event.target.value);
    };

    const handleFilterDescription = (event) => {
        setPage(0);
        setFilterDescription(event.target.value);
    };

    const handleResetFilter = () => {
        setFilterPlateNumber("");
        setFilterSeatsCapacity("");
        setFilterDescription("");
        setFilterStatus("SVI");
        setSelectedOne(null);
    };

    return (
        <>
            <Helmet>
                <title>BUS Soft - {translate("vehicles")}</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : "xl"}>
                <CustomBreadcrumbs
                    heading={translate("driveParkList")}
                    links={[
                        {name: translate("home"), href: PATH_DASHBOARD.general.app},
                        {name: translate("vehicles"), href: PATH_DASHBOARD.bus.list},
                        {name: translate("list")}
                    ]}
                />

                <Card>
                    <BusTableToolbar
                        isFiltered={isFiltered}
                        filterPlateNumber={filterPlateNumber}
                        filterOperatorName={filterOperatorName}
                        filterSeatsCapacity={filterSeatsCapacity}
                        filterDescription={filterDescription}
                        onFilterName={handleFilterPlateNumber}
                        onFilterOperatorName={handleFilterOperatorName}
                        onFilterSeat={handleFilterSeat}
                        onFilterDescription={handleFilterDescription}
                        onResetFilter={handleResetFilter}
                    />

                    <TableContainer sx={{position: "relative", overflow: "unset"}}>
                        <Scrollbar>
                            <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD(translate)}
                                    rowCount={foundVehicles?.content?.length}
                                    onSort={onSort}
                                />

                                <TableBody>
                                    {loadingBuses ?
                                        Array.from({length: rowsPerPage}, (_, i) => (
                                            <TableSkeleton key={i} columns={TABLE_HEAD(translate).length}/>
                                        ))
                                        :
                                        foundVehicles?.content?.map((row) =>
                                            <BusTableRow
                                                isPreview={true}
                                                key={row.id}
                                                row={row}
                                                onEditRow={() => handleEditRow(row)}
                                            />)
                                    }
                                    <TableNoData isNotFound={!loadingBuses && foundVehicles?.totalElements === 0}/>
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        loading={loadingBuses}
                        count={foundVehicles?.totalElements || 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>
        </>
    );
}
