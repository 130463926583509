import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

// CENOVNICI
export async function getAllRateCardsAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.post(`/rateCard/search?${parsedParams}`,{})
}

export async function getAllRateCardsWithOperatorAPI(payload) {
    const parsedParams = parsePaginationParams(payload)
    return customAxiosBE.get(`/rateCard/withOperator?${parsedParams}`)
}
export async function getRateCardAPI(id) {
    return customAxiosBE.get(`/rateCard/${id}`)
}
export async function insertRateCardsAPI(payload) {
    return customAxiosBE.post(`/rateCard`, payload)
}

export async function updateRateCardsAPI(payload) {
    return customAxiosBE.put(`/rateCard`, payload)
}
export async function updateRateCardByProvidedOperatorAPI(payload) {
    return customAxiosBE.put(`/rateCard/byProvidedOperator`, payload)
}

export async function deleteRateCardsAPI(id) {
    return customAxiosBE.delete(`/rateCard/${id}`)
}

export const searchRateCardAPI = async (data, cancelToken) => {
    let response
    const {name, validFrom, validTo, operatorId, operatorName} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/rateCard/search?${parsedParams}`, {
            name: name,
            operatorName: operatorName,
            validFrom: validFrom,
            validTo: validTo,
            operatorId: operatorId
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

export const searchRateCardByOperatorAPI = async (data, cancelToken) => {
    let response
    const {name, validFrom, validTo, operatorId} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/rateCard/searchByOperator?${parsedParams}`, {
            name: name,
            validFrom: validFrom,
            validTo: validTo,
            operatorId: operatorId
        }, {
            cancelToken
        })
        .then((r) => {
            response = r.data
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}

