import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
import {TextField} from '@mui/material';
import Iconify from "../Iconify";
import MaskedInput from 'react-text-mask';

// ----------------------------------------------------------------------

RHFTimeMask.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    icon: PropTypes.string,
};

export default function RHFTimeMask({name, disabled, children, icon, ...other}) {
    const {control} = useFormContext();

    // Custom time mask to accept values in the format "hh:mm"
    const timeMask = [
        /[0-2]/, // First hour digit (0-2)
        /\d/, // Second hour digit (0-9)
        ':', // Separator
        /[0-5]/, // First minute digit (0-5)
        /\d/, // Second minute digit (0-9)
    ];

    const inputProps = other?.InputProps;
    delete other?.InputProps;

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <TextField
                    disabled={disabled}
                    size="small"
                    {...field}
                    fullWidth
                    sx={{maxWidth: 160}}
                    InputProps={{
                        ...inputProps,
                        startAdornment: <Iconify icon={icon}/>,
                        inputComponent: MaskedInput, // Use the MaskedInput component for the input
                        inputProps: {
                            mask: timeMask,
                            placeholderChar: ' ', // Replace empty slots in the mask with a space
                        },
                    }}
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                >
                    {children}
                </TextField>
            )}
        />
    );
}
