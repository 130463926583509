// routes
import {PATH_DASHBOARD} from "../../../routes/path";
// components
import SvgColor from "../../../components/svg-color";
import {ALL_ROLES, OPERATORS_GROUP} from "../../../constants";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

export const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;


// ----------------------------------------------------------------------

const ICONS = {
    blog: icon("ic_blog"),
    discount: icon("ic_discount"),
    cart: icon("ic_cart"),
    chat: icon("ic_chat"),
    mail: icon("ic_mail"),
    user: icon("ic_user"),
    file: icon("ic_file"),
    lock: icon("ic_lock"),
    label: icon("ic_label"),
    blank: icon("ic_blank"),
    kanban: icon("ic_kanban"),
    folder: icon("ic_folder"),
    banking: icon("ic_banking"),
    booking: icon("ic_booking"),
    invoice: icon("ic_invoice"),
    calendar: icon("ic_calendar"),
    disabled: icon("ic_disabled"),
    external: icon("ic_external"),
    menuItem: icon("ic_menu_item"),
    ecommerce: icon("ic_ecommerce"),
    analytics: icon("ic_analytics"),
    dashboard: icon("ic_dashboard"),
    bus: icon("ic_bus"),
    report: icon("ic_report"),
    priceList: icon("ic_price_list"),
    stationList: icon("ic_station_list"),
    monthly: icon("ic_monthly"),
    locations: icon("ic_locations"),
    relations: icon("ic_relations"),
    platforms: icon("ic_relations"),
    departures: icon("ic_deparutres"),
    times: icon("ic_times"),
    reports: icon("ic_reports"),
    invoices: icon("ic_invoices"),
    carrier: icon("ic_carrier"),
    customers: icon("ic_customer"),
    insurance: icon("ic_insurance"),
    adminRoutes: <Iconify icon="dashicons:admin-links"/>

};

export const navConfigUzice = [
    // ----------------------------------------------------------------------
    {
        subheader: "Početna",
        items: [
            {
                title: "Izveštaji",
                //roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.operator, OPERATORS_GROUP.accountant, OPERATORS_GROUP.manager],
                path: PATH_DASHBOARD.reports.root,
                icon: ICONS.reports
            }
        ]
    },
]
export const navConfig = [
    // ----------------------------------------------------------------------
    {
        subheader: "Admin",
        needPrimaryUser: true,
        uid: "0UIMxuKls9hjEjDDwcFJKv8DZmn1",
        items: [
            {
                title: "Zabranjene stranice po korisniku",
                needPrimaryUser: true,
                roles: [OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.admin.routes,
                icon: ICONS.adminRoutes,
            },
        ]
    },
    {
        subheader: "Početna",
        needPrimaryUser: true,
        items: [
            {
                title: "Početna",
                roles: ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher].includes(item);
                }),
                path: PATH_DASHBOARD.general.app,
                icon: ICONS.bus
            },
            {
                title: "Lista artikala",
                roles: ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.publisher,
                        OPERATORS_GROUP.informer].includes(item);
                }),
                path: PATH_DASHBOARD.general.stationServices,
                icon: ICONS.bus
            },
            {
                title: "Izveštaji",
                roles: ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.publisher,
                        OPERATORS_GROUP.informer].includes(item);
                }),
                path: PATH_DASHBOARD.reports.root,
                icon: ICONS.reports,
                children: [
                    {title: "Evidencija prometa", path: PATH_DASHBOARD.reports.overview},
                    {title: "Dodatan prihod", path: PATH_DASHBOARD.reports.additionalIncome},
                    {title: "Pregled prodatih karata i robe", path: PATH_DASHBOARD.reports.overviewTicketAndOther},
                ]
            },
            {
                title: "Lista Računa",
                roles: ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.publisher,
                        OPERATORS_GROUP.informer].includes(item);
                }),
                path: PATH_DASHBOARD.general.invoices,
                icon: ICONS.invoices
            },
            {
                title: "Lista autobuskih karata",
                roles: ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.publisher,
                        OPERATORS_GROUP.informer].includes(item);
                }),
                path: PATH_DASHBOARD.general.busTickets,
                icon: ICONS.invoices
            },
            {
                title: "Mesečne karte",
                path: PATH_DASHBOARD.monthlyTicketsList.root,
                roles:ALL_ROLES.filter(item => {
                    return ![OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.publisher,
                        OPERATORS_GROUP.informer].includes(item);
                }),
                icon: ICONS.monthly,
                children: [
                    {title: "Lista mesečnih karti", path: PATH_DASHBOARD.monthlyTicketsList.list},
                    {title: "Dodavanje mesečne karte", path: PATH_DASHBOARD.monthlyTicketsList.new},
                ]
            },
        ]
    },
    {
        subheader: "Operativa",
        roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
        items: [
            {
                title: "Prevoznici",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.carriers.root,
                icon: ICONS.carrier,
                children: [
                    {title: "Lista prevoznika", path: PATH_DASHBOARD.carriers.list},
                    {title: "Dodavanje prevoznika", path: PATH_DASHBOARD.carriers.new}
                ]
            },
            {
                title: "Vozila",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.bus.root,
                icon: ICONS.bus,
                children: [
                    {title: "Lista vozila prevoznika", path: PATH_DASHBOARD.bus.list},
                    {title: "Dodavanje vozila", path: PATH_DASHBOARD.bus.new},
                ]
            },
            {
                title: "Cenovnik",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.priceList.root,
                icon: ICONS.priceList,
                children: [
                    {title: "Lista cenonvika", path: PATH_DASHBOARD.priceList.list},
                    {title: "Dodavanje cenovnika", path: PATH_DASHBOARD.priceList.new},
                    {title: "Stanice", path: PATH_DASHBOARD.priceList.stationsList},
                ]
            },
            {
                title: "Artikli",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.priceList.root,
                icon: ICONS.cart,
                children: [
                    {
                        title: "Lista artikala",
                        path: PATH_DASHBOARD.priceList.stationsServicesList
                    },
                    {
                        title: "Dodavanje artikla",
                        path: PATH_DASHBOARD.priceList.stationsServicesAdd
                    },
                ]
            },
            {
                title: "Praznici",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.holidays.root,
                icon: ICONS.priceList,
                children: [
                    {title: "Lista praznika", path: PATH_DASHBOARD.holidays.list},
                    {title: "Dodavanje praznika", path: PATH_DASHBOARD.holidays.new},
                ]
            },
            {
                title: "Relacije",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.linesList.root,
                icon: ICONS.relations,
                children: [
                    {title: "Lista relacija", path: PATH_DASHBOARD.linesList.list},
                    {title: "Dodavanje relacije", path: PATH_DASHBOARD.linesList.new},
                ]
            },
            {
                title: "Red vožnje i polasci",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.departuresList.root,
                icon: ICONS.departures,
                children: [
                    {title: "Lista polazaka", path: PATH_DASHBOARD.departuresList.list},
                    {title: "Dodavanje polazaka", path: PATH_DASHBOARD.departuresList.new},
                    {title: "Vezani polasci", path: PATH_DASHBOARD.departuresList.linked},
                    {title: "Lista alotmana", path: PATH_DASHBOARD.allotment.list},
                    {title: "Dodavanje alotmana", path: PATH_DASHBOARD.allotment.new},
                ]
            },
            {
                title: "Itinerer",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.timesList.root,
                icon: ICONS.times,
                children: [
                    {title: "Lista Itinerera", path: PATH_DASHBOARD.timesList.list},
                    // {title: "Dodavanje vremena", path: PATH_DASHBOARD.timesList.new},
                ]
            },
            {
                title: "Popusti",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.discounts.root,
                icon: ICONS.discount,
                children: [
                    {title: "Popusti", path: PATH_DASHBOARD.discounts.list},
                    {title: "Dodavanje popusta", path: PATH_DASHBOARD.discounts.new},
                ]
            },
            {
                title: "Povlastice",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.privileges.root,
                icon: ICONS.discount,
                children: [
                    {title: "Povlastice", path: PATH_DASHBOARD.privileges.list},
                    {title: "Dodavanje povlastice", path: PATH_DASHBOARD.privileges.new},
                    {title: "Tipovi povlastice", path: PATH_DASHBOARD.privileges.listDisabilities},
                    // {title: "Dodavanje tipa povlastice", path: PATH_DASHBOARD.privileges.newDisability},
                ]
            },
            {
                title: "Akcije",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.actions.root,
                icon: ICONS.times,
                children: [
                    {title: "Lista akcija", path: PATH_DASHBOARD.actions.list},
                    {title: "Dodavanje akcije", path: PATH_DASHBOARD.actions.new},
                    {title: "Lista vrednosti akcije", path: PATH_DASHBOARD.actions.actionValueList},
                    {title: "Dodavanje vrednosti akcije", path: PATH_DASHBOARD.actions.actionValueNew},
                ]
            },
            {
                title: "Stanice",
                roles: [OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.stationList.root,
                icon: ICONS.stationList,
                children: [
                    {title: "Lista stanica", path: PATH_DASHBOARD.stationList.list},
                    {title: "Dodavanje stanice", path: PATH_DASHBOARD.stationList.new},
                ]
            },
            {
                title: "Lokacije",
                roles: [OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.locationList.root,
                icon: ICONS.locations,
                children: [
                    {title: "Lista lokacija", path: PATH_DASHBOARD.locationList.list},
                    {title: "Dodavanje lokacija", path: PATH_DASHBOARD.locationList.new},
                ]
            },
            {
                title: "Peroni",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.platforms.root,
                icon: ICONS.platforms,
                children: [
                    {title: "Lista perona", path: PATH_DASHBOARD.platforms.list},
                    {title: "Dodavanje perona", path: PATH_DASHBOARD.platforms.new},
                    {title: "Zauzetost perona", path: PATH_DASHBOARD.platforms.busyness},
                ]
            },
            {
                title: "Baza kupaca",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.customers.root,
                icon: ICONS.customers,
                children: [
                    {title: "Lista kupaca", path: PATH_DASHBOARD.customers.list},
                    {title: "Dodavanje kupaca", path: PATH_DASHBOARD.customers.new}
                ]
            },
            {
                title: "Baza operatera",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.operators.root,
                icon: ICONS.customers,
                children: [
                    {title: "Lista operatera", path: PATH_DASHBOARD.operators.list},
                    {title: "Dodavanje operatera", path: PATH_DASHBOARD.operators.new}
                ]
            },
            {
                title: "Komentari",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.operators.root,
                icon: ICONS.customers,
                children: [
                    {title: "Lista komentara", path: PATH_DASHBOARD.comments.list},
                ]
            },
            // {
            //     title: "Osiguranja",
            //     roles: [OPERATORS_GROUP.admin],
            //     path: PATH_DASHBOARD.insurance.root,
            //     icon: ICONS.insurance,
            //     children: [
            //         {title: "Lista osiguranja", path: PATH_DASHBOARD.insurance.list},
            //         {title: "Dodavanje osiguranja", path: PATH_DASHBOARD.insurance.new}
            //     ]
            // },
            // {
            //     title: "Usluge",
            //     roles: [OPERATORS_GROUP.admin],
            //     path: PATH_DASHBOARD.items.root,
            //     icon: ICONS.booking,
            //     children: [
            //         {title: "Lista usluga", path:  PATH_DASHBOARD.priceList.stationsServicesList},
            //         {title: "Dodavanje usluge", path: PATH_DASHBOARD.items.new}
            //     ]
            // }
        ]
    },
    {
        subheader: 'PANEL PREVOZNIKA',
        needPrimaryUser: true,
        roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
        items: [
            {title: "Cenovnici", path: PATH_DASHBOARD.preview.priceList, icon: ICONS.dashboard},
            {title: "Relacije", path: PATH_DASHBOARD.preview.linesList, icon: ICONS.dashboard},
            {title: "Redovi vožnje i polasci", path: PATH_DASHBOARD.preview.departuresList, icon: ICONS.dashboard},
            {title: "Alotmani", path: PATH_DASHBOARD.preview.allotments, icon: ICONS.dashboard},
            {title: "Dostupni alotmani", path: PATH_DASHBOARD.preview.currentAllotments, icon: ICONS.dashboard},
            {title: "Itinereri", path: PATH_DASHBOARD.preview.timesList, icon: ICONS.dashboard},
            {title: "Popusti", path: PATH_DASHBOARD.preview.discountsList, icon: ICONS.dashboard},
            {title: "Vozila", path: PATH_DASHBOARD.preview.driveParkList, icon: ICONS.dashboard},
            {title: "Komentari", path: PATH_DASHBOARD.preview.commentsList, icon: ICONS.dashboard},
        ]
    },
    {
        subheader: "Saobraćajni dnevnik",
        needPrimaryUser: true,
        roles: ALL_ROLES,
        items: [
            {
                title: "Otpravnik",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.operator, OPERATORS_GROUP.dispatcher,
                    OPERATORS_GROUP.informer],
                path: PATH_DASHBOARD.otpravnik.root,
                icon: ICONS.carrier,
                children: [
                    {title: "Lista polazaka/dolazaka", path: PATH_DASHBOARD.otpravnik.list, roles:
                            [OPERATORS_GROUP.operator, OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.admin,
                                OPERATORS_GROUP.informer]},
                    {title: "Vanredne linije/parking", path: PATH_DASHBOARD.otpravnik.other, roles:
                            [OPERATORS_GROUP.operator, OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.admin,
                                OPERATORS_GROUP.informer]},
                    {title: "Objava Polazaka/Dolazaka", path: PATH_DASHBOARD.otpravnik.publisher, roles:
                            [OPERATORS_GROUP.operator, OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.admin,
                                OPERATORS_GROUP.informer]},
                    {title: "Neiskorišćene tablice", path: PATH_DASHBOARD.otpravnik.unusedCameraEvents, roles:
                            [OPERATORS_GROUP.operator, OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.admin,
                                OPERATORS_GROUP.informer]},
                    {title: "Dodavanje polaska/dolaska", path: PATH_DASHBOARD.otpravnik.new, roles:
                            [OPERATORS_GROUP.dispatcher, OPERATORS_GROUP.superAdmin, OPERATORS_GROUP.admin]}
                ]
            },
            {
                title: "Održani polasci / dolasci",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.scheduledDepAndArr.root,
                icon: ICONS.carrier,
                children: [
                    {title: "Lista održanih polazaka/dolazaka", path: PATH_DASHBOARD.scheduledDepAndArr.list},
                ]
            },
        ]
    },
    {
        subheader: "Kartice prevoznika",
        needPrimaryUser: true,
        items: [

            {
                title: "Peronizacija",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.peronization.root,
                icon: ICONS.carrier,
                children: [
                    {title: "Cenovnik peronizacije", path: PATH_DASHBOARD.peronization.price},
                    {title: "Izveštaj prijema i otpreme", path: PATH_DASHBOARD.peronization.list},
                    {title: "Izveštaj parking", path: PATH_DASHBOARD.parking.list},
                ]
            },
            {
                title: "Obračun prihoda prevoznika",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.carrierReport.root,
                icon: ICONS.carrier,
                children: [
                    {title: "Sumarni izveštaj o prodaji za prevoznika", path: PATH_DASHBOARD.carrierReport.sumarized},
                    {title: "Detaljni izveštaj o prodaji za prevoznika", path: PATH_DASHBOARD.carrierReport.detailed},

                    {title: "Izveštaj o prodatim kartama", path: PATH_DASHBOARD.carrierReport.tickets},
                    {title: "Izveštaj vraćenih karata", path: PATH_DASHBOARD.carrierReport.returnTickets},
                    {title: "Izveštaj validiranih karata", path: PATH_DASHBOARD.carrierReport.validatedTickets},
                    {title: "Izveštaj povlašćenih karata", path: PATH_DASHBOARD.carrierReport.privilegesTickets},
                ]
            },
        ]
    },
    {
        subheader: "Kartica AS",
        needPrimaryUser: true,
        items: [
            {
                title: "Obračun provizija",
                roles: [OPERATORS_GROUP.admin, OPERATORS_GROUP.superAdmin],
                path: PATH_DASHBOARD.commissionCalculation.root,
                icon: ICONS.reports,
                children: [
                    {title: "Obračun provizija", path: PATH_DASHBOARD.commissionCalculation.list},
                    {title: "Izveštaj svih karata", path: PATH_DASHBOARD.carrierReport.allTickets},
                    {title: "Obračun prihoda za stanicu", path: PATH_DASHBOARD.commissionCalculation.incomeForStation},
                ]
            },
        ]
    }
];

