import PropTypes from "prop-types";
// @mui
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

BusTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterPlateNumber: PropTypes.string,
    filterOperatorName: PropTypes.string,
    onFilterName: PropTypes.func,
    onResetFilter: PropTypes.func,
    onFilterOperatorName: PropTypes.func,
};

export default function BusTableToolbar({
                                            isFiltered,
                                            filterPlateNumber,
                                            filterOperatorName,
                                            onFilterName,
                                            onResetFilter,
                                            onFilterOperatorName,
                                        }) {
    const {translate} = useLocales();

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            <TextField
                autoFocus
                size={"small"}
                fullWidth
                value={filterOperatorName}
                onChange={onFilterOperatorName}
                placeholder={translate("carrierName")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                size={"small"}
                fullWidth
                value={filterPlateNumber}
                onChange={onFilterName}
                placeholder={translate("plateNumber")}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="error"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline"/>}
                >
                    {translate("clear")}
                </Button>
            )}
        </Stack>
    )
}
