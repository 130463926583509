import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {issueCopyTicketAPI, searchTicketsApi} from "../../api/tickets";
import moment from "moment";
import {parsePaginationParams} from "../../utils/other";
import {Invoice} from "../../helper/invoice/Invoice";
import {allSeats} from "../../api/trip";

export const postTicketSeats = createAsyncThunk('postTicketSeats', async (payload) => {
    let tripDate = payload.tripDate;
    if (tripDate) {
        tripDate = moment(tripDate).format("yyyy-MM-DD");
    }
    return allSeats(payload.tripId, tripDate);
})

export const searchTickets = createAsyncThunk('searchTickets', async (payload) => {
    const { filter, params } = payload;

    // Formatiraj salesTime u "YYYY-MM-DD"
    let salesTime = filter.salesTime ? moment(filter.salesTime).format("YYYY-MM-DD") : null;

    // Formatiraj journeyDate u "YYYY-MM-DD" za LocalDate
    let journeyDate = filter.journeyDate ? moment(filter.journeyDate).format("YYYY-MM-DD") : null;

    let id = filter.ticketNumber;
    let cashier = filter.cashier;

    // Formatiraj salesTime za prikaz ako je potrebno, ali za backend koristi "YYYY-MM-DD"
    if (salesTime) {
        salesTime = moment(salesTime).format("YYYY-MM-DD HH:mm:ss"); // ili format za LocalDateTime ako treba
    }
    if (journeyDate) {
        journeyDate = moment(journeyDate).format("YYYY-MM-DD HH:mm:ss"); // format za LocalDate
    }

    const parsedParams = parsePaginationParams(params);
    return (await searchTicketsApi({
        ...filter,
        salesTime,
        journeyDate,
        cashier,
        id
    }, parsedParams)).data;
});



export const issueCopyTicket =
    createAsyncThunk('issueCopyTicket', async (ticketId) => {
        const ticket = (await issueCopyTicketAPI(ticketId)).data
        const invoice = new Invoice();
        await invoice.printTickets([ticket], null, false,
            undefined, null, null)
        return ticket;
    })

const initialState = {
    tickets: {
        content: [],
        page: 0,
        totalElements: 0,
    },
    loading: false,
    reservedSeats: [],
    ticket: null,
    loadingTickets: false,
}

export const ticketSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {},
    extraReducers: {
        [searchTickets.pending]: (state) => {
            state.loading = true;
        },
        [searchTickets.fulfilled]: (state, {payload}) => {
            state.tickets = payload;
            state.loading = false;
        },
        [searchTickets.rejected]: (state) => {
            state.loading = false;
        },
        [postTicketSeats.pending]: (state) => {
            state.loadingTickets = true;
        },
        [postTicketSeats.fulfilled]: (state, action) => {
            state.loadingTickets = false;
            state.reservedSeats = action.meta.arg
        },
        [postTicketSeats.rejected]: (state) => {
            state.loadingTickets = false;
        },
        [issueCopyTicket.pending]: (state) => {
            state.loadingTickets = true;
        },
        [issueCopyTicket.fulfilled]: (state, {payload}) => {
            const currentState = current(state.tickets);
            const tickets = [...currentState.content];
            const index = tickets.findIndex(x => x.id === payload.id);
            if (index !== -1) {
                tickets[index] = payload;
                state.tickets = {
                    ...currentState,
                    content: tickets
                }
            }
            state.loadingTickets = false;
        },
        [issueCopyTicket.rejected]: (state) => {
            state.loadingTickets = false;
        },
    }
});

export const ticketsReducer = ticketSlice.reducer
