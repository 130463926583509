import {calculateBasicOfItem} from "../other";
import {LINE_TRAIFFIC_TYPES} from "../../constants";

export const parseService = (data, currentTaxRate, quantity) => {
    const base = calculateBasicOfItem({
        ...data,
        unitPrice: data.price,
        quantity: quantity
    }, currentTaxRate);

    return {
        uid: data.uid,
        code: data.code,
        name: data.name,
        includeDiscount: data?.includeDiscount || false,
        inactive: data?.inactive,
        includeForOnlineSaleOnly: data?.includeForOnlineSaleOnly || false,
        includeOnIssuingTicket: data?.includeOnIssuingTicket || false,
        includeOnPlatform: data?.includeOnPlatform || false,
        includeOnReservation: data?.includeOnReservation || false,
        includeOnVerification: data?.includeOnVerification || false,
        quantity: quantity,
        price: data.price,
        totalAmount: data.price * quantity,
        vat: data.vat,
        baseAmount: base,
        pdv: (data.price * quantity) - base,
    }
}

export const getTrafficType = (data) => {
    if (!data) {
        return undefined;
    }
    if (data.depLocationName === data.arrLocationName) {
        return LINE_TRAIFFIC_TYPES[0]
    } else if (data.depCountryCode === data.arrCountryCode) {
        return LINE_TRAIFFIC_TYPES[1]
    }
    return LINE_TRAIFFIC_TYPES[2]
}

// export const parseActions = (actions, total, currentTaxRate) => {
//     // TODO za akiciju da se doda da gled da li je medjunarodna ili ne
//     return actions.map(action => {
//         let price
//         if (action.amount > 0 && action?.salesActionType?.isAbsolute === true) {
//             price = action.amount
//         } else {
//             price = total * 2 / 100
//         }
//         const base = calculateBasicOfItem({
//             labels: [action.vat],
//             vat: action.vat,
//             unitPrice: price,
//             quantity: 1
//         }, currentTaxRate);
//         return ({
//             name: action?.salesActionType?.name,
//             passengerUid: [],
//             labels: [action.vat],
//             vat: action.vat,
//             quantity: 1,
//             totalAmount: Number(fCurrency(price)),
//             unitPrice: Number(fCurrency(price)),
//             price: Number(fCurrency(price)),
//             baseAmount: base,
//             pdv: (price) - base,
//         })
//     })
// }
